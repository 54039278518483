/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPurchaseProduct,
  getRentalProduct,
} from "../../../helpers/product.service";
import useMobileOrientation from "../../../hook/useMobileOrientation";
import { openPopup } from "../../../redux/popup/actions";
import {
  listBrowserFront,
  listBrowserFrontForOneCategory,
} from "../../../redux/browserfront/actions";
import constants, { popupNames, identity } from "../../../constants";
import ModalFilm from "../../modalfilm";
import ModalInfo from "../../modalinfo";
import { films_concert, films_docu, films_tv } from "../../../constants/datas";
import { connect } from "react-redux";

import "./_style.scss";
import { removeDuplicates } from "../../../helpers/removeDuplicates";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SeeAll = ({ dataList }) => {
  const { id } = useParams();

  let titrePage = id;
  const descriptions = {
    emissiontv: (
      <span>
        Bienvenue dans l'onglet "Émissions TV"!.
        <br /> Ici, replongez dans les émissions qui ont marquées les
        générations grâce à notre sélection exclusive.
        <br /> Que vous soyez nostalgique des classiques ou curieux de
        redécouvrir des pépites oubliées, cet espace vous permettra devoyager
        dans le temps sans quitter votre canapé. <br />
        Revivez ces moments cultes, et laissez la nostalgie opérer !
      </span>
    ),
    concert: (
      <span>
        Bienvenue dans l'onglet "Concerts" !<br />
        Vous êtes à quelques clics de donner le coup d'envoi à votre symphonie
        privée.
        <br />
        Ici, vous pouvez louer ou acheter des vidéos des meilleurs concerts de
        Gims, M. Pokora, Jenifer, et Trois Cafés Gourmands 3, 2, 1... <br />
        Plongez dans l'énergie des performances live avec des extraits exclusifs
        et des compilations des moments les plus mémorables
        <br />
        Montez le volume, éteignez les lumières, et préparez-vous à assister à
        un show avec des souvenirs inoubliables depuis votre canapé.
        <br /> Votre festival personnel commence maintenant !
      </span>
    ),
    documentaire: (
      <span>
        Bienvenue dans l'onglet "Documentaires Musicaux"
        <br />
        Explorez les coulisses de grandes stars et découvrez les secrets des
        festivals ou concerts iconiques à travers des documentaires immersifs.
        <br />
        Plongez dans des récits captivants, suivez les artistes dans leurs
        tournées, et assistez à des moments inédits. <br />
        Vivez l'intensité des concerts comme si vous y étiez, tout en apprenant
        des anecdotes exclusives. <br />
        Préparez-vous pour un voyage au cœur du monde de la musique !
      </span>
    ),
  };
  const descriptionFilter = (title) => {
    if (title === "EmissionsTV") return descriptions.emissiontv;
    if (title === "Concerts") return descriptions.concert;
    if (title === "Documentairesmusicaux") return descriptions.documentaire;
  };
  const splitTitle = (title) => {
    if (title === "EmissionsTV") return "Emissions TV";
    if (title === "Concerts") return "Concerts";
    if (title === "Documentairesmusicaux") return "Documentaires Musicaux";
  };
  const generateId = (id) => {
    if (id === "EmissionsTV") return "Emissions TV";
    if (id === "Concerts") return "Concerts";
    if (id === "Documentairesmusicaux") return "Documentaires musicaux";
  };

  const dispatch = useDispatch();
  const { isMobile } = useMobileOrientation("(max-width: 911px)");

  const [showModal, setShowModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [rulesModal, setRulesModal] = useState(null);

  const [purchaseProd, setPurchaseProd] = useState(null);
  const [rentalProd, setRentalProd] = useState(null);
  const [isPurchaseLoaded, setIsPurchaseLoaded] = useState(false);
  const [isRentalLoaded, setIsRentalLoaded] = useState(false);
  const [dataPurch, setDataPurch] = useState("");

  const [dataModal, setDataModal] = useState(undefined);

  let srcBA = "";
  let displayBA = false;

  const meUser = useSelector((state) => state.userReducer.userInfo);

  let itemPurchase = [];
  let itemRental = [];

  useEffect(() => {
    if (generateId(id)) {
      dispatch(listBrowserFrontForOneCategory(generateId(id)));
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      try {
        let result = [];
        let fireResult = [];
        let allResult = [];
        if (meUser.uid) {
          result = await getPurchaseProduct(meUser, meUser.uid);
          if (meUser.firebaseid) {
            fireResult = await getPurchaseProduct(meUser, meUser.firebaseid);
            allResult = [...result?.data?.data, ...fireResult?.data?.data];
          } else {
            allResult = [...result?.data?.data];
          }

          setPurchaseProd(removeDuplicates(allResult, "id"));
          setIsPurchaseLoaded(true);
        } else {
          setPurchaseProd([]);
          setIsPurchaseLoaded(true);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let result = [];
        let fireResult = [];
        let allResult = [];
        if (meUser.uid) {
          result = await getRentalProduct(meUser, meUser.uid);
          if (meUser.firebaseid) {
            fireResult = await getRentalProduct(meUser, meUser.firebaseid);
            allResult = [...result?.data?.data, ...fireResult?.data?.data];
          } else {
            allResult = [...result?.data?.data];
          }

          setRentalProd(removeDuplicates(allResult, "id"));
          setIsRentalLoaded(true);
        } else {
          setRentalProd([]);
          setIsRentalLoaded(true);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const handleBox = (event, video = []) => {
    const foundObject = purchaseProd.filter((obj) => obj.id === video.id);
    const checkRental = rentalProd.filter((obj) => obj.id === video.id);
    if (foundObject.length > 0 || checkRental.length > 0) {
      if (foundObject.length > 0) {
        setDataPurch(btoa(foundObject[0].video_id));
      } else {
        setDataPurch(btoa(checkRental[0].video_id));
      }
    } else {
      setDataPurch("");
    }

    let plusX = 30,
      plusY = 80,
      plusW = 60,
      plusH = 200;

    let dimElParent = document
      .querySelector(".containerPurchase")
      .getBoundingClientRect();
    let dimElEvent = event.target.getBoundingClientRect();

    if (dimElParent.x === dimElEvent.x) {
      plusX = 0;
    }
    if (dimElEvent.right >= dimElParent.right) {
      plusX = 60;
    }
    const myRules = {
      hidden: {
        opacity: 0,
        zIndex: 10,
        x: dimElEvent.left + window.scrollX,
        y: dimElEvent.top + window.scrollY,
        position: "absolute",
        width: dimElEvent.width,
        height: dimElEvent.height,
        backgroundColor: "rgb(23,22,22)",
        transition: { delay: 0 },
      },
      visible: {
        opacity: 1,
        x: dimElEvent.left + window.scrollX - plusX,
        y: dimElEvent.top + window.scrollY - plusY,
        width: dimElEvent.width + plusW,
        height: dimElEvent.height + plusH,
        transition: { bounce: 0, delay: 0 },
      },
    };
    setRulesModal(myRules);
    setDataModal(event.target.getAttribute("data-gab"));
    setShowModal(true);
  };

  const showPopMobile = (e) => {
    const event = e.target.tagName === "IMG" ? e.target : e.target.children[0];

    const data_purch = event.getAttribute("data-purch");
    const data_rent = event.getAttribute("data-rent");
    const data_gab = event.getAttribute("data-gab");

    var data_achat = "";
    if (data_purch.length > 0 || data_rent.length > 0) {
      data_achat = data_purch.length > 0 ? data_purch : data_rent;
    }
    dispatch(openPopup(popupNames.detailSheet, null, data_gab, data_achat));
  };

  function sortByPosiDescending(data) {
    return data.slice().sort((a, b) => parseInt(a.posi) - parseInt(b.posi));
  }

  return (
    <>
      {isMobile && (
        <div className="mainView-mobile">
          <div className="containerAchat-mobile">
            <div className="TitreAchat-mobile">{splitTitle(titrePage)}</div>

            <div className="containerPurchase">
              {isPurchaseLoaded &&
                isRentalLoaded &&
                dataList &&
                sortByPosiDescending(dataList).map((film, idx) => {
                  if (meUser.uid) {
                    try {
                      itemPurchase = purchaseProd.filter((r) => {
                        if (r.id === film.id) return r;
                        else return null;
                      });
                      itemRental = rentalProd.filter((r) => {
                        if (r.id === film.id) return r;
                        else return null;
                      });
                    } catch (e) {}
                  }
                  return (
                    <div
                      key={idx}
                      className="achfiche-mobile"
                      onClick={(e) => showPopMobile(e)}
                    >
                      <LazyLoadImage
                        effect="blur"
                        loading="lazy"
                        className="achImg"
                        src={
                          "https://vcdn.inlive-stream.com/img/" +
                          film.img_thumbnail
                        }
                        alt={film.titre}
                        data-gab={btoa(
                          unescape(encodeURIComponent(JSON.stringify(film)))
                        )}
                        data-purch={
                          itemPurchase.length > 0
                            ? btoa(itemPurchase[0].video_id)
                            : ""
                        }
                        data-rent={
                          itemRental.length > 0
                            ? btoa(itemRental[0].video_id)
                            : ""
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="mainView">
          <ModalInfo
            showInfoModal={showInfoModal}
            setShowInfoModal={setShowInfoModal}
            dataModal={dataModal}
            dataPurch={dataPurch}
          />
          <ModalFilm
            showModal={showModal}
            setShowModal={setShowModal}
            myRulesModal={rulesModal}
            dataPurch={dataPurch}
            dataModal={dataModal}
            setShowInfoModal={setShowInfoModal}
            srcBA={srcBA}
            displayBA={displayBA}
          />

          <div className="containerAchat">
            <div className="TitreAchat">{splitTitle(titrePage)}</div>

            <div className="DescriptionCategorie">
              {descriptionFilter(titrePage)}
            </div>

            <div className="containerPurchase">
              {isPurchaseLoaded &&
                isRentalLoaded &&
                dataList &&
                sortByPosiDescending(dataList).map((film, idx) => {
                  return (
                    <div
                      key={idx}
                      onMouseEnter={(e) => handleBox(e, film)}
                      style={{}}
                      className="achfiche"
                    >
                      <LazyLoadImage
                        effect="blur"
                        loading="lazy"
                        className="achImg"
                        src={
                          "https://vcdn.inlive-stream.com/img/" +
                          film.img_thumbnail
                        }
                        alt={film.titre}
                        data-gab={btoa(
                          unescape(encodeURIComponent(JSON.stringify(film)))
                        )}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ BrowserFrontReducer }) => ({
  loading: BrowserFrontReducer.loading,
  error: BrowserFrontReducer.error,
  dataList: BrowserFrontReducer.categoryList,
});

export default connect(mapStateToProps)(SeeAll);
