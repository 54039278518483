import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, 
	Toolbar, Button, Typography, CssBaseline,
	useScrollTrigger, Box, Container, 
	styled, IconButton, Badge
} from "@mui/material";

import { AccountCircle, Login } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';


import { openPopup } from "../../redux/popup/actions";
import constants, { identity, popupNames } from "../../constants";

import logo from '../../assets/img/icon-logo-horizontal-white.png';
import iconILS from '../../assets/img/playstore.png';
import logoWhite from '../../assets/img/logo-white.png';

import logoEvent from "../../assets/img/live-channel.png";
import "./style.css";


function ElevationScroll(props){
	const { children, window } = props; 
	
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});
	
	return React.cloneElement(children, {
		elevation: trigger ? 6 : 0,
		style: {background: trigger ? "#000" : "linear-gradient(#000, transparent)" },
	});
}

ElevationScroll.propTypes = {
	children: PropTypes.element.isRequired,
	window: PropTypes.func,
};
  
const colorHover = '#aaa';

const MyButton = styled(Button)({
	color: '#fff',
	textTransform: 'none',
	'&:hover' : {
		color: colorHover
	}
});

const MyIconButton = styled(IconButton)({
	color: '#fff',
	'&:hover': {
		color: colorHover
	}
});

const ElevateAppBar = ({props, children}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const userInfo = useSelector(state => state.userReducer.userInfo);
	const isAuthenticated = userInfo?.uid ? true : false;

	const myNotifs = '';

	return (
		<React.Fragment>
		<CssBaseline />
		<ElevationScroll {...props}>
			<AppBar>
				<Toolbar>
					<Box sx={{my: 2}} className="navbar-boxLogo" onClick={(e)=>{history.push('/')}}>
						<img src={iconILS} alt="Inlive Stream" className="navbar-iconILS" />
						<img src={logoWhite} alt="Inlive Stream" className="navbar-logoWhite" />
					</Box>
					<Box sx={{flexGrow: 1, display: 'flex', padding:'1vh 0px 0px 25px'}}>
						<MyButton onClick={(e)=>{history.push('/')}}> Accueil</MyButton>
						<MyButton><Link to={{pathname:"/MyPurchases"}}> Mes achats</Link></MyButton>
						<MyButton className="btnLive"><Link to={{pathname:"/LiveEvent"}}>Live Event <img className="logo-event" src={logoEvent} /></Link></MyButton>
					</Box>
					<Box sx={{ flexGrow: 0 }} className="mnuBtnIcn">
						<MyIconButton sx={{padding: '0px 10px'  }}>
							<Badge badgeContent={myNotifs}>
								<NotificationsIcon />
							</Badge>
						</MyIconButton>

						{ (isAuthenticated) ? (
							<Link to="/MyAccount">
								<MyIconButton sx={{p: '0 0 0 10px'}}>
									<AccountCircle/>
								</MyIconButton>
							</Link>
						):(
							<MyIconButton sx={{p: '0 0 0 10px'}} onClick={(e) => {
								if(constants.IDENTITY_ACCESS){
									identity.Login();
								}else{
									dispatch(openPopup(popupNames.login))
								}
							}}>
								<AccountCircle/>
							</MyIconButton>
							)}
					</Box>
				</Toolbar>

			</AppBar>
		</ElevationScroll>	

		{ children }
	</React.Fragment>
  
	)
}

export default ElevateAppBar;