import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import { Audio } from "react-loader-spinner";
import Layout from "./layouts";
import HomePage from "./homepage";
import MonCompte from "./pages/monCompte";
import MesAchats from "./pages/mesAchats";
import MaListe from "./pages/maListe";
import SeeAll from "./pages/seeAll";
import LivreAudio from "./pages/livreAudio";
import LiveEvent from "./pages/liveEvent";
import CGV from "./pages/cgv";
import PolitiqueConf from "./pages/politiqueConfidentialite";
import Billetterie from "./pages/billetterie";
import { postUnauthRequest } from "../helpers/api";
import constants, { popupNames, identity } from "../constants";
import { openPopup } from "../redux/popup/actions";

import MentionsLegales from "./pages/mentionLegale";
import { fetch_user_info, getUserProfile } from "../redux/user/actions";

import { useToaster } from "rsuite";
import Message from "../helpers/Notifications";
import { Toaster } from "react-hot-toast";
import Payment from "./pages/payment";
import LiveMagic from "./pages/lienmagic";

const getConfirmation = (message, callback) => {
  const allowTransition = window.config(message);
  callback(allowTransition);
};
const urlLogin = `${constants.URL_API}/users/login`;

const LoginRequired = () => {
  const dispatch = useDispatch();
  return (
    <div className="content-login-required">
      <div className="content-login-required-text">
        Vous devez être connecté pour accéder à cette page.
      </div>
      <div
        className="content-login-required-btn"
        onClick={() => {
          if (constants.IDENTITY_ACCESS) {
            identity.Login();
          } else {
            dispatch(openPopup(popupNames.login));
          }
        }}
      >
        SE CONNECTER
      </div>
    </div>
  );
};

const RouteWrapper = ({
  children,
  isLoaded,
  isLoadedAuth,
  isAuthenticated = false,
  userInfo = {},
  fullPage = false,
  authenticationRequired = false,
  ...rest
}) => {
  const dispatch = useDispatch();

  if (authenticationRequired && !isAuthenticated && !constants.IDENTITY_ACCESS)
    dispatch(openPopup(popupNames.login));

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout fullPage={fullPage} {...props}>
          {authenticationRequired && !isAuthenticated ? (
            <LoginRequired />
          ) : !isLoaded ? (
            <div className="loader-wrapper">
              <Audio color="#fbfbfb" height={100} width={100} />
            </div>
          ) : (
            <>{children}</>
          )}
        </Layout>
      )}
    />
  );
};

const Router = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const toaster = useToaster();
  const meUser = useSelector((state) => state.userReducer.userInfo);

  const isAuthenticated = meUser?.uid ? true : false;
  useEffect(() => {
    (async () => {
      try {
        setIsLoaded(true);
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("ils-cookies-policy")) return;
    dispatch(openPopup(popupNames.cookies));
  }, []);

  window.addEventListener("message", (even) => {
    if (even.data) {
      if (even.data.accountId) {
        postUnauthRequest(
          urlLogin,
          JSON.stringify({
            email: even.data.email,
            token: even.data.token,
          })
        )
          .then((response) => {
            if (response.ret) {
              localStorage.setItem("uid", even.data.accountId);
              localStorage.setItem("token", response.token);
              localStorage.setItem("data", JSON.stringify(even.data));
              dispatch(fetch_user_info());
              dispatch(getUserProfile({ userId: even.data.accountId }));
              toaster.push(
                <Message type="success" message="Connexion réussie!" />,
                {
                  placement: "topEnd",
                }
              );
            } else {
              localStorage.removeItem("uid");
              localStorage.removeItem("token");
              localStorage.removeItem("data");
              toaster.push(
                <Message type="error" message="Erreur de connexion" />,
                {
                  placement: "topEnd",
                }
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (even.data === "close") {
        dispatch(getUserProfile({ userId: localStorage.getItem("uid") }));
      }
    }
  });

  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <BrowserRouter
        getUserConfirmation={getConfirmation}
        forceRefresh={true}
        basename="/"
      >
        <Switch>
          <RouteWrapper
            isLoaded={isLoaded}
            /*isLoadedAuth={loadingAuth}*/ exact
            path={"/"}
          >
            <HomePage />
          </RouteWrapper>
          <RouteWrapper
            isLoaded={isLoaded}
            path={"/MyAccount"}
            authenticationRequired
            isAuthenticated={isAuthenticated}
          >
            <MonCompte />
          </RouteWrapper>
          <RouteWrapper
            isLoaded={isLoaded}
            path={"/MyPurchases"}
            authenticationRequired
            isAuthenticated={isAuthenticated}
          >
            <MesAchats />
          </RouteWrapper>
          <RouteWrapper
            isLoaded={isLoaded}
            path={"/AudioBook/:productId"}
            fullPage={true}
            authenticationRequired
            isAuthenticated={isAuthenticated}
          >
            <LivreAudio />
          </RouteWrapper>
          <RouteWrapper
            isLoaded={isLoaded}
            path={"/LiveEvent"}
            authenticationRequired
            isAuthenticated={isAuthenticated}
          >
            <LiveEvent />
          </RouteWrapper>

          <RouteWrapper
            exact
            isLoaded={isLoaded}
            path={"/MyList"}
            authenticationRequired
            isAuthenticated={isAuthenticated}
          >
            <MaListe />
          </RouteWrapper>
          <RouteWrapper
            exact
            isLoaded={isLoaded}
            path={"/Billetterie"}
            authenticationRequired
            isAuthenticated={isAuthenticated}
          >
            <Billetterie />
          </RouteWrapper>
          <RouteWrapper
            exact
            isLoaded={isLoaded}
            path={"/Payments"}
            authenticationRequired
            isAuthenticated={isAuthenticated}
          >
            <Payment />
          </RouteWrapper>
          <RouteWrapper isLoaded={isLoaded} exact path={"/cgv"}>
            <CGV />
          </RouteWrapper>
          <RouteWrapper isLoaded={isLoaded} exact path={"/confidentiality"}>
            <PolitiqueConf />
          </RouteWrapper>
          <RouteWrapper isLoaded={isLoaded} exact path={"/LegalNotices"}>
            <MentionsLegales />
          </RouteWrapper>
          <RouteWrapper isLoaded={isLoaded} exact path={"/seeAll/:id"}>
            <SeeAll />
          </RouteWrapper>
          <RouteWrapper
            fullPage={true}
            isLoaded={isLoaded}
            exact
            path={"/live/watch/:id"}
          >
            <LiveMagic />
          </RouteWrapper>
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </>
  );
};

const mapStateToProps = ({ userReducer }) => ({
  message: userReducer.message,
});

export default connect(mapStateToProps)(Router);
