import React, {useEffect, useLayoutEffect, useState} from 'react';
import { useMediaQuery } from '@material-ui/core';

/*
/!\For mobile/!\
Best practise height for vh in css example: height: calc(var(--vh, 1vh) * 100); or height: calc(var(--vh, 1vh) * 100 - 40px);
source: http://css-tricks.com/the-trick-to-viewport-units-on-mobile
 */
const useMobileOrientation = query => {
    const isMobile = useMediaQuery(query || '(max-width:812px)');
    const [isPortrait, setIsPortrait] = useState(window.innerWidth < window.innerHeight);

    const updateResize = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        setIsPortrait(window.innerWidth < window.innerHeight);
    };

    useLayoutEffect(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateResize);
        return () => {
            window.removeEventListener('resize', updateResize);
        };
    }, []);

    return { isMobile, isPortrait };
};

export const withMobileOrientation = Component => {
    return props => {
        const { isMobile, isPortrait } = useMobileOrientation();
        return <Component isMobile={isMobile} isPortrait={isPortrait} {...props} />;
    };
};

export default useMobileOrientation;
