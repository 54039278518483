import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closePopup } from '../../../redux/popup/actions';
import { styled, alpha } from '@mui/system';
import { Switch } from '@mui/material';
import { ThemeProvider } from '@mui/system';

import {
	defaultCookiesText,
	cookiesSettings,
	requiredCookies,
	cookiesLifeTime,
	performanceCookies
} from '../resources/cookies';

import logoImgSmall from '../../../assets/img/playstore.png';
import logoImgTall from '../../../assets/img/logo-white.png';

const IlsSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#ec00ff',
    '&:hover': {
      backgroundColor: alpha('#ec00ff', 0.3),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#ec00ff',
  },
}));

const CookiesSettings = ({ cookiePolicy, setCookiePolicy, handleSubmit }) => {
	return (
			<>
					<div className="popup-content-title">{cookiesSettings.title}</div>
					<div className="popup-content-text">{cookiesSettings.content}</div>
					<div className="popup-content-title">
							{requiredCookies.title}
							<IlsSwitch
									checked={cookiePolicy.required}
									style={{color:"#ec00ff",}}
									//color="primary"
							/>
							<span style={{color:'#ec00ff', fontSize: "0.8rem"}}>Toujours Actif</span>
					</div>
					<div className="popup-content-text">{requiredCookies.content}</div>
					<div className="popup-content-text">{cookiesLifeTime}</div>
					<div className="popup-content-title">
							{performanceCookies.title}
							<IlsSwitch
									checked={cookiePolicy.performance}
									style={{color:"#ec00ff"}}
									//color="primary"
									onChange={e =>
											setCookiePolicy(prev => ({ ...prev, performance: e.target.checked }))
									}
							/>
					</div>
					<div className="popup-content-text">{performanceCookies.content}</div>
					<div className="popup-content-form">
							<div className="popup-content-submit" onClick={() => handleSubmit(cookiePolicy)}>
									OK
							</div>
					</div>
			</>
	);
};

const CookiesDefault = ({ toggleSettings, cookiePolicy, handleSubmit }) => {
	return (
			<>
					<div className="popup-content-title">Préférence de cookies</div>
					<div className="popup-content-text">{defaultCookiesText}</div>
					<div className="popup-buttons-container">
							<div onClick={() => handleSubmit({ required: false, performance: false })}>
									Refuser
							</div>
							<div onClick={toggleSettings}>Personnaliser</div>
							<div onClick={() => handleSubmit(cookiePolicy)}>OK</div>
					</div>
			</>
	);
};

const Cookies = () => {
	const dispatch = useDispatch();
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);
	const toggleSettings = () => setIsSettingsOpen(!isSettingsOpen);

	const currentCookiePolicy = JSON.parse(localStorage.getItem('ils-cookies-policy')) ?? {
			required: true,
			performance: true
	};

	const [cookiePolicy, setCookiePolicy] = useState(currentCookiePolicy);
	
	const handleSubmit = object => {
		localStorage.setItem('ils-cookies-policy', JSON.stringify(object));
		dispatch(closePopup());
	};

	const props = { toggleSettings, cookiePolicy, setCookiePolicy, handleSubmit };

	return (
			<>
					<div className="popup-content-close">
							<button
									className="popup-content-close-button"
									onClick={() => dispatch(closePopup())}
							/>
					</div>
					<div className="popup-content-logo">
							<img
									className="popup-content-logo-sm"
									src={logoImgSmall}
									alt="logo Inlive Stream"
							/>
							<img className="popup-content-logo-xl" src={logoImgTall} alt="logo Inlive Stream" />
					</div>

					{isSettingsOpen && <CookiesSettings {...props} />}
					{!isSettingsOpen && <CookiesDefault {...props} />}
			</>
	);
};

export default Cookies;