import { initializeApp } from "firebase/app";
import constants from "../constants";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  deleteUser,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { firebaseConfig } from "../constants";
import ApiService from "./api.service";
import { red } from "@material-ui/core/colors";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const setStorage = (data) => {
  //  console.log("storage  data : ", data);
  //  console.log("storage data JSON.stringify", JSON.stringify(data, null));
  localStorage.setItem("token", data.accessToken);
  localStorage.setItem("uid", data.uid);
  localStorage.setItem("metadata", data.metadata);
  localStorage.setItem("data", data.data); //JSON.stringify(data.data));
};

const signInWithFacebook = async () => {
  let retour = {};
  try {
    const res = await signInWithPopup(auth, facebookProvider);
    //console.log("auth facebook : ", res);
    // Vérification si le user existe !
    const q = query(collection(db, "users"), where("uid", "==", res.user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      //le client n'est pas enregistré
      // Supprime le de la BDD
      const user = auth.currentUser;
      await deleteUser(user)
        .then((res) => {
          //console.log("res delete user : ", res);
        })
        .catch((error) => {
          console.log("error delete user : ", error);
        });

      //signOut(auth);
      retour = {
        ret: false,
        err: "Unknown user",
      };
    } else {
      // Enregistre le token et le uid
    }
  } catch (err) {
    retour = {
      ret: false,
      who: "facebook",
      err: err,
    };
  }
  return retour;
};

const signInWithGoogle = async () => {
  let retour = {};
  try {
    const res = await signInWithPopup(auth, googleProvider);
    //    console.log("res google : ", res);

    // Vérification si le user existe !
    const q = query(collection(db, "users"), where("uid", "==", res.user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      //le client n'est pas enregistré
      // Supprime le de la BDD
      const user = auth.currentUser;
      await deleteUser(user)
        .then((res) => {
          //console.log("res delete user : ", res);
        })
        .catch((error) => {
          console.log("error delete user : ", error);
        });

      //signOut(auth);
      retour = {
        ret: false,
        who: "google",
        err: "Unknown user",
      };
    } else {
      // Enregistre le token et le uid
    }
    //console.log("docs : ",docs.docs.length);

    /*
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
    */
  } catch (err) {
    retour = {
      ret: false,
      who: "google",
      err: err,
    };
  }
  return retour;
};

const registerWithGoogle = async (auth, googleProvider) => {
  let retour = {};
  try {
    // Vérification si le user existe !
    const res = await signInWithPopup(auth, googleProvider);

    const q = query(collection(db, "users"), where("uid", "==", res.user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      // Le client n'existe pas, enregistre le
      await addDoc(collection(db, "users"), {
        uid: res.user.uid,
        name: res.user.displayName,
        authProvider: "google",
        email: res.user.email,
      });
      retour = {
        ret: true,
        data: res.user,
      };
    } else {
      retour = {
        ret: false,
        err: "User exist",
      };
    }
  } catch (err) {
    retour = {
      ret: false,
      err: err,
    };
  }
  return retour;
};
const updateData = async (uid, datas) => {
  let retour = {};
  try {
    const q = query(collection(db, "users"), where("uid", "==", uid));
    //const docRef =  collection(db,"users", uid);
    const docs = await getDocs(q);
    const docsID = docs.docs[0].id;
    const myRef = doc(db, "users", docsID);
    const myData = {
      pseudo: datas.pseudo,
      gender: datas.gender,
      address: datas.address,
      address_comp: datas.address2 !== undefined ? datas.address2 : "",
      birthday: datas.birthday,
      city: datas.city,
      country: datas.country,
      displayName: datas.firstname + " " + datas.lastname,
      firstname: datas.firstname,
      lastname: datas.lastname,
      zipcode: datas.zipcode,
    };
    await updateDoc(myRef, myData);
    retour = {
      ret: true,
      data: myData,
    };
    /*
    const docRef = collection(db,"users");
    console.log("docRef : ", docRef);
    let monTest = db.collection("users").doc(docsID);
    console.log("monTest : ", monTest);
    */
    //const ret = await updateDoc(docRef,datas);
    //console.log("ret updateData : ", ret)
  } catch (err) {
    retour = {
      ret: false,
      err: err,
    };
  }
  return retour;
};

const logInWithEmailAndPassword = async (email, password) => {
  let retour = {};
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    //    console.log("res E/P: ", res);
    const user = res.user;

    const q = query(collection(db, "users"), where("uid", "==", user?.uid));
    const docs = await getDocs(q);

    const datas = docs.docs[0].data();
    //    console.log("docs : ", datas);

    //    console.log("user  : ",user);
    //    console.log("docs.docs.length : ", docs.docs.length);
    if (datas.uid) {
      const theData = {
        uid: user.uid,
        accessToken: user.accessToken,
        metadata: JSON.stringify(user.metadata),
        data: JSON.stringify(datas),
      };
      //    console.log("login res : ", res);
      //		if(res.user.email === email){
      retour = {
        ret: true,
        data: theData,
      };
      // Ici c'est Ok tout est bon on enregistre le token et le Uid et le data au cas où
      if (!constants.IDENTITY_ACCESS) setStorage(theData);
    }
    //console.log("res auth : ", res);
    //console.log("retour auth : ", retour);
  } catch (err) {
    //console.log("err : ", err);
    if (err.code === "auth/user-not-found") {
      // L'utilisateur n'existe pas dans Firebase
      // Vérifie s'il existe dans l'ancienne BDD
      const _ret = await ApiService.post(
        constants.URL_API + "/users/get_user_oldsite",
        {
          email: email,
          password: password,
        }
      );
      const retData = _ret.data;

      if (retData.ret) {
        if (retData.verifMail && retData.verifPassword) {
          // L'utilisateur est déja enregistré
          // On l'enregistre comme nouvel utilisateur dans FireBase
          try {
            const retEnreg = await registerWithEmailAndPassword(
              email,
              password,
              retData.data
            );
            retour = retEnreg;
            if (retour.ret === true) {
              setStorage(retour.data);
            }
            if (process.env.NODE_ENV === "development")
              console.log("enregistrement retour : ", retour);
          } catch (err) {
            console.log("Erreur retEnreg : ", err);
          }
        } else if (retData.verifMail) {
          // L'email existe mais le bon passe
          retour = {
            ret: false,
            message: "Le mot de passe n'est pas correct",
          };
        }
      } else {
        retour = {
          ret: false,
          message: " Inliver inconnu",
        };
      }
      if (process.env.NODE_ENV === "development")
        console.log("ret Old site result : ", _ret);
    }
    /*    
    console.log("catch erreurCode : ", err.code);
    console.log("catch erreurmessage : ", err.message);
    retour = {
      ret: false,
      err: err,
    };
*/
  }
  return retour;
};

const registerWithEmailAndPassword = async (email, password, data) => {
  let retour = {};
  try {
    // check if user exists
    const _ret = await ApiService.post(
      constants.URL_API + "/users/get_user_oldsite",
      {
        email: email,
        password: password,
      }
    );
    const retData = _ret.data;

    if (_ret.ret) {
      retour = {
        ret: false,
        msg: "Votre email existe déjà",
      };
    } else {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      //		console.log("create user ret : ", user);
      //		console.log("create user data : ", data);
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        email: email === undefined ? "" : email,
        displayName: data.firstname + " " + data.lastname,
        firstname: data.firstname === undefined ? "" : data.firstname,
        lastname: data.lastname === undefined ? "" : data.lastname,
        pseudo: data.pseudo === undefined ? "" : data.pseudo,
        birthday: data.birthday === undefined ? "" : data.birthday,
        gender: data.gender === undefined ? "" : data.gender,
        address: data.address === undefined ? "" : data.address,
        address_comp: data.adress2 === undefined ? "" : data.adress2,
        zipcode: data.zipcode === undefined ? "" : data.zipcode,
        city: data.city === undefined ? "" : data.city,
        country: data.country === undefined ? "" : data.country,
        slug: data.slug === undefined ? "" : data.slug,
        authProvider: "local",
      });
      retour = {
        ret: true,
        data: user,
      };
    }
  } catch (err) {
    //console.log("register with email - catch err : ", err);
    let msg = "";
    if (err.code == "auth/email-already-in-use") {
      msg = "L'email existe déjà";
    } else {
      msg = err.message;
    }
    retour = {
      ret: false,
      msg: msg,
    };
  }
  return retour;
};

const sendPasswordReset = async (email) => {
  let retour = {};
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    retour = {
      ret: false,
      err: err,
    };
  }
  return retour;
};

const logout = () => {
  // signOut(auth);
  localStorage.removeItem("uid");
  localStorage.removeItem("token");
  localStorage.removeItem("data");
};

const reauthenticate = async (currentPassword) => {
  var user = auth.currentUser;
  var cred = await EmailAuthProvider.credential(user.email, currentPassword);
  return await reauthenticateWithCredential(user, cred);
};
const changePassword = async (oldPassword, newPassword) => {
  let retour = {};
  try {
    await reauthenticate(oldPassword)
      .then(() => {
        var user = auth.currentUser;
        updatePassword(user, newPassword)
          .then(
            (retour = {
              ret: true,
            })
          )
          .catch((error) => {
            retour = {
              ret: false,
              err: error,
            };
            console.log("error1 : ", error);
          });
      })
      .catch((error) => {
        let errMsg = "";
        switch (error.code) {
          case "auth/too-many-requests":
            errMsg =
              "L'accès à ce compte a été temporairement désactivé en raison de nombreuses tentatives de connexion infructueuses. Vous pouvez le rétablir immédiatement en réinitialisant votre mot de passe ou vous pouvez réessayer plus tard";
            break;

          case "auth/invalid-password":
          default:
            errMsg = "Le mot de passe actuel n'est pas le bon";
            break;
        }
        retour = {
          ret: false,
          err: errMsg,
        };
        console.log("erreur : ", error);
      });
    console.log("retour: ", retour);

    return retour;
  } catch (err) {
    console.log("erreur : ", err);
  }
};
export {
  auth,
  db,
  signInWithGoogle,
  signInWithFacebook,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  registerWithGoogle,
  sendPasswordReset,
  logout,
  updateData,
  changePassword,
};
