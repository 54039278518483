const constants = {
	
	STRIPE_TEST: 'pk_test_51L99rJLXyzBlwKpfUu6ujiQikvjyVaJYGgyNU5U4ycsuV6weaGBHYxHG8QHnUpfVqHQgtWWIgXzbr21s7huKJFcE00vHMSK5NY',
	STRIPE_PROD: 'pk_live_51L99rJLXyzBlwKpf5fji9cTfEq7VFal3rMHUEgQy1VA6GsfYdVS4I9AkRqx7g2MXI3llTKULG07AOjHb0YFbFTzh00HJvzEMHf',
	
	FETCH_API_ERROR: "FETCH_API_ERROR",
	FETCH_GETVIDEOAPI_ERROR: "FETCH_GETVIDEOAPI_ERROR",

	FETCH_PRODUCT: "FETCH_PRODUCT",
	FETCH_ENCODED_PRODUCT: "FETCH_ENCODED_PRODUCT",
	FETCH_PURCHASE_PRODUCT: 'FETCH_PURCHASE_PRODUCT',

	POPUP_TYPE_RENTAL: 'popup-payment-rental', 

	JWTtoken: function() {
        return localStorage.getItem("token");
    },

	URL_API: (process.env.REACT_APP_STAGE === "production" ) ? "https://vapi.inlive-stream.com/v1" : "https://vapi-dev.inlive-stream.com/v1",
    URL_FILE: "https://vcdn.inlive-stream.com/img/",
	DEVICE_VERSION: "platform_web|" + detectBrowser() + "|" + detectEnv() + "|v220901",

	IDENTITY_ACCESS: true,
	IDENTITY_DEV: true,
	BASE_URL_IDENTITY : process.env.REACT_APP_STAGE === "production" ? "https://identity.ilwith.io/identity" : "https://stag.identity.ilwith.io/identity",
	BASE_URL_LOGIN: (process.env.REACT_APP_STAGE === "development") ? "https://stag.identity.ui.ilwith.io" : (process.env.REACT_APP_STAGE === "staging") ? 
	"https://stag.identity.ui.ilwith.io" : (process.env.REACT_APP_STAGE === "production") ? "https://account.inlive-group.com" : "https://stag.identity.ui.ilwith.io",

	URL_CALLBACK : (process.env.REACT_APP_STAGE === "development") ? "http://localhost:5041" : (process.env.REACT_APP_STAGE === "staging") ? 
	"https://staging.inlive-stream.com" : (process.env.REACT_APP_STAGE === "production") ? "https://www.inlive-stream.com/" : 'http://localhost:5041',

};

export default constants;

export const identity = {
	Login : () => {
		window.open(constants.BASE_URL_LOGIN + "/auth/login?callback=" + constants.URL_CALLBACK , 'targetWindow', `toolbar=no, location=no, status=no,
			menubar=no, scrollbars=yes, resizable=yes, width=1050, height=640` );
	},
	Register : () => {
		window.open(constants.BASE_URL_LOGIN + "/auth/register?callback=" + constants.URL_CALLBACK , 'targetWindow', `toolbar=no, location=no, status=no,
			menubar=no, scrollbars=yes, resizable=yes, width=1050, height=640` );
	},
}

export const URL_API_IMG = "https://vcdn.inlive-stream.com/ilw";
export const URL_API_IMG_PUB = "https://vcdn.inlive-stream.com/ilw";

export const popupNames = {
	payment: 'popup-payment',
	login: 'popup-login',
	cookies: 'popup-cookies',
	registration: 'popup-registration',
	forgotPassword: 'popup-forgot-password',
	detailSheet: 'popup-detail-sheet',
	livePayment: 'popup-live-payment'
}

export const firebaseConfig = {
  apiKey: "AIzaSyCj-bVvGMOXWYCuBiGyF6fA0gDr4lAKP_4",
  authDomain: "inlive-auth.firebaseapp.com",
  projectId: "inlive-auth",
  storageBucket: "inlive-auth.appspot.com",
  messagingSenderId: "256224681560",
  appId: "1:256224681560:web:aed37cd69964a760b2b42d",
  measurementId: "G-T38N1H90FY"
};

export const Path = {
	MyAccount : "/MyAccount",
	MyList: "/MyList",
}

export const authError = {
	"auth/admin-restricted-operation": "",
	"auth/argument-error": "",
	"auth/app-not-authorized": "",
	"auth/app-not-installed": "",
	"auth/captcha-check-failed": "",
	"auth/code-expired": "",
	"auth/cordova-not-ready": "",
	"auth/cors-unsupported": "",
	"auth/credential-already-in-use": "",
	"auth/custom-token-mismatch": "",
	"auth/requires-recent-login": "",
	"auth/dependent-sdk-initialized-before-auth": "",
	"auth/dynamic-link-not-activated": "",
	"auth/email-change-needs-verification": "",
	"auth/email-already-in-use": "",
	"auth/emulator-config-failed": "",
	"auth/expired-action-code": "",
	"auth/cancelled-popup-request": "",
	"auth/internal-error": "",
	"auth/invalid-api-key": "",
	"auth/invalid-app-credential": "",
	"auth/invalid-app-id": "",
	"auth/invalid-user-token": "",
	"auth/invalid-auth-event": "",
	"auth/invalid-cert-hash": "",
	"auth/invalid-verification-code": "",
	"auth/invalid-continue-uri": "",
	"auth/invalid-cordova-configuration": "",
	"auth/invalid-custom-token": "",
	"auth/invalid-dynamic-link-domain": "",
	"auth/invalid-email": "",
	"auth/invalid-emulator-scheme": "",
	"auth/invalid-credential": "",
	"auth/invalid-message-payload": "",
	"auth/invalid-multi-factor-session": "",
	"auth/invalid-oauth-client-id": "",
	"auth/invalid-oauth-provider": "",
	"auth/invalid-action-code": "",
	"auth/unauthorized-domain": "",
	"auth/wrong-password": "",
	"auth/invalid-persistence-type": "",
	"auth/invalid-phone-number": "",
	"auth/invalid-provider-id": "",
	"auth/invalid-recipient-email": "",
	"auth/invalid-sender": "",
	"auth/invalid-verification-id": "",
	"auth/invalid-tenant-id": "",
	"auth/multi-factor-info-not-found": "",
	"auth/multi-factor-auth-required": "",
	"auth/missing-android-pkg-name": "",
	"auth/missing-app-credential": "",
	"auth/auth-domain-config-required": "",
	"auth/missing-verification-code": "",
	"auth/missing-continue-uri": "",
	"auth/missing-iframe-start": "",
	"auth/missing-ios-bundle-id": "",
	"auth/missing-or-invalid-nonce": "",
	"auth/missing-multi-factor-info": "",
	"auth/missing-multi-factor-session": "",
	"auth/missing-phone-number": "",
	"auth/missing-verification-id": "",
	"auth/app-deleted": "",
	"auth/account-exists-with-different-credential": "",
	"auth/network-request-failed": "",
	"auth/null-user": "",
	"auth/no-auth-event": "",
	"auth/no-such-provider": "",
	"auth/operation-not-allowed": "",
	"auth/operation-not-supported-in-this-environment": "",
	"auth/popup-blocked": "",
	"auth/popup-closed-by-user": "",
	"auth/provider-already-linked": "",
	"auth/quota-exceeded": "",
	"auth/redirect-cancelled-by-user": "",
	"auth/redirect-operation-pending": "",
	"auth/rejected-credential": "",
	"auth/second-factor-already-in-use": "",
	"auth/maximum-second-factor-count-exceeded": "",
	"auth/tenant-id-mismatch": "",
	"auth/timeout": "",
	"auth/user-token-expired": "",
	"auth/too-many-requests": "",
	"auth/unauthorized-continue-uri": "",
	"auth/unsupported-first-factor": "",
	"auth/unsupported-persistence-type": "",
	"auth/unsupported-tenant-operation": "",
	"auth/unverified-email": "",
	"auth/user-cancelled": "",
	"auth/user-not-found": "",
	"auth/user-disabled": "",
	"auth/user-mismatch": "",
	"auth/user-signed-out": "",
	"auth/weak-password": "",
	"auth/web-storage-unsupported": "",
	"auth/already-initialized": "",
}

function detectBrowser() {
	const userAgent = navigator.userAgent;
	const match = userAgent.match( /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i ) || [];
	let test;
		
    if( /trident/i.test( match[1] ) ) {
		test = /\brv[ :]+(\d+)/g.exec( userAgent ) || [];
		return 'IE'+ ( test[1] || '' );
	}
	
    if( match[1] === 'Chrome' ) {
        test = userAgent.match( /\b(OPR|Edge)\/(\d+)/ );
        if( test ) return test.slice(1).join( '' ).replace( 'OPR', 'Opera' );
	}

	// let resultArr = match[2] ? [ match[1], match[2] ] : [ navigator.appName, navigator.appVersion, '-?' ];
	let resultArr = match[2] ? [ match[1], match[2] ] : [ 'unknown', '' ];
	test = userAgent.match( /version\/(\d+)/i );
	if ( test ) resultArr.splice( 1, 1, test[1] );

    return resultArr.join( '' );
};

function detectEnv() {
	const userAgent = navigator.userAgent;

	if ( [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes( navigator.platform )
			|| ( userAgent.includes("Mac") && "ontouchend" in document ) ) return 'iOS';

	const match = userAgent.match( /(Android|BlackBerry|Opera Mini|IEMobile|WPDesktop)/i );
	if ( match ) {
		if ( match[ 0 ] === 'Opera Mini' ) return 'Opera';
		if ( [ 'IEMobile', 'WPDesktop' ].includes( match[ 0 ] ) ) return 'Windows';

		return match[ 0 ];
	}
	
	return 'desktop';
};