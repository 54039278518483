import constants from "../constants";
//import apiService from './api.service';
import axios from "axios";
import { logout } from "./firebase";

const myHeader = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + constants.JWTtoken(),
  },
};
const getRentalProduct = async (data, id) => {
  try {
    let prod = await axios.get(
      constants.URL_API + "/users/get_rental?uid=" + id,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      }
    );
    return prod;
  } catch (err) {
    if (err.response.status === 401) {
      logout();
      localStorage.removeItem("firebaseid");
      localStorage.removeItem("slug");
    }
    return err;
  }
};

const getPurchaseProduct = async (data, id) => {
  try {
    let prod = await axios.post(
      constants.URL_API + "/users/get_purchase",
      {
        slug: data?.slug || "",
        uid: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.token,
        },
      }
    );
    return prod;
  } catch (err) {
    if (err.response.status === 401) {
      logout();
      localStorage.removeItem("firebaseid");
      localStorage.removeItem("slug");
    }
    return err;
  }
};

const updateProgressMovies = async (data) => {
  const upt = await axios.post(
    constants.URL_API + "/videos/updateProgress",
    {
      data: data,
    },
    myHeader
  );
  //console.log("uPM : ", data);
  return upt;
};
const getProgressMovies = async (data) => {
  const upt = await axios.post(
    constants.URL_API + "/videos/getProgress",
    {
      data: data,
    },
    myHeader
  );
  //console.log("uPM : ", data);
  return upt;
};

export {
  getPurchaseProduct,
  getRentalProduct,
  updateProgressMovies,
  getProgressMovies,
};
