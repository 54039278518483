import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import useMobileOrientation from '../../../hook/useMobileOrientation';

import { getPurchaseProduct } from "../../../helpers/product.service";

import Book from "./component/book";
import { pages_1, book_1 } from "../../../constants/book";
import { ReactComponent as IconPrevious } from '../../../assets/icon/previous.svg';

import './_livreAudio.scss';
 
const LivreAudio = (ident) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const  {isMobile}  = useMobileOrientation('(max-width: 911px)');
	const { productId } = useParams();

	const [purchaseProd, setPurchaseProd] = useState(null);
	const [isPurchaseLoaded, setIsPurchaseLoaded] = useState(false);
	const [isAuthorized, setAuthorized] = useState(false); 
	const [dataPurch, setDataPurch] = useState();

	const meUser = useSelector( state => state.userReducer.userInfo);
	const isAuthenticated = meUser?.uid ? true : false;
	
	useEffect(() => {
		( async () => {
			try {
				let result = await getPurchaseProduct(meUser.data);

				setPurchaseProd(result.data.data);
				setIsPurchaseLoaded(true);

				//Check if productId is in Purchase product
				const mPurchaseProd = result.data.data;
				const isProdOK = mPurchaseProd.filter( r =>{ return (r.id === productId) });
				console.log("isProdOK : ", isProdOK.length )
				if( isProdOK.length === 0){
					window.open('/',"_self");
				}else{
					setAuthorized(true);
				}

				//if(process.env.NODE_ENV === "development")  console.log("purchaseProd.lenght : ", Object.keys(purchaseProd).length);
			}catch(e){
				console.log("catch e: ", e);
				window.open('/',"_self");
				//history.replace('/');
				//history.goBack();
			}
		})()
	},[]);

	return (
		<>
			{isPurchaseLoaded &&
        <div className="reader">
					<div className="reader__icon-container --return" onClick={() => history.goBack()}>
							<IconPrevious />
					</div>
					{ isAuthorized && 
						<Book book={book_1} pages={pages_1} />
					}
			</div>
			}
		</>
	)
}

export default LivreAudio;