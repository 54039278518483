import {types} from './types';

const initialState = {
	product: {},
	encodedProduct: null,
	productID: {},
	productRow: {},
	productList: {},
	productPurchase: {},
	liveProduct: {},
	refreshPurchase: null,
}

const productReducer = (state = initialState, action) => {
	const newState = Object.assign({}, state);

	switch (action.type) {
		case types.REFRESH_PURCHASE:
			newState.refreshPurchase = action.payload;
			return newState;

		case types.FETCH_PURCHASE_PRODUCT:
			action.payload.then((res) => {
				newState.productPurchase = res;
			}).catch(err => {
				console.log("err : ", err);
			});
			return newState;

		case types.FETCH_ENCODED_PRODUCT:
			newState.encodedProduct = action.payload;
			return newState;
		case types.FETCH_LIVE_PRODUCT:
			newState.liveProduct = action.payload;
			return newState;
		default:
			return newState;
	}
}

export default productReducer;