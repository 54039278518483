import React from "react";
import ElevateAppBarMobile from "./navBar";
import Footer from "../../footer";

const MobileLayout = ({children}) => {
	return (
		<div className="webLayout">
			<ElevateAppBarMobile>
			<div className="conteneur">
				{children}
			</div>
			<Footer />
			</ElevateAppBarMobile>

		</div>
	)
}

export default MobileLayout;