import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import constants, { identity, popupNames } from '../../constants';
import { closePopup } from '../../redux/popup/actions';
import Cookies from './components/cookies';
import Payment from './components/payment';
import Login from './components/login';
import Registration from './components/registration';
import ForgottenPassword from './components/forgottenPassword';
import DetailSheet from './components/detailSheet';

import {Audio as Loader} from 'react-loader-spinner';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import useMobileOrientation from '../../hook/useMobileOrientation';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr";
import DateFnsUtils from "@date-io/date-fns";
import LivePayment from './components/live/payment';


const PopupHandler = () => {
	const dispatch = useDispatch();
	const { open: isOpen, name: popupName } = useSelector(state => state.popupReducer);
	const [showLoader, setShowLoader] = useState(false);

	const  {isMobile}  = useMobileOrientation('(max-width: 911px)');

	useEffect( () => {
		if( isOpen ){
			document.documentElement.style.overflow="hidden";
		}else{
			document.documentElement.style.overflow="initial";
		}
	},[isOpen]);

  function closeModal() {
		document.documentElement.style.overflow="initial";
		dispatch(closePopup());
	}

	const customStyles = {
		content: {
			//top: "5px",
			//width: "50%",
			//marginLeft: "25%",
			display: "flex",
			flexDirection: "column",
			position: 'relative',
			top: '5%',
			left: '25%',
			transform: 'translate(-50%, -90%)',
			width:'50%',
			height: '90%',
			background: "rgb(23, 22, 22)",
			border: "none",
//			padding: "none",
		},
	};

	const customStylesMobiles = {
		content: {
			//top: "5px",
			//width: "50%",
			//marginLeft: "25%",
			display: "flex",
			flexDirection: "column",
			position: 'relative',
			top: '0',
			left: '0',
			width:'100%',
			height: '100%',
			background: "rgb(23, 22, 22)",
			border: "none",
//			padding: "none",
		},
	};

	
	const materialTheme = createTheme({
    palette: {
        primary: {
            light: '#f133ff',
            main: '#ec00ff',
            dark: '#b500cb'
        },
        grey: {
            light: '#aeaeae',
            main: '#2b2b2b',
            dark: '#121212'
        }
    }
	});

	return (
		<>
			<Modal
				closeTimeoutMS={500}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={isMobile ? customStylesMobiles : customStyles}
				className="popup"
//        contentLabel="Example Modal"
				overlayClassName="pOverlay"
			>
				<ThemeProvider theme={materialTheme}>

					<div className={`pContent${popupName === popupNames.detailSheet ? " mobile":""}`}>
						{popupName === popupNames.cookies && (
							<Cookies setShowLoader={setShowLoader} />
						)}
						{popupName === popupNames.payment && (
							<Payment setShowLoader={setShowLoader} />
						)}
						{popupName === popupNames.livePayment && (
							<LivePayment setShowLoader={setShowLoader} />
						)}
						{popupName === popupNames.login && (
							constants.IDENTITY_ACCESS
							? 
								identity.Login()
							:
								<Login setShowLoader={setShowLoader} />
						)}
						{popupName === popupNames.registration && (
								identity.Register()
						)}
						{popupName === popupNames.forgotPassword && (
							<ForgottenPassword setShowLoader={setShowLoader} />
						)}
						{popupName === popupNames.detailSheet && (
							<DetailSheet setShowLoader={setShowLoader} />
						)}
					</div>

					{showLoader && (
							<div className="popup-content-loader">
									<Loader
											type="TailSpin"
											color="#ec00ff"
											height={100}
											width={100}
									/>
							</div>
					)}
				</ThemeProvider>
			</Modal>
				
		</>
	)
}
export default PopupHandler;