import { types } from "./types";

/**
 * @description user infos.
 */

export const getStreams = () => ({
	type: types.GET_STREAM_REQUEST,
	payload: null
})


export const getCurrentLive = (payload) => ({
	type: types.CURRENT_LIVE_REQUEST,
	payload: payload
})

export const verifyCode = (payload) => ({
	type: types.VERIFY_CODE_REQUEST,
	payload: payload
})

export const getPurchasedStreams = (payload) => ({
	type: types.PURCHASE_STREAM_REQUEST,
	payload: payload
})

export const getMagicLink = (payload) => ({
	type: types.MAGIC_LINK_REQUEST,
	payload: payload
})

export const getMagicStream = (payload) => ({
	type: types.MAGIC_STREAM_REQUEST,
	payload: payload
})

export const closeLink = (payload) => ({
	type: types.CLOSE_LINK_REQUEST,
	payload: payload
})