export const book_1 = {
	display: "double",
	hardCover: true,
	hasCover: true,
	hasShadow: true,
	id: 1,
	isActive: true,
	ratio: "1.11",
	status: "online",
}
export const pages_1 = [
		{
			"displayOrder": 1,
			"hasAudio": true,
			"id": 1,
			"isActive": true,
			"isHardPage": true,
			"mediaAudio": {
				"id": 8,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630943181",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Couv new.mp3"
			},
			"mediaImage": {
				"id": 7,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631173515",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "1-COUVERTURE.jpg"
			},
			"noAudioTimer": 0,
		},
		{
			"displayOrder": 2,
			"hasAudio": true,
			"id": 2,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 10,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630943172",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 2_3 new.mp3"
			},
			"mediaImage": {
				"id": 9,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631173525",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "2-EPILOGUE.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 3,
			"hasAudio": false,
			"id": 3,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 129,
				"type": null,
				"filename": "3-EPILOGUE",
				"extension": "jpg",
				"altText": "3-EPILOGUE.jpg",
				"avatar": null,
				"displayFilename": "3-EPILOGUE.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 4,
			"hasAudio": true,
			"id": 4,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 13,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630943202",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 4_5 new.mp3"
			},
			"mediaImage": {
				"id": 12,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631173552",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "4-AUDITIONSDANSEURS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 5,
			"hasAudio": false,
			"id": 5,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 14,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631173559",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "5-AUDITIONSDANSEURS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 6,
			"hasAudio": true,
			"id": 6,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 16,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630943210",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 6_7 new.mp3"
			},
			"mediaImage": {
				"id": 15,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631173606",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "6-REPET MUSICIENS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 7,
			"hasAudio": false,
			"id": 7,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 17,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631173610",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "7-REPETMUSICIENS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"createdAt": null,
			"displayOrder": 8,
			"hasAudio": true,
			"id": 70,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 236,
				"type": null,
				"filename": "Bed Page 8_9 new-1639322307",
				"extension": "mp3",
				"altText": "Bed Page 8_9 new-1639322307.mp3",
				"avatar": null,
				"displayFilename": "Bed Page 8_9 new-1639322307.mp3"
			},
			"mediaImage": {
				"id": 242,
				"type": null,
				"filename": "audioBook-610954fb343958-image-1631173620-1639323029",
				"extension": "jpg",
				"altText": "audioBook-610954fb343958-image-1631173620-1639323029.jpg",
				"avatar": null,
				"displayFilename": "audioBook-610954fb343958-image-1631173620-1639323029.jpg"
			},
			"noAudioTimer": 2,
			"updatedAt": null
		},
		{
			"displayOrder": 9,
			"hasAudio": false,
			"id": 9,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 20,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631173619",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "9-REPETMUSICIENS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 10,
			"hasAudio": true,
			"id": 10,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 22,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630943250",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 10_11 new.mp3"
			},
			"mediaImage": {
				"id": 21,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175757",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "10-REPETDANSEURS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 11,
			"hasAudio": false,
			"id": 11,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 23,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175745",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "11-REPETDANSEURS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 12,
			"hasAudio": true,
			"id": 12,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 25,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630944850",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 12_13 new.mp3"
			},
			"mediaImage": {
				"id": 24,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175772",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "12-LESONDESSONTBONNES.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 13,
			"hasAudio": false,
			"id": 13,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 140,
				"type": null,
				"filename": "audioBook-610954fb343958-image-1631175801",
				"extension": "jpg",
				"altText": "audioBook-610954fb343958-image-1631175801.jpg",
				"avatar": null,
				"displayFilename": "audioBook-610954fb343958-image-1631175801.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 14,
			"hasAudio": true,
			"id": 14,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 28,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630944963",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 16_17 new.mp3"
			},
			"mediaImage": {
				"id": 27,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175782",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "14-ISAIAH.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 15,
			"hasAudio": false,
			"id": 15,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 29,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175800",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "15-ISAIAH.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 16,
			"hasAudio": true,
			"id": 16,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 31,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630944884",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 14_15 new.mp3"
			},
			"mediaImage": {
				"id": 30,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175805",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "16-GRADINS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 17,
			"hasAudio": false,
			"id": 17,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 32,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175830",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "17-GRADINS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 18,
			"hasAudio": true,
			"id": 18,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 34,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630945053",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 18_19 new.mp3"
			},
			"mediaImage": {
				"id": 33,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175940",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "18-HAKA.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 19,
			"hasAudio": false,
			"id": 19,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 35,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175850",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "19-HAKA.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 20,
			"hasAudio": true,
			"id": 20,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 37,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630945084",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 20_21 new.mp3"
			},
			"mediaImage": {
				"id": 36,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175990",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "20-SCENE.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 21,
			"hasAudio": false,
			"id": 21,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 38,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175892",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "21-SCENE.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 22,
			"hasAudio": true,
			"id": 22,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 40,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630945103",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 22_23 new.mp3"
			},
			"mediaImage": {
				"id": 39,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175901",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "22-PUBLIC.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 23,
			"hasAudio": false,
			"id": 23,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 41,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175900",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "23-PUBLIC.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 24,
			"hasAudio": true,
			"id": 24,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 43,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1631177049",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 24_25 new.mp3"
			},
			"mediaImage": {
				"id": 42,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631175950",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "24-BOOM.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 25,
			"hasAudio": false,
			"id": 25,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 44,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631176126",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "25-BOOM.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 26,
			"hasAudio": true,
			"id": 26,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 46,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1631177275",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 26_27 new.mp3"
			},
			"mediaImage": {
				"id": 45,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631178229",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "26-DANSEURS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 27,
			"hasAudio": false,
			"id": 27,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 47,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631178266",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "27-DANSEURS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 28,
			"hasAudio": true,
			"id": 28,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 49,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1631178417",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 28_29 new.mp3"
			},
			"mediaImage": {
				"id": 48,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631178243",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "28-DANSEURS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 29,
			"hasAudio": false,
			"id": 29,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 50,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631178263",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "29-DANSEURS.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 30,
			"hasAudio": true,
			"id": 30,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 52,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1631178445",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 30_31 new.mp3"
			},
			"mediaImage": {
				"id": 51,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631178595",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "30-FINCONCERT.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 31,
			"hasAudio": false,
			"id": 31,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 53,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631178273",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "31-FINCONCERT.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 32,
			"hasAudio": true,
			"id": 32,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": {
				"id": 55,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630946162",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 32\u201433 new.mp3"
			},
			"mediaImage": {
				"id": 54,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631176211",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "32-MATT.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 33,
			"hasAudio": false,
			"id": 33,
			"isActive": true,
			"isHardPage": false,
			"mediaAudio": null,
			"mediaImage": {
				"id": 56,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631176227",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "33-MATT.jpg"
			},
			"noAudioTimer": 2
		},
		{
			"displayOrder": 34,
			"hasAudio": true,
			"id": 34,
			"isActive": true,
			"isHardPage": true,
			"mediaAudio": {
				"id": 58,
				"type": "audio",
				"filename": "audioBook-610954fb343958-sound-1630945242",
				"extension": "mp3",
				"altText": null,
				"avatar": null,
				"displayFilename": "Bed Page 33 new.mp3"
			},
			"mediaImage": {
				"id": 57,
				"type": "image",
				"filename": "audioBook-610954fb343958-image-1631176650",
				"extension": "jpg",
				"altText": null,
				"avatar": null,
				"displayFilename": "34-FIN.jpg"
			},
			"noAudioTimer": 2
		}
	]