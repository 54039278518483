import { call, put, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import constants from "../../constants";
import { getRequest, getUnauthRequest, postRequest, putRequest } from '../../helpers/api';
import toast, { Toaster } from 'react-hot-toast';

function* getUserProfile({payload}) {
  try {
    let data = yield getUnauthRequest(`${constants.BASE_URL_IDENTITY}/Account/SearchById/${payload.userId}`);
    if(data.res === 0){
      localStorage.setItem("firebaseid", JSON.stringify(data?.data?.firebaseUUID));
      localStorage.setItem("slug", JSON.stringify(data?.data?.slug));
      yield put({ type: types.GET_PROFILE_SUCCESS, payload: data?.data });
    }else{
        
        yield put({ type: types.GET_PROFILE_FAILURE, payload: data?.error });
    }
    } catch (error) {
    console.log(error);
    yield put({ type: types.GET_PROFILE_FAILURE, payload: error });
  }
}

function* updateProfile({payload}) {
    try {
        let data = yield putRequest(`${constants.BASE_URL_IDENTITY}/Account/Update/${payload.userId}`, JSON.stringify(payload.updateData));
        if(data.res === 0){
            yield put({ type: types.UPDATE_PROFILE_SUCCESS, payload: data?.data });
            toast.success('Les données ont bien été enretistrées.');
            // yield call(getUserProfile, {userId: payload.userId});
        }else{
            yield put({ type: types.UPDATE_PROFILE_FAILURE, payload: data?.error });
            toast.error("Aucune donnée n'a été sauvegardée." + data?.error);
        }
      } catch (error) {
        console.log(error);
        yield put({ type: types.UPDATE_PROFILE_FAILURE, payload: error });
        toast.error("Aucune donnée n'a été sauvegardée." + error?.statusText);
      }
}

function* getUserCountry({payload}) {
  try {
    let data = yield getRequest(`${constants.URL_API}/users/user_details?userId=${payload.userId}&metaName=${payload.metaName}`);
    
    if(data.ret){
      if(data.data.length>0){
        localStorage.setItem("metaCountry", data?.data[0].meta_value);
      }
    }
    } catch (error) {
    console.log(error);
  }
}

function* addMetaCountry({payload}) {
  try {
      let data = yield postRequest(`${constants.URL_API}/users/user_details`, JSON.stringify(payload));
      if(data.ret){
          toast.success("Donnée sauvegardée avec success");
          localStorage.setItem("metaCountry", payload.metaValue);
          yield put({ type: types.ADD_META_COUNTRY_SUCCESS });
      }else{
          toast.error("Aucune donnée n'a été sauvegardée.");
      }
    } catch (error) {
      console.log(error);
      toast.error("Aucune donnée n'a été sauvegardée.");
    }
}


function* getPublicCountry() {
  try {
    let data = yield getRequest(`${constants.URL_API}/countries/publicList`);
   
    if(data.ret){
      yield put({ type: types.GET_ALL_COUNTRY_SUCCESS, payload: data.data });
    }
    } catch (error) {
    console.log(error);
  }
}

export default function* UserSaga() {
  yield takeLatest(types.GET_PROFILE_REQUEST, getUserProfile);
  yield takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfile);
  yield takeLatest(types.GET_USER_COUNTRY, getUserCountry);
  yield takeLatest(types.ADD_META_COUNTRY, addMetaCountry);
  yield takeLatest(types.GET_ALL_COUNTRY, getPublicCountry);
}