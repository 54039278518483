import axios from "axios";
import constants from "../../constants";
import {types} from "./types"

export const fetchEncodedProduct = data => ({
	type: types.FETCH_ENCODED_PRODUCT,
	payload: data
});

export const fetchLive = payload => ({
	type: types.FETCH_LIVE_PRODUCT,
	payload: payload
})

export const refreshPurchase = () => ({
	type: types.REFRESH_PURCHASE,
	payload: new Date()
});

export const fetchPurchaseProduct = datas =>({
	type: types.FETCH_PURCHASE_PRODUCT,
	payload: axios.post(constants.URL_API + "/users/get_purchase",{"slug": datas.slug,"uid": datas.uid},{			headers: {
		'Content-Type': 'application/json',
		'Authorization': "Bearer " + constants.JWTtoken()
	},
}).then( (response) => {
	if(response.data.ret)
		return response.data.data;
}).then( (data) => {
	return (data);
})
})