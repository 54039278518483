import {types} from './types';

export const openPopup = (popupName, type, data_gab, data_purch) => ({
	type: types.OPEN_POPUP,
	payload: { 
		open: true, 
		name: popupName, 
		type: type || null, 
		data_gab: data_gab || null, 
		data_purch: data_purch || null 
	}
});

export const closePopup = () => ({
	type: types.CLOSE_POPUP
});

