export const types = {
    //user info 
    FETCH_USER_INFO: 'FETCH_USER_INFO',

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

    GET_USER_COUNTRY: 'GET_USER_COUNTRY',
    ADD_META_COUNTRY: 'ADD_META_COUNTRY',
    ADD_META_COUNTRY_SUCCESS: 'ADD_META_COUNTRY_SUCCESS',
    GET_ALL_COUNTRY: 'GET_ALL_COUNTRY',
    GET_ALL_COUNTRY_SUCCESS: 'GET_ALL_COUNTRY_SUCCESS',

};