import React from "react";
import { Link } from "react-router-dom";
import logoILME from "../../assets/LOGO/PNG/ME.png";
import logoILSTORE from "../../assets/LOGO/PNG/STORE.png";
import logoILWITH from "../../assets/LOGO/PNG/WITH.png";
import logoILTickets from '../../assets/LOGO/PNG/TICKETS.png';
import CB from "../../assets/LOGO/cb.png";
import AE from '../../assets/LOGO/american_express-1.png';
import VISA from "../../assets/LOGO/visa.png";
import INSTA from "../../assets/LOGO/instagram.png";
import LINKEDIN from "../../assets/LOGO/linkedin.png";
import FACEBOOK from "../../assets/LOGO/facebook.png";
import YOUTUBE from "../../assets/LOGO/youtube.png";
import TIKTOK from "../../assets/LOGO/tiktok.png";
import TWITTERX from "../../assets/LOGO/x.png";

import { useDispatch } from "react-redux";
import { popupNames } from "../../constants";
import { openPopup } from "../../redux/popup/actions";

const Footer = () => {
	const dispatch = useDispatch();
	return (
		<div className="row rowFooter"> 
			<div className="col-sm-3">
				<div className="titreFooter">NOS UNIVERS</div>
				<div><a href="https://www.inlivewith.com" target="_blank"><img src={logoILWITH} alt="Inlive Width" className="logoFooter" /> </a></div>
				<div><a href="https://www.inlive-store.com" target="_blank" rel="noreferrer"><img src={logoILSTORE} alt="Inlive Store" className="logoFooter" /></a></div>
				<div><a href="https://www.inlive-tickets.com" target="_blank" rel="noreferrer"><img src={logoILTickets} alt="Inlive ME" className="logoFooter" /></a></div>
				<div><a href="https://www.inlive-me.com" target="_blank" rel="noreferrer"><img src={logoILME} alt="Inlive ME" className="logoFooter" /></a></div>
			</div>
			<div className="col-sm-3">
				<div className="titreFooter">INFOS LÉGALES</div>
				<div><Link className="myLink" to={{pathname:'/LegalNotices'}} >Mentions Légales</Link></div>
				<div><Link className="myLink" to={{pathname:'/cgv'}} >CGV</Link></div>
				<div><span className="myLink" onClick={() => {dispatch(openPopup(popupNames.cookies))}}>Politique de cookies</span></div>
				<div><Link className="myLink" to={{pathname:"/confidentiality"}}>Politique de confidenlité</Link> </div>
			</div>
			<div className="col-sm-3">
				<div className="titreFooter">AIDES & INFOS</div>
				<Link className="myLink" to={{pathname: "/MyAccount"}}>
					<div>Mon compte</div>
				</Link>
				<Link className="myLink" to={{pathname: "/MyPurchases"}}>
					<div>Mes achats</div>
				</Link>
				<div><a className="myLink" rel="noreferrer" href="mailto:contact@inlivewith.com" target="_blank">Contact</a></div>
			</div>
			<div className="col-sm-3">
				<div className="sousTitreFooter">SUIVEZ-NOUS</div>
				<div className="blocSocial">
					<span className="cartesFooter Social"><a href="https://www.facebook.com/Inlivewith/" target="_blank" rel="noreferrer"><img alt="Inlive facebook" className="s24" src={FACEBOOK} /></a></span>
					<span className="cartesFooter Social"><a href="https://www.instagram.com/inlive_with" target="_blank" rel="noreferrer"> <img alt="Inlive Instagram" className="s24" src={INSTA} /></a></span>
					<span className="cartesFooter Social"><a href="https://www.youtube.com/channel/UCsrn0RaMWirm6QUMClD1GCg" target="_blank" rel="noreferrer"> <img alt="Inlive youtube" className="s24" src={YOUTUBE} /></a></span>
					<span className="cartesFooter Social"><a href="https://www.linkedin.com/company/inlive-with?originalSubdomain=fr" target="_blank" rel="noreferrer"> <img alt="Inlive linkedin" className="s24" src={LINKEDIN} /></a></span>
					<span style={{marginLeft: -5}} className="cartesFooter Social"><a href="https://www.tiktok.com/@inlive_with" target="_blank" rel="noreferrer"> <img alt="Inlive tiktok" className="" width={36} src={TIKTOK} /></a></span>
					<span style={{marginLeft: -15}} className="cartesFooter Social"><a href="https://twitter.com/inlive_with" target="_blank" rel="noreferrer"> <img alt="Inlive twitter" className="" width={36} src={TWITTERX} /></a></span>
				</div>

				<br />
				<div className="sousTitreFooter">MODES DE PAIEMENT</div>
				<span className="cartesFooter"><img alt="" src={VISA} /></span>
				<span className="cartesFooter"><img alt="" src={CB} /></span>
				<span className="cartesFooter"><img alt="" src={AE} /></span>
				
			</div>
		</div>
	)
}

export default Footer;