import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPurchaseProduct } from "../../../redux/product/actions";
import ModalFilm from "../../modalfilm";
import ModalInfo from "../../modalinfo";
import apiService from "../../../helpers/api.service";
import {
  getPurchaseProduct,
  getRentalProduct,
} from "../../../helpers/product.service";
import useMobileOrientation from "../../../hook/useMobileOrientation";
import { openPopup } from "../../../redux/popup/actions";
import constants, { popupNames, identity } from "../../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./_style.scss";
import { removeDuplicates } from "../../../helpers/removeDuplicates";

const MesAchats = () => {
  const dispatch = useDispatch();
  const { isMobile } = useMobileOrientation("(max-width: 911px)");

  const [showModal, setShowModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [rulesModal, setRulesModal] = useState(null);

  const [purchaseProd, setPurchaseProd] = useState(null);
  const [rentalProd, setRentalProd] = useState(null);
  const [isPurchaseLoaded, setIsPurchaseLoaded] = useState(false);
  const [isRentalLoaded, setIsRentalLoaded] = useState(false);
  const [dataPurch, setDataPurch] = useState();

  var [dataModal, setDataModal] = useState(undefined);

  var myDataModal = [];
  var srcBA = "";
  var displayBA = false;
  var prod = {};

  const meUser = useSelector((state) => state.userReducer.userInfo);

  useEffect(() => {
    (async () => {
      try {
        let result = [];
        let fireResult = [];
        let allResult = [];
        if (meUser.uid) {
          result = await getPurchaseProduct(meUser, meUser.uid);
        }
        if (meUser.firebaseid) {
          fireResult = await getPurchaseProduct(meUser, meUser.firebaseid);
          allResult = [...result.data.data, ...fireResult?.data?.data];
        } else {
          allResult = [...result.data.data];
        }

       
        setPurchaseProd(removeDuplicates(allResult, "id"));
        setIsPurchaseLoaded(true);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let result = [];
        let fireResult = [];
        let allResult = [];
        if (meUser.uid) {
          result = await getRentalProduct(meUser, meUser.uid);
        }
        if (meUser.firebaseid) {
          fireResult = await getRentalProduct(meUser, meUser.firebaseid);
          allResult = [...result.data.data, ...fireResult?.data?.data];
        } else {
          allResult = [...result.data.data];
        }

        // console.log("rental filter all: ", removeDuplicates(allResult, 'id'));
        setRentalProd(removeDuplicates(allResult, "id"));
        setIsRentalLoaded(true);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const handleBox = (event) => {
    const data_purch = event.target.getAttribute("data-purch");
    const data_rent = event.target.getAttribute("data-rent");
    if (data_purch.length > 0 || data_rent.length > 0) {
      setDataPurch(data_purch.length > 0 ? data_purch : data_rent);
    } else {
      setDataPurch("");
    }

    let plusX = 30,
      plusY = 80,
      plusW = 60,
      plusH = 200;

    var dimElParent = document
      .querySelector(".containerPurchase")
      .getBoundingClientRect();
    var dimElEvent = event.target.getBoundingClientRect();
    if (dimElParent.x === dimElEvent.x) {
      plusX = 0;
    }
    if (dimElEvent.right >= dimElParent.right) {
      plusX = 60;
    }
    const myRules = {
      hidden: {
        opacity: 0,
        zIndex: 10,
        x: dimElEvent.left + window.scrollX,
        y: dimElEvent.top + window.scrollY,
        position: "absolute",
        width: dimElEvent.width,
        height: dimElEvent.height,
        backgroundColor: "rgb(23,22,22)",
        transition: { delay: 0 },
      },
      visible: {
        opacity: 1,
        x: dimElEvent.left + window.scrollX - plusX,
        y: dimElEvent.top + window.scrollY - plusY,
        width: dimElEvent.width + plusW,
        height: dimElEvent.height + plusH,
        transition: { bounce: 0, delay: 0 },
      },
    };
    setRulesModal(myRules);
    setDataModal(event.target.getAttribute("data-gab"));
    setShowModal(true);
  };

  const showPopMobile = (e) => {
    const event = e.target.tagName === "IMG" ? e.target : e.target.children[0];
    const data_purch = event.getAttribute("data-purch");
    const data_rent = event.getAttribute("data-rent");
    const data_gab = event.getAttribute("data-gab");
    var data_achat = "";
    if (data_purch.length > 0 || data_rent.length > 0) {
      data_achat = data_purch.length > 0 ? data_purch : data_rent;
    }
    dispatch(openPopup(popupNames.detailSheet, null, data_gab, data_achat));
  };

  return (
    <>
      {isMobile && (
        <div className="mainView-mobile">
          <div className="containerAchat-mobile">
            <div className="TitreAchat-mobile">Mes achats Inlive Stream</div>

            <div className="containerPurchase">
              {isPurchaseLoaded &&
                isRentalLoaded &&
                Object.keys(rentalProd).length +
                  Object.keys(purchaseProd).length ==
                  0 && (
                  <div className="aucunAchat accroche-empty">
                    Vous n'avez effectué aucun achat
                  </div>
                )}
              {isPurchaseLoaded &&
                Object.keys(purchaseProd).map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="achfiche-mobile"
                      onClick={(e) => showPopMobile(e)}
                    >
                      <LazyLoadImage
                        effect="blur"
                        loading="lazy"
                        className="achImg"
                        src={`https://cimage.inlive-stream.com/img.php?src=https://vcdn.inlive-stream.com/img/${purchaseProd[item].img_thumbnail}&w=421`}
                        alt={purchaseProd[item].titre}
                        data-gab={btoa(
                          unescape(
                            encodeURIComponent(
                              JSON.stringify(purchaseProd[item])
                            )
                          )
                        )}
                        data-purch={
                          purchaseProd[item].type === "VIDEO"
                            ? btoa(purchaseProd[item].video_id)
                            : btoa("/AudioBook/" + purchaseProd[item].id)
                        } //{btoa(purchaseProd[item].video_id)}
                      />
                    </div>
                  );
                })}

              {isRentalLoaded &&
                Object.keys(rentalProd).map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="achfiche-mobile"
                      onClick={(e) => showPopMobile(e)}
                    >
                      <LazyLoadImage
                        effect="blur"
                        loading="lazy"
                        className="achImg"
                        src={`https://cimage.inlive-stream.com/img.php?src=https://vcdn.inlive-stream.com/img/${rentalProd[item].img_thumbnail}&w=421`}
                        alt={rentalProd[item].titre}
                        data-gab={btoa(
                          unescape(
                            encodeURIComponent(JSON.stringify(rentalProd[item]))
                          )
                        )}
                        data-purch={btoa(rentalProd[item].video_id)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="mainView">
          <ModalInfo
            showInfoModal={showInfoModal}
            setShowInfoModal={setShowInfoModal}
            dataModal={dataModal}
            dataPurch={dataPurch}
          />
          <ModalFilm
            showModal={showModal}
            setShowModal={setShowModal}
            myRulesModal={rulesModal}
            dataPurch={dataPurch}
            dataModal={dataModal}
            setShowInfoModal={setShowInfoModal}
            srcBA={srcBA}
            displayBA={displayBA}
          />

          <div className="containerAchat">
            <div className="TitreAchat">Mes achats Inlive Stream</div>
            <div className="containerPurchase">
              {isPurchaseLoaded &&
                isRentalLoaded &&
                rentalProd.length + purchaseProd.length === 0 && (
                  <div className="aucunAchat accroche-empty">
                    Vous n'avez effectué aucun achat
                  </div>
                )}
              {isPurchaseLoaded &&
                Object.keys(purchaseProd).map((item, i) => {
                  return (
                    <div key={i} className="achfiche">
                      <LazyLoadImage
                        effect="blur"
                        loading="lazy"
                        className="achImg"
                        src={`https://cimage.inlive-stream.com/img.php?src=https://vcdn.inlive-stream.com/img/${purchaseProd[item].img_thumbnail}&w=421`}
                        alt={purchaseProd[item].titre}
                        data-gab={btoa(
                          unescape(
                            encodeURIComponent(
                              JSON.stringify(purchaseProd[item])
                            )
                          )
                        )}
                        data-purch={
                          purchaseProd[item].type === "VIDEO"
                            ? btoa(purchaseProd[item].video_id)
                            : btoa("/AudioBook/" + purchaseProd[item].id)
                        }
                        onMouseEnter={handleBox}
                      />
                    </div>
                  );
                })}

              {isRentalLoaded &&
                Object.keys(rentalProd).map((item, i) => {
                  return (
                    <div key={i} className="achfiche">
                      <LazyLoadImage
                        effect="blur"
                        loading="lazy"
                        className="achImg"
                        src={`https://cimage.inlive-stream.com/img.php?src=https://vcdn.inlive-stream.com/img/${rentalProd[item].img_thumbnail}&w=421`}
                        alt={rentalProd[item].titre}
                        data-gab={btoa(
                          unescape(
                            encodeURIComponent(JSON.stringify(rentalProd[item]))
                          )
                        )}
                        data-purch={btoa(rentalProd[item].video_id)}
                        onMouseEnter={handleBox}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MesAchats;
