import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { TextField } from '@material-ui/core';
import { openPopup, closePopup } from '../../../redux/popup/actions';
import constants, { popupNames } from '../../../constants';

import logoImgSmall from '../../../assets/img/playstore.png';
import logoImgTall from '../../../assets/img/logo-white.png';

const Login = ({setShowLoader}) => {
	const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState('');




	return (
		<>
			<div className="popup-content-close">
				<button
					className="popup-content-close-button"
					onClick={() => dispatch(closePopup())}
				/>
			</div>
			<div className="popup-content-logo">
					<img
							className="popup-content-logo-sm"
							src={logoImgSmall}
							alt="logo Inlive Stream"
					/>
					<img className="popup-content-logo-xl" src={logoImgTall} alt="logo Inlive Stream" />
			</div>
			<div className="popup-content-title">Se connecter avec un identifiant Inlive</div>

		{
			constants.IDENTITY_ACCESS ? 
			<></> :
			<>
			<div className="popup-content-text">
				Connectez-vous avec vos identifiants Inlive Stream
			</div>
			<div className="popup-content-text">
				<span>Première visite ? </span>
				<span
					className="popup-content-link"
					onClick={() => dispatch(openPopup(popupNames.registration))}>
					Créer un compte
				</span>
			</div>
			{errorMessage && <div className="popup-content-error">{errorMessage}</div>}
			<div className="popup-content-form">
				<div className="popup-content-form-item">
					<label htmlFor="email">Email :</label>
					<TextField
						fullWidth
						id="email"
						name="email"
						//error={helpers.email ? true : false}
						//helperText={helpers.email}
						value={email}
						onChange={(e) => {
							setErrorMessage('');
							setEmail(e.target.value);
						}}
						//onBlur={onBlur}
					/>
				</div>
				<div className="popup-content-form-item">
					<label htmlFor="password">Mot de passe :</label>
					<TextField
						type="password"
						fullWidth
						id="password"
						name="password"
//						error={helpers.password ? true : false}
//						helperText={helpers.password}
						value={password}
						onChange={(e) => {
							setErrorMessage('');
							setPassword(e.target.value);
						}}
//						onBlur={onBlur}
					/>
				</div>

				<div
					className='popup-content-submit'
					// onClick={() => loginLocal(email,password)}
					>
					Se connecter
				</div>
				<div
					className="popup-content-link"
					onClick={() => dispatch(openPopup(popupNames.forgotPassword))}>
					Mot de passe oublié ?
				</div>
{/*
				<div className='break-line'>
					<div className='brTxt'>Ou</div>
					<div className="brLine" />
				</div>
				<div>Se connecter avec</div>
				<div className='barLogoConnect'>
					<div className="btnConnectSocial" onClick={(e) => loginGoogle()}>
						<img src={connectGoogle} alt="se connecter avec Google" />
					</div>
					<div className="btnConnectSocial" onClick={(e) => loginFacebook()}>
						<img src={connectFacebook} alt="se connecter avec FaceBook" />
					</div>
					<div className="btnConnectSocial">
						<img src={connectApple} alt="se connecter avec Apple" />
					</div>
				</div>
*/}
			</div> 
			</>}

		</>
	)
};

export default Login;