import constants from '../constants';

const checkEmail = email => {
    // eslint-disable-next-line
    const regExp =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email.toLowerCase());
};

const convertPriceToStg = stg => {
    return stg.toString().replace(/\./g, ',') + '€';
};

const detectBrowser = () => {
    const userAgent = navigator.userAgent;
    const match =
        userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let test;

    if (/trident/i.test(match[1])) {
        test = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
        return 'IE' + (test[1] || '');
    }

    if (match[1] === 'Chrome') {
        test = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
        if (test) return test.slice(1).join('').replace('OPR', 'Opera');
    }

    // let resultArr = match[2] ? [ match[1], match[2] ] : [ navigator.appName, navigator.appVersion, '-?' ];
    let resultArr = match[2] ? [match[1], match[2]] : ['unknown', ''];
    test = userAgent.match(/version\/(\d+)/i);
    if (test) resultArr.splice(1, 1, test[1]);

    return resultArr.join('');
};

const detectEnv = () => {
    const userAgent = navigator.userAgent;

    if (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        (userAgent.includes('Mac') && 'ontouchend' in document)
    )
        return 'iOS';

    const match = userAgent.match(/(Android|BlackBerry|Opera Mini|IEMobile|WPDesktop)/i);
    if (match) {
        if (match[0] === 'Opera Mini') return 'Opera';
        if (['IEMobile', 'WPDesktop'].includes(match[0])) return 'Windows';

        return match[0];
    }

    return 'desktop';
};

const getUrlParams = () => {
    let paramsArray = {};

    const urlArray = window.location.href.split('?');
    if (urlArray.length > 1) {
        urlArray[1].split('&').forEach(el => {
            let tmpArray = el.split('=');
            paramsArray[tmpArray[0]] = tmpArray[1];
        });
    }

    return paramsArray;
};

const paramToString = data => {
    let str = '';
    if (typeof data === 'undefined' || data === null) return str;
    Object.keys(data).forEach((item, index) => {
        if (index > 0) {
            str = `${str}&${item}=${data[item]}`;
        } else {
            str = `${str}?${item}=${data[item]}`;
        }
    });
    return str;
};

const getUserInfo = () => {
    let data = localStorage.getItem(constants.DATA_VLOG);
    let dataJSON = {};
    if (data) dataJSON = JSON.parse(data);

    return dataJSON;
};

export {
    checkEmail,
    convertPriceToStg,
    detectBrowser,
    detectEnv,
    getUrlParams,
    getUserInfo,
    paramToString
};
