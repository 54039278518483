/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

import RICIBs from "../../myInput/ReactIndividualCharacterInputBoxes";
import constants, { popupNames, identity } from "../../../constants";
import picto_tickets from "../../../assets/img/fond_texte.png";
import picto_warning from "../../../assets/img/fond_avertissement.png";
import imgClose from "../../../assets/img/imgclose.png";
import changeCodeBG from "../../../assets/img/change_code_bg.png";
import icnLive from "../../../assets/img/live1.png";
import "./_style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import ModalDiscuss from "../../modaldiscuss";
import ModalValid from "../../modalValid";
import Countdown, { zeroPad } from "react-countdown";
import useMobileOrientation from "../../../hook/useMobileOrientation";

import api from "./api";
import "moment/locale/fr";
import moment from "moment";
import Modal from "react-modal";
import VideoPlayer from "../../videoplayer/reactPlayer";
import logoEvent from "../../../assets/img/live-channel.png";
import Boutton from "../../Boutton/Boutton";
import { openPopup } from "../../../redux/popup/actions";
import { listBrowserFront } from "../../../redux/browserfront/actions";
import ModalReplay from "../../modalReplay";
import {
  getCurrentLive,
  getPurchasedStreams,
  getStreams,
} from "../../../redux/stream/actions";
import { getUserProfile } from "../../../redux/user/actions";
import parse from "html-react-parser";

const LiveEvent = ({
  Bandeau,
  streams,
  current_live,
  my_streams,
  my_streams_ids,
  userData,
}) => {
  const { isMobile } = useMobileOrientation("(max-width: 767px)");
  const dispatch = useDispatch();
  const history = useHistory();
  const [typeCode, setTypeCode] = useState("0");
  const [changeCode, setChangeCode] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [thePseudo, setThePseudo] = useState("");
  const [orientation, setOrientation] = useState("portrait");
  const [openDiscuss, setOpenDiscuss] = useState(false);
  const [openReplay, setOpenReplay] = useState(false);
  const [selectedFiche, setSelectedFiche] = useState(null);

  const meUser = useSelector((state) => state.userReducer.userInfo);


  useEffect(() => {
    setThePseudo(
      meUser.data.pseudo !== ""
        ? meUser.data.pseudo
        : "user" + (Math.floor(Math.random() * (99999999 - 0 + 1)) + 0)
    );
  }, [thePseudo]);

  useEffect(() => {
    moment.locale("fr");
    dispatch(getStreams());
  }, []);

  useEffect(() => {
    moment.locale("fr");
    dispatch(
      getPurchasedStreams({ uid: meUser.uid, email: meUser.data.email })
    );
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getUserProfile({ userId: meUser.uid }));
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const reserverBillet = (stream) => {
    meUser.uid
      ? history.push({
          pathname: "/Billetterie/",
          state: {
            image: `https://vcdn.inlive-stream.com/img/${stream?.img}`,
            data: { ...stream, desc: parse(stream.desc_short) },
          },
        })
      : dispatch(openPopup(identity.Register()));
  };

  const getLive = () => {
    dispatch(getCurrentLive(selectedFiche.video_id));
    setModalIsOpen(true);
  };

  const filterStreams = (arr = []) => {
    return arr?.filter(
      (val) =>
        moment(val.date_ticket_fin).diff(moment(new Date())) > 0 &&
        !my_streams_ids.includes(val.id)
    );
  };

  const filterStreamsReplay = (arr = []) => {
    return arr.filter(
      (val) => moment(val.date_replay_fin).diff(moment(new Date())) > 0
    );
  };

  const createNameChannel = (str) => {
    
    return str.replace(/\s+/g, "_").replace(/\//g, "");
  };

  const Renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
    live,
    reserved,
  }) => {
    if (isMobile) {
      if (completed) {
        return (
          <>
            {moment(live.date_live_fin || live.dateFin).diff(moment(new Date())) > 0 ? (
              <div
                className="imgConteneur"
                onClick={() => {
                  setModalIsOpen(true);
                }}
              >
                <div className="bkground" />
                <img
                  width={"100%"}
                  src={`https://vcdn.inlive-stream.com/img/${live.img}`}
                />
                <div className="masque-popup-mobile"></div>
                <img className="icnLive" src={icnLive} />
              </div>
            ) : (
              <div
                onClick={() => {
                  setSelectedFiche(live);
                  setOpenReplay(true);
                }}
              >
                <div className="contentImageGoParis">
                  <div className="sousContentImgGoParis">
                    <img
                      src={`https://vcdn.inlive-stream.com/img/${live.img}`}
                    />
                    <div className="obscur"></div>
                  </div>
                  <button className="btnGoLive">Replay disponible 24h</button>
                </div>
              </div>
            )}
            <div style={{ position: "relative" }}>
              <span
                className="envelopEvent"
                style={{ backgroundColor: "transparent", marginTop: 10 }}
              >
                <span>Live Event</span>
                <img className="logoEvent" src={logoEvent} />
              </span>
              <div
                style={{
                  display: "block",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 2,
                }}
              >
                <h4>{live.titre}</h4>
                <span style={{ color: "gold" }}>
                  {moment(live.date_live_debut || live.dateDebut).format("DD/MM/YYYY")}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <Boutton
                  disabled={reserved}
                  style={
                    reserved
                      ? {
                          backgroundColor: "#2F164E",
                          width: "100%",
                          borderRadius: 5,
                        }
                      : { width: "100%", borderRadius: 5 }
                  }
                  text={reserved ? "Déja réservé" : "Reserver"}
                  action={() => reserverBillet(live)}
                />
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="mobile-container">
              <div
                className="imgConteneur"
                onClick={() => {
                  setSelectedFiche(live);
                  setOpenReplay(true);
                }}
              >
                <div className="bkground" />
                <img
                  width={"100%"}
                  src={`https://vcdn.inlive-stream.com/img/${live.img}`}
                />
                <div className="masque-popup-mobile"></div>
              </div>
              <div className="countDown-mobile">
                {!completed ? (
                  <div className="compteur">
                    <div className="elementCpt">
                      <div className="numeroCpt">{zeroPad(days)}</div>
                      <div className="nomCpt">JOURS</div>
                    </div>

                    <div className="deuxpoint"> : </div>

                    <div className="elementCpt">
                      <div className="numeroCpt">{zeroPad(hours)}</div>
                      <div className="nomCpt">HEURES</div>
                    </div>

                    <div className="elementCpt">
                      <div className="deuxpoint"> : </div>
                    </div>

                    <div className="elementCpt">
                      <div className="numeroCpt">{zeroPad(minutes)}</div>
                      <div className="nomCpt">MINUTES</div>
                    </div>
                    <div className="elementCpt">
                      <div className="deuxpoint"> : </div>
                    </div>
                    <div className="elementCpt">
                      <div className="numeroCpt">{zeroPad(seconds)}</div>
                      <div className="nomCpt">SECONDES</div>
                    </div>
                  </div>
                ) : (
                  <div className="compteur">
                    <img
                      alt=""
                      className=""
                      style={{ position: "absolute", width: 60, height: 30 }}
                      src={icnLive}
                    />
                  </div>
                )}
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <span
                className="envelopEvent"
                style={{ backgroundColor: "transparent", marginTop: 10 }}
              >
                <span>Live Event</span>
                <img className="logoEvent" src={logoEvent} />
              </span>
              <div
                style={{
                  display: "block",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 2,
                }}
              >
                <h4>{live.titre}</h4>
                <span style={{ color: "gold" }}>
                  {moment(live.date_live_debut || live.dateDebut).format("DD/MM/YYYY")}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <Boutton
                  disabled={reserved}
                  style={
                    reserved
                      ? {
                          backgroundColor: "#2F164E",
                          width: "100%",
                          borderRadius: 5,
                        }
                      : { width: "100%", borderRadius: 5 }
                  }
                  text={reserved ? "Déja réservé" : "Reserver"}
                  action={() => reserverBillet(live)}
                />
              </div>
            </div>
          </>
        );
      }
    } else {
      if (reserved) {
        if (completed) {
          // if (moment(live.date_live_debut || live.dateDebut).diff(moment(new Date())) > 0) {
          if (moment(live.date_live_fin || live.dateFin).diff(moment(new Date())) < 0) {
            return (
              <div>
                <div className="contentImageGoParis">
                  <div
                    className="sousContentImgGoParis"
                    onClick={() => {
                      setSelectedFiche(live);
                      setOpenReplay(true);
                    }}
                  >
                    <img
                      src={`https://vcdn.inlive-stream.com/img/${live.img}`}
                    />
                    <div className="obscur"></div>
                  </div>
                  <button className="btnGoLive">Replay disponible 24h</button>
                </div>
              </div>
            );
          } else {
            return (
              <div>
                <div
                  className="containerLive"
                  onClick={() => {
                    setSelectedFiche(live);
                    setOpenDiscuss(true);
                  }}
                >
                  <div className="bkground" />
                  <img
                    alt=""
                    width={"100%"}
                    height={330}
                    src={`https://vcdn.inlive-stream.com/img/${live.img}`}
                  />
                  <img alt="" className="icnLive" src={icnLive} />
                </div>
                <span
                  className="envelopEvent"
                  style={{ backgroundColor: "transparent", marginTop: 10 }}
                >
                  <span>Live Event</span>
                  <img className="logoEvent" src={logoEvent} />
                </span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    maxWidth: 576,
                    marginTop: 10,
                  }}
                >
                  <h4>{live.titre}</h4>
                  <span style={{ color: "gold" }}>
                    {moment(live.date_live_debut || live.dateDebut).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div style={{ marginTop: 15 }}>
                  <Boutton
                    disabled={reserved}
                    style={reserved ? { backgroundColor: "#2F164E" } : {}}
                    text={reserved ? "Déja réservé" : "Reserver"}
                    action={() => reserverBillet(live)}
                  />
                </div>
              </div>
            );
          }
          // }
        } else {
          return (
            <div>
              <div className="countDown">
                <div className="compteur">
                  <div className="elementCpt">
                    <div className="numeroCpt">{zeroPad(days)}</div>
                    <div className="nomCpt">JOURS</div>
                  </div>

                  <div className="deuxpoint"> : </div>

                  <div className="elementCpt">
                    <div className="numeroCpt">{zeroPad(hours)}</div>
                    <div className="nomCpt">HEURES</div>
                  </div>

                  <div className="elementCpt">
                    <div className="deuxpoint"> : </div>
                  </div>

                  <div className="elementCpt">
                    <div className="numeroCpt">{zeroPad(minutes)}</div>
                    <div className="nomCpt">MINUTES</div>
                  </div>

                  <div className="elementCpt">
                    <div className="deuxpoint"> : </div>
                  </div>

                  <div className="elementCpt">
                    <div className="numeroCpt">{zeroPad(seconds)}</div>
                    <div className="nomCpt">SECONDES</div>
                  </div>
                </div>
                <div className="bkground" />
                <img src={`https://vcdn.inlive-stream.com/img/${live.img}`} />
              </div>
              <span
                className="envelopEvent"
                style={{ backgroundColor: "transparent", marginTop: 10 }}
              >
                <span>Live Event</span>
                <img className="logoEvent" src={logoEvent} />
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <h4>{live.titre}</h4>
                <span style={{ color: "gold" }}>
                  {moment(live.date_live_debut || live.dateDebut).format("DD/MM/YYYY")}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <Boutton
                  disabled={reserved}
                  style={reserved ? { backgroundColor: "#2F164E" } : {}}
                  text={reserved ? "Déja réservé" : "Reserver"}
                  action={() => reserverBillet(live)}
                />
              </div>
            </div>
          );
        }
      } else {
        return (
          <div>
            <div className="countDown">
              {!completed ? (
                <div className="compteur">
                  <div className="elementCpt">
                    <div className="numeroCpt">{zeroPad(days)}</div>
                    <div className="nomCpt">JOURS</div>
                  </div>

                  <div className="deuxpoint"> : </div>

                  <div className="elementCpt">
                    <div className="numeroCpt">{zeroPad(hours)}</div>
                    <div className="nomCpt">HEURES</div>
                  </div>

                  <div className="elementCpt">
                    <div className="deuxpoint"> : </div>
                  </div>

                  <div className="elementCpt">
                    <div className="numeroCpt">{zeroPad(minutes)}</div>
                    <div className="nomCpt">MINUTES</div>
                  </div>

                  <div className="elementCpt">
                    <div className="deuxpoint"> : </div>
                  </div>

                  <div className="elementCpt">
                    <div className="numeroCpt">{zeroPad(seconds)}</div>
                    <div className="nomCpt">SECONDES</div>
                  </div>
                </div>
              ) : (
                <div className="compteur">
                  <img
                    alt=""
                    className=""
                    style={{ position: "absolute", width: 60, height: 30 }}
                    src={icnLive}
                  />
                </div>
              )}
              <div className="bkground" />
              <img
                alt=""
                src={`https://vcdn.inlive-stream.com/img/${live.img}`}
              />
            </div>

            <span
              className="envelopEvent"
              style={{ backgroundColor: "transparent", marginTop: 10 }}
            >
              <span>Live Event</span>
              <img alt="" className="logoEvent" src={logoEvent} />
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: 10,
              }}
            >
              <h4>{live.titre}</h4>
              <span style={{ color: "gold" }}>
                {moment(live.date_live_debut || live.dateDebut).format("DD/MM/YYYY")}
              </span>
            </div>
            <div style={{ marginTop: 15 }}>
              <Boutton action={() => reserverBillet(live)} />
            </div>
          </div>
        );
      }
    }
  };
  const changeCodeAcces = async () => {
    let ret = await axios.post(
      constants.URL_API + "/users/resetCode",
      {
        uid: meUser.uid,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + constants.JWTtoken(),
        },
      }
    );
    const _ret = ret.data;
    if (_ret.ret) {
      if (isMobile) document.getElementById("root").style.overflow = "auto";
      history.push("/LiveEvent");
    }
  };

  function closeModal() {
    document.documentElement.style.overflow = "initial";
    setModalIsOpen(false);
  }
  const customStyles = {
    content: {
      position: "unset",
      top: "100%",
      left: "100%",
      transform: "translate(-100%, -100%)",
      width: "100%",
      height: "100%",
      background: "rgba(0,0,0,0.7",
      border: "none",
      padding: "none",
      display: "flex",
      justifyContent: "center",
    },
  };

  const streamEnded = () => {
    console.log("Live fini");
  };
  const getOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrientation("portrait");
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrientation("landscape");
    }
  };
  const updateOrientation = (event) => {
    getOrientation();
  };

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <Modal
            closeTimeoutMS={500}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            overlayClassName="Overlay"
          >
            <div
              style={{
                position: "absolute",
                right: 10,
                top: 5,
                zIndex: 1500,
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={solid("x")} onClick={closeModal} />
            </div>

            <div
              className={
                orientation === "portrait"
                  ? "framesMobilePortrait"
                  : "framesMobileLandscape"
              }
              style={{ display: "flex" }}
            >
              <div
                className={
                  orientation === "portrait"
                    ? "frameVideoPortrait"
                    : "frameVideoLandscape"
                }
              >
                <VideoPlayer
                  src={current_live?.viewer_endpoint}
                  id="baPlayer"
                  autoplay={true}
                  muted={false}
                  controls={true}
                  width="100%"
                  height="100%"
                  isLive={true}
                  onEnded={streamEnded}
				  onStart={()=>{}}
                />
              </div>
              {selectedFiche && (
                <div
                  className={
                    orientation === "portrait"
                      ? "containerChatPortrait"
                      : "containerChatLandscape"
                  }
                >
                  <iframe
                    className={
                      orientation === "portrait"
                        ? "frameChatPortrait"
                        : "frameChatLandscape"
                    }
                    src={encodeURI(
                      `https://chat.inlive-stream.com/?groupname=${createNameChannel(
                        selectedFiche?.titre
                      )}&username=${userData?.displayName}&callback=${
                        constants.URL_API
                      }/stream/saveMessage`
                    )}
                  ></iframe>
                </div>
              )}
            </div>
          </Modal>
          <Modal
            closeTimeoutMS={500}
            isOpen={openReplay}
            onRequestClose={() => setOpenReplay(false)}
            style={customStyles}
          >
            <ModalReplay
              replayPress={() => setModalIsOpen(true)}
              onClose={() => setOpenReplay(false)}
              stream={selectedFiche}
            />
          </Modal>
        </>
      ) : (
        <>
          <Modal
            closeTimeoutMS={500}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel=""
            overlayClassName="Overlay"
          >
            <div
              style={{
                position: "absolute",
                right: 10,
                top: 5,
                zIndex: 1500,
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={solid("x")} onClick={closeModal} />
            </div>
            <div style={{ display: "flex" }}>
              <div className="frameVideo">
                {current_live?.viewer_endpoint && (
                  <VideoPlayer
                    src={current_live?.viewer_endpoint}
                    id="baPlayer"
                    autoplay={true}
                    muted={false}
                    controls={true}
                    width="100%"
                    height="100%"
                    isLive={true}
                    onEnded={streamEnded}
                    onStart={() => {}}
                  />
                )}
              </div>
              {selectedFiche && (
                <div className="containerChat">
                  <iframe
                    className="frameChat"
                    src={encodeURI(
                      `https://chat.inlive-stream.com/?groupname=${createNameChannel(
                        selectedFiche?.titre
                      )}&username=${userData?.displayName}&callback=${
                        constants.URL_API
                      }/stream/saveMessage`
                    )}
                  ></iframe>
                </div>
              )}
            </div>
          </Modal>
          <Modal
            closeTimeoutMS={500}
            isOpen={openDiscuss}
            onRequestClose={() => setOpenDiscuss}
            style={customStyles}
          >
            <ModalDiscuss
              live={selectedFiche}
              onClose={() => setOpenDiscuss(false)}
              onJoin={() => {
                setOpenDiscuss(false);
                getLive();
              }}
            />
          </Modal>
          <Modal
            closeTimeoutMS={500}
            isOpen={openReplay}
            onRequestClose={() => setOpenReplay(false)}
            style={customStyles}
          >
            <ModalReplay
              replayPress={() => setModalIsOpen(true)}
              onClose={() => setOpenReplay(false)}
              stream={selectedFiche}
            />
          </Modal>
        </>
      )}
      {
        <>
          <>
            {isMobile ? (
              <div className="mainView">
                {changeCode && (
                  <div className="ctnCongratMobile">
                    <div className="ctnPopupCongrat vmobile">
                      <div className="titreCongratGoParis">
                        Changer mon code d’accès
                      </div>
                      <div className="txt1Congrat">
                        Es-tu sûr(e) de vouloir changer ton code d’accès ? Si tu
                        as obtenu plusieurs code d’accès, un seul pourra être
                        accepté sur ton compte. Cette action est irréversible.
                      </div>
                      <div className="zoneBtnWarn">
                        <div style={{ padding: "10px" }}></div>
                        <button
                          className="btnResendMail"
                          onClick={() => {
                            setChangeCode(false);
                            document.getElementById("root").style.overflow =
                              "auto";
                          }}
                        >
                          Annuler
                        </button>
                        <div style={{ padding: "10px" }}></div>
                        <button
                          className="btnLouer"
                          onClick={() => changeCodeAcces()}
                        >
                          Valider
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="containerEvent-mobile">
                  <div className="titre">Mes réservations</div>
                  {/* <div className="containerFiche d-flex row"> */}
                  {my_streams?.length === 0 && (
                      <div style={{}} className="accroche-empty">
                        Aucune réservation de Live Event dans votre liste
                      </div>
                    )}
                  {my_streams?.length > 0 &&
                    my_streams?.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          className="w-100"
                          style={{ marginTop: 40 }}
                        >
                          <div style={{ width: "100%" }}>
                            <Countdown
                              date={item.date_live_debut || item.dateDebut}
                              renderer={(props) => (
                                <Renderer
                                  {...props}
                                  live={item}
                                  reserved={true}
                                />
                              )}
                              zeroPadTime={2}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div
                  className="containerEvent-mobile"
                  style={{ marginTop: 0, paddingTop: 0 }}
                >
                  <div style={{ fontSize: 28 }} className="accroche">
                    À ne pas manquer ...
                  </div>
                  {(my_streams?.length === 0 ||
                    (my_streams && filterStreams(streams)?.length === 0)) && (
                    <div style={{}} className="accroche-empty">
                      Aucun Live Event
                    </div>
                  )}
                  {streams &&
                    my_streams_ids &&
                    filterStreams(streams)?.map((item, index) => {
                      return (
                        <div
                          key={item?.id}
                          className="w-100"
                          style={{ marginTop: 40 }}
                        >
                          <div style={{ width: "100%" }}>
                            <Countdown
                              date={item.date_live_debut}
                              renderer={(props) => (
                                <Renderer
                                  {...props}
                                  live={item}
                                  reserved={false}
                                />
                              )}
                              zeroPadTime={2}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {/* </div> */}
                </div>
              </div>
            ) : (
              <div className="mainView">
                {changeCode && (
                  <div className="popupContainer">
                    <div className="popupChangeCode">
                      <img src={changeCodeBG} className="imgbg" />
                      <div className="ctnButton">
                        <button
                          className="btnAnnuler"
                          onClick={() => setChangeCode(false)}
                        >
                          Annuler
                        </button>
                        <button
                          className="btnChanger"
                          onClick={() => changeCodeAcces()}
                        >
                          Changer de code
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="containerEvent">
                  <div style={{ fontSize: 28 }} className="accroche">
                    Mes réservations
                  </div>
                  {my_streams?.length === 0 && (
                      <div style={{}} className="accroche-empty">
                        Aucune réservation de Live Event dans votre liste
                      </div>
                    )}
                  <div className="containerFiche d-flex row">
                    {my_streams?.length > 0 &&
                      my_streams?.map((item, index) => {
                        return (
                          <div
                            key={item.id}
                            className="col-12 col-md-6"
                            style={{ marginTop: 40 }}
                          >
                            <div style={{ width: "90%" }}>
                              <Countdown
                                date={item.date_live_debut || item.dateDebut}
                                renderer={(props) => (
                                  <Renderer
                                    {...props}
                                    live={item}
                                    reserved={true}
                                  />
                                )}
                                zeroPadTime={2}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div
                  className="containerEvent"
                  style={{ marginTop: 0, paddingTop: 0 }}
                >
                  <div style={{ fontSize: 28 }} className="accroche">
                    À ne pas manquer ...
                  </div>
                  {streams &&
                    my_streams_ids &&
                    filterStreams(streams)?.length === 0 && (
                      <div style={{}} className="accroche-empty">
                        Aucun Live Event
                      </div>
                    )}
                  <div
                    style={{ justifyContent: "space-between" }}
                    className="containerFiche d-flex row"
                  >
                    {streams &&
                      my_streams_ids &&
                      filterStreams(streams)?.map((item, index) => {
                        return (
                          <div
                            key={item?.id}
                            className="col-12 col-md-6"
                            style={{ marginTop: 40 }}
                          >
                            <div style={{ width: "90%" }}>
                              <Countdown
                                date={item.date_live_debut}
                                renderer={(props) => (
                                  <Renderer
                                    {...props}
                                    live={item}
                                    reserved={false}
                                  />
                                )}
                                zeroPadTime={2}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </>
        </>
      }
    </>
  );
};

const mapStateToProps = ({
  BrowserFrontReducer,
  StreamReducer,
  userReducer,
}) => ({
  Bandeau: BrowserFrontReducer.Bandeau,
  streams: StreamReducer.streams,
  current_live: StreamReducer.current_live,
  my_streams: StreamReducer.my_streams,
  my_streams_ids: StreamReducer.my_streams_ids,
  userData: userReducer.userData,
});

export default connect(mapStateToProps)(LiveEvent);
