import React from "react";
import { createTheme } from "@mui/material";
import {ThemeProvider} from "@mui/material";
import { Link } from 'react-router-dom';

import "./_styles.scss";

const materialTheme = createTheme({
	palette: {
			primary: {
					light: '#f133ff',
					main: '#ec00ff',
					dark: '#b500cb'
			}
	}
});


const styles = {
  table: {
    border: '1px',
    borderColor: 'white',
    borderStyle: 'solid',
    padding: '0.6rem'
  },
  li: {
    lineHeight: 1.2,
    marginLeft: '1rem'
  },
  h2: {
    fontSize: '1.4rem',
    textAlign: 'center'
  },
  h4: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  h5: {
    fontWeight: 'bold',
    marginLeft: '3rem'
  },
  h6: {
    fontStyle: 'italic',
    textDecoration: 'underline'
  },
  bold: {
    fontWeight: 'bold'
  },
  em: {
    display: 'block',
    fontStyle: 'italic'
  },
  strong: {
    display: 'block',
    fontWeight: 'bold'
  },
  indented: {
    marginLeft: '3rem'
  },
  flex: {
    display: 'flex',
    gap: '1rem'
  },
  a: {
    color: '#ec00ff',
  }
};


const PolitiqueConf = () => {
	return (
		<div className="margeHaute">
			<div className="profile">
				<ThemeProvider theme={materialTheme}>
        <>
            <h2>Charte de confidentialité Inlive Stream</h2>
            <br />
            <p>
                Bonjour, saviez-vous qu’en allant sur notre site internet https://inlive-stream.com,
                comme sur d’autres sites Internet, vous donnez des informations sur vous dont
                certaines sont des données personnelles ?
            </p>
            <br />
            <h3> Qui sommes-nous ? </h3>
            <br />
            <p>
                Inlive Stream est une plateforme gérée par la société Inlive With.
                <br />
                Ce site internet vous permet d’acheter des produits digitaux pour le compte des
                célébrités/marques. Nous avons aussi toute une équipe motivée qui peut répondre à
                vos questions par mail à contact@inlivewith.com.
            </p>
            <h5> Une donnée personnelle, c’est quoi ?</h5>
            <p>
                A partir du moment où vous vous inscrivez sur notre site internet ou que vous nous
                envoyez un mail, vous nous communiquez de manière volontaire des informations qui
                vous concernent et que l’on appelle « données personnelles ».
            </p>
            <h5>Les données personnelles que nous avons sur vous sont :</h5>
            <p>
                -Des données relatives à l’identité : nom, prénom
                <br /> -Des données pour nous permettre de vous contacter : adresse mail (ou
                l’adresse mail d’un de vos parents si vous êtes âgé de moins de 15 ans)
                <br /> -Des données relatives aux achats effectués sur notre site : coordonnées
                bancaires ou historique des commandes,
                <br />
                -Des données relatives aux mails que vous nous envoyez : le contenu du mail, la date
                et l’objet du mail, notre réponse à votre mail,
                <br /> -Des données relatives à votre connexion comme le type de connexion utilisée,
                adresse IP, opérateur de téléphonie mobile,
                <br /> -Des données relatives à votre navigation comme les statistiques des pages
                que vous avez consultées,
                <br />- Les publicités sur lesquelles vous avez cliqué.
            </p>
            <br />
            <h3>Que faisons-nous des données ?</h3>
            <br />
            <h5>-Pourquoi avons-nous besoin de ces données ?</h5>
            <p>
                {' '}
                1) Pour vous permettre d’utiliser notre site internet, en vous créant un compte
                dessus,
                <br /> 2) Pour vous répondre si vous nous contactez
                <br /> 3) Pour suivre vos commandes
                <br /> 4) Pour vous demander si vous êtes satisfait(e) de notre site internet et
                savoir comment nous pourrions l’améliorer,
                <br /> 5) Pour vous montrer des publicités adaptées à vos goûts,
                <br /> 6) Pour vous envoyer des e-mails, notamment pour bénéficier de promotions ou
                vous avertir si de nouvelles célébrités sont présentes sur le site internet,
                <br /> 7) Pour lutter contre la fraude et assurer la sécurité de notre site
                internet.
            </p>
            <h5>-Vos données sont gardées chez nous pendant combien de temps ?</h5>
            <p>
                Nous gardons vos données tant que vous utilisez notre plateforme. Si vous arrêtez de
                l'utiliser, nous pouvons encore garder vos données pendant 1 an au maximum pour vous
                proposer de revenir sur notre application web/mobile. Nous pouvons également garder
                vos données pendant 5 ans après votre départ de notre site internet mais seulement
                pour montrer en cas de difficulté que nous vous avions bien donné accès à ce que
                nous vous avions promis. Nous gardons vos données relatives à votre carte bancaire
                pendant toute la durée de la commande de votre produit et plus si vous la
                sauvegardez sur votre compte Inlive Stream mais jamais plus longtemps que la durée
                de votre compte.Après ces délais, vos données seront définitivement effacées.
            </p>
            <h5>-A qui sont transmises vos données ?</h5>
            <p>
                Nous pouvons transmettre vos données à des sociétés qui travaillent pour nous comme
                notre prestataire de paiement, notre prestataire d’envoi de newsletters et notre
                prestataire d’hébergement. Nous ferons évidemment attention à ce qu’elles protègent
                vos données et ne les utilisent pas pour autre chose.
            </p>
            <h5>-Comment vos données sont-elles sécurisées ?</h5>
            <p>
                Nous faisons très attention à ce que vos données ne soient pas perdues, volées ou
                utilisées par des personnes qui n’ont pas le droit de les connaître.Voici quelques
                exemples de mesures de sécurité que nous utilisons :<br /> -Nous ne pouvons regarder
                vos données qu’après avoir saisi notre identifiant personnel et notre mot de passe,
                <br /> -Nous avons sécurisé l’accès à nos ordinateurs et à nos locaux pour les
                protéger.
            </p>
            <br />
            <h3>Vos droits</h3>
            <br />
            <p>
                Vous avez des droits pour protéger votre vie privée. Si vous souhaitez exercer un ou
                plusieurs de ces droits ou avoir plus de précisions dessus, vous pouvez nous
                contacter à l’adresse indiquée au début de cette charte.
            </p>
            <h5>Il y a une faute dans mes informations</h5>
            <p>
                Si vous remarquez une erreur dans vos informations, vous pouvez demander à tout
                moment qu’on la corrige.
            </p>
            <h5>Que savez-vous sur moi ?</h5>
            <p>Vous avez le droit de savoir ce que nous savons sur vous.</p>
            <h5>Arrêtez d’utiliser mes données !</h5>
            <p>Il arrive parfois que vous souhaitiez que nous arrêtions d’utiliser vos données. </p>
            <h5>Pourriez-vous effacer mes données ?</h5>
            <p>Il arrive parfois que vous souhaitiez que nous effacions vos données. </p>
            <h5>Je veux transférer mes données personnelles à une autre société</h5>
            <p>
                Vous pouvez nous demander de récupérer les données personnelles que nous avons sur
                vous et de les transférer à une société.
            </p>
            <h5>
                Je ne souhaite plus recevoir de mails pour bénéficier de promotions ou m’informer si
                de nouvelles stars sont présentes sur le site
            </h5>
            <p>
                Vous pouvez vous opposer à tout moment à recevoir des notifications pour acheter des
                places de concert ou pour recevoir des offres de vos partenaires en cochant la case
                prévue dans votre compte utilisateur.
            </p>
            <p>
                Si vous le souhaitez, vous pouvez à tout moment envoyer une réclamation à l’autorité
                de protection des données (la CNIL).
            </p>
            <p>Date d’entrée en vigueur de cette charte : 14/10/2021</p>
        </>
				</ThemeProvider>
			</div>
		</div>
	)
}
export default PolitiqueConf;