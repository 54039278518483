import { types } from "./types";

/**
 * @description user infos.
 */

export const fetch_user_info = () => ({
	type: types.FETCH_USER_INFO,
	payload: null
})

export const getUserProfile = (payload) => ({
	type: types.GET_PROFILE_REQUEST,
	payload
})

export const updateUserProfile = (payload) => ({
	type: types.UPDATE_PROFILE_REQUEST,
	payload
})

export const fetch_user_country = (payload) => ({
	type: types.GET_USER_COUNTRY,
	payload
})

export const add_meta = (payload) => ({
	type: types.ADD_META_COUNTRY,
	payload
})

export const fetch_public_country = () => ({
	type: types.GET_ALL_COUNTRY
})