export const removeTags = (text) => {
    // First matcher
    const regex = /(<([^>]+)>)/gi;
    // Second matcher
    const regex2 = /(&nbsp;|<([^>]+)>)/gi;
    // Apply first regex
    if (typeof text === "string") {
      const filtered = text?.replace(regex, "");
      // Apply last regex
      return filtered?.replace(regex2, "");
    } else {
      return text;
    }
  };