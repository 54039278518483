import React, {useState} from "react";

import { TextField, Radio, FormControlLabel, RadioGroup, Avatar } from '@material-ui/core';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import { isEmpty } from 'lodash';
import { isBefore, addYears, isDate, format } from 'date-fns';

import toast, { Toaster } from 'react-hot-toast';

import useMobileOrientation from '../../../../hook/useMobileOrientation';
import constants from "../../../../constants";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../../../../redux/user/actions";
import moment from "moment";


const materialTheme = createTheme({
	palette: {
		primary: {
				light: '#f133ff',
				main: '#ec00ff',
				dark: '#b500cb'
		},
		grey: {
				light: '#aeaeae',
				main: '#2b2b2b',
				dark: '#121212'
		}
	}
});


const InfoPerso = ({Datas}) => {
	const  {isMobile}  = useMobileOrientation('(max-width: 911px)');

	const updateAccount = () => {
		window.open(constants.BASE_URL_LOGIN + "/profile/"+ Datas?.email + "?callback=" + constants.URL_CALLBACK , 
		'targetWindow', `toolbar=no, location=no, status=no,
			menubar=no, scrollbars=yes, resizable=yes, width=1050, height=640`);
	}

	return(
		<div className={`profile-infos${isMobile ? '-mobile' : ''}`}>
			<div className="profile-infos-title">
				<h4><strong>Mes informations</strong></h4>

				<div className="line">
					<span className={`mnuMonCompte`}
						onClick={(e) => {updateAccount()}}
					>
						Modifier
					</span>
				</div>
			</div>
			<ThemeProvider theme={materialTheme}>
				<div className={`frmGlobal${isMobile ? '-mobile' : ''}`}>
					<div className="profile-infos-form">
						<div className="line" style={{marginBottom: 20}}>
							<Avatar src={Datas?.avatarUrl} style={{height: 80, width: 80}}>{Datas?.firstName ? Array.from(Datas?.firstName)[0] : ''}{Datas?.lastName ? Array.from(Datas?.lastName)[0] : ''}</Avatar> 
							<span className="pseudo" style={{fontSize: 18}}>{Datas?.displayName}</span>
						</div>
						<div className="profile-infos-form-item">
							<label htmlFor="account-displayName">Pseudo</label>
							<div className="encadre">
								<TextField
									fullWidth
									disabled={true}
									id="account-userName"
									name="userName"
									color="primary"
									value={Datas?.displayName}
								/>
							</div>
						</div>
						<div className="profile-infos-form-item">
							<label htmlFor="account-email">Email</label>
							<div className="encadre">
								<TextField
									fullWidth
									disabled={true}
									id="account-email"
									name="email"
									value={Datas?.email}
								/>
							</div>
						</div>

						<div className="profile-infos-form-item">
							<label htmlFor="account-firstname">Prénom</label>
							<div className="encadre">
								<TextField
									fullWidth
									disabled={true}
									id="account-firstName"
									name="firstName"
									defaultValue={Datas?.firstName}
									value={Datas?.firstName}
								/>
							</div>
						</div>
						<div className="profile-infos-form-item">
							<label htmlFor="account-lastname">Nom</label>
							<div className="encadre">
								<TextField
									fullWidth
									disabled={true}
									id="account-name"
									name="lastName"
									value={Datas?.lastName}
								/>
							</div>
						</div>
						<div className="profile-infos-form-item">
							<label htmlFor="account-birthday">Date de naissance</label>
							<div className="encadre">
								<TextField
									fullWidth
									disabled={true}
									id="account-name"
									name="date"
									value={moment(Datas?.birthDay).add(1, 'days').format('DD/MM/YYYY')}
									placeholder= "dd/mm/yyyy"
								/>
							</div>
							
						</div>

						<div className="profile-infos-form-item">
							<label htmlFor="account-lastName">Genre (facultatif)</label>
							<div className="encadre">
								<TextField
									fullWidth
									disabled={true}
									id="account-genre"
									name="lastName"
									value={Datas?.gender === 'GENDER_MALE' ? 'Homme' : Datas?.gender === 'GENDER_FEMALE' ? 'Femme' : 'Non precisé'}
								/>
							</div>
						</div>
						<div className="profile-infos-form-item">
							<label htmlFor="account-lastName">Mot de passe</label>
							<div className="encadre">
								<TextField
									fullWidth
									disabled={true}
									id="account-mp"
									name="lastName"
									type={'password'}
									value={'djf8834o34u83483h'}
								/>
							</div>
						</div>
						<div className="profile-infos-form-item">
							<label htmlFor="account-lastName">Numéro de téléphone</label>
							<div className="encadre">
								<TextField
									fullWidth
									disabled={true}
									id="account-phone"
									name="lastName"
									value={Datas?.phoneNumber}
								/>
							</div>
						</div>

					</div>
				</div>
			</ThemeProvider>
			<Toaster
				position="top-right"
				reverseOrder={false}
			 />
		</div>
	)
}

export default InfoPerso;