import {types} from "./types";

const initialState = {
	open: false,
	name: null,
	type: null,
	data_gab: null,
	data_purch: null
}

const popupReducer = (state = initialState, action) => {
	const newState = Object.assign({}, state);

	switch(action.type){
		case types.CLOSE_POPUP:
				newState.open = false;
				newState.name = null;
				newState.type = null;
				newState.data_gab=null;
				newState.data_purch=null;
				return newState;
		
		case types.OPEN_POPUP:
			const { name, type, data_gab, data_purch } = action.payload;
			newState.open = true;
			newState.name = name;
			newState.type = type;
			newState.data_gab = data_gab;
			newState.data_purch = data_purch;
			return newState;

		default:
			return newState;
	}
};
export default popupReducer;