import { Box, Container, FormControl, FormControlLabel, Grid } from '@material-ui/core'
import { Divider, Stack, Radio } from '@mui/material'
import React, { useState } from 'react'
import Boutton from '../../Boutton/Boutton'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router-dom';
import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import constants from '../../../constants';
import moment from 'moment';

const style = {
    base: { color: '#FFF' },
    invalid: { color: ' #E33939' }
};



export default function Payment() {
    const history = useHistory();
   
    const data = history.location.state.data;
    const pack = history.location.state.pack;

    const [add, setAdd] = useState(false);

    const stripePromise = loadStripe((process.env.REACT_APP_STAGE === "production") ? constants.STRIPE_PROD : constants.STRIPE_TEST);

    return (
        <Box style={{ backgroundColor: 'rgb(26,26,26)' }}>
            <Container style={{ paddingTop: 100, }} maxWidth="md">
                <Box sx={{ height: '100vh' }}>
                    <Box sx={{ marginBottom: 30 }} display="flex" flexDirection="row">
                        <FontAwesomeIcon icon={solid('angle-left')} onClick={() => { history.goBack() }} />
                        <h5 style={{ marginLeft: 20 }}>Passer la commande</h5>
                    </Box>
                    <Box>
                        <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                            justifyContent="space-between">
                            <Grid xs={12} sm={6} style={{ marginBottom: 20 }}>
                                <Stack spacing={2}>
                                    <div>
                                        Séléctionner un moyen de paiement
                                    </div>
                                    <Stack justifyContent={'center'} sx={{ height: 70, backgroundColor: !add ? 'grey' : 'transparent', borderRadius: 2, paddingLeft: 2, cursor: 'pointer' }}>
                                        <Stack direction={'row'} alignItems="center" onClick={()=> setAdd(false)}>
                                            <Grid>
                                                <Radio
                                                    defaultChecked
                                                    sx={{
                                                        color: 'gray',
                                                        '&.Mui-checked': {
                                                            color: 'white',
                                                        },
                                                    }}
                                                    checked={!add}
                                                    // value={add}
                                                    onChange={(e) => setAdd(!e.target.checked)}
                                                />
                                            </Grid>
                                            <Grid>
                                                <div style={{ marginBottom: 2, fontSize: 14 }}>NAME FIRSTNAME</div>
                                                <div style={{ fontSize: 11 }}><span style={{ marginRight: 20 }}>Visa</span><span>****4252</span></div>
                                            </Grid>
                                        </Stack>
                                    </Stack>
                                    {/* <Stack justifyContent={'center'} sx={{ height: 70, border: '1px gray solid', borderRadius: 2, paddingLeft: 2, cursor: 'pointer' }}>
                                        <Stack direction={'row'} alignItems="center">
                                            <Grid>
                                                <Radio
                                                    sx={{
                                                        color: 'gray',
                                                        '&.Mui-checked': {
                                                            color: 'white',
                                                        },
                                                    }}
                                                    value="" />
                                            </Grid>
                                            <Grid>
                                                <div style={{ marginBottom: 2, fontSize: 14 }}>NAME FIRSTNAME</div>
                                                <div style={{ fontSize: 11 }}><span style={{ marginRight: 20 }}>Visa</span><span>****4252</span></div>
                                            </Grid>
                                        </Stack>
                                    </Stack> */}
                                    <Divider />
                                </Stack>
                                <Stack justifyContent={'center'} sx={{ backgroundColor: add ? 'grey' : 'transparent', height: 50, border: '1px gray solid', borderRadius: 2, marginTop: 2, paddingLeft: 2, cursor: 'pointer' }}>
                                    <Stack direction={'row'} alignItems="center" onClick={()=> setAdd(true)}>
                                        <Grid>
                                            <Radio
                                                sx={{
                                                    color: 'gray',
                                                    '&.Mui-checked': {
                                                        color: 'white',
                                                    },
                                                }}
                                                checked={add}
                                                // value={add}
                                                onChange={(e) => setAdd(e.target.checked)}
                                            />
                                        </Grid>
                                        <Grid>
                                            <div style={{ fontSize: 11 }}>Ajouter une carte de credit ou debit</div>
                                        </Grid>
                                    </Stack>
                                </Stack>
                                {add && <Stack width={'100%'}>
                                    <Elements stripe={stripePromise}>
                                        <form
                                         style={{width: '100%', maxWidth: '100%'}}
                                            // onSubmit={handleSubmit} 
                                            className="popup-content-payment-form w-100">
                                            <div  style={{width: '100%'}} className="popup-content-payment-form-input w-100">
                                                <label htmlFor="name">Nom du titulaire de la carte</label>
                                                <input
                                                style={{width: '100%'}}
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="--no-active w-100"
                                                    placeholder='Nom sur la carte'
                                                // onChange={e => setCardName(e.target.value.trim())}
                                                />
                                            </div>
                                            <div className="popup-content-payment-form-input mt-3">
                                                <label>Informations de la carte</label>
                                                <CardNumberElement
                                                    className="input --first --no-active mt-1"
                                                    // onChange={handleChange}
                                                    options={{ style }}
                                                />
                                                <div className="popup-content-payment-form-input --splitted mt-3">
                                                    <CardExpiryElement
                                                        className="input splitted-half --no-active"
                                                        options={{ style }}
                                                    // onChange={handleChange}
                                                    />
                                                    <CardCvcElement
                                                        className="input splitted-half --no-active"
                                                        options={{ style }}
                                                    // onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            {/* {error && <div className="popup-content-error">{error}</div>}
                                        {successMessage && <div className="popup-content-success">{successMessage}</div>}
                                        <button
                                            className={
                                                'popup-content-submit' +
                                                (!stripe || processing || disabled || successMessage ? ' --disabled' : '')
                                            }
                                            type="submit">
                                            Payer{' '}
                                           
                                            {product?.price || product?.price_rental} &euro;
                                        </button> */}
                                        </form>
                                    </Elements>
                                </Stack>}
                            </Grid>
                            <Grid xs={12} sm={5}>
                                <Stack spacing={2}>
                                    <div>
                                        Votre commande
                                    </div>
                                    <Stack sx={{ height: 150, border: '1px gray solid', borderRadius: 2, padding: 2 }}>
                                        <Stack direction={'row'} height="70%" >
                                            <Grid xs={3} style={{ marginRight: 10 }}>
                                                <img height={'80%'} width={"100%"} src={`https://vcdn.inlive-stream.com/img/${data.img}`} />
                                            </Grid>
                                            <Grid xs={9} direction="column">
                                                <div style={{ fontSize: 14 }}>{data.titre}</div>
                                                <div style={{ fontSize: 11 }}>En direct a paris le</div>
                                                <div style={{ fontSize: 11 }}>{moment(data.date_online_debut).format('DD MMM YYYY hh:mm')}</div>
                                            </Grid>
                                        </Stack>
                                        <Divider />
                                        <Stack direction={'row'} mt={2} justifyContent="space-between">
                                            <span>{pack.categorie}</span>
                                            <span>{pack.prix}€</span>
                                        </Stack>
                                    </Stack>

                                </Stack>
                                <Boutton action={() => history.push('/LiveEvent')} text={'Confirmer et payer'} style={{ textTransform: 'initial', borderRadius: 5, marginTop: 20, backgroundColor: '#522787', fontSize: 16 }} />
                            </Grid>
                        </Stack>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
