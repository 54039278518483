import React from 'react'
import ModalInfo from '../modalinfo';
import Modeless from '../modeless'
import { useState } from 'react';
import { openPopup } from "../../redux/popup/actions";
import { useSelector, useDispatch, connect } from "react-redux";
import { popupNames } from "../../constants";
import { fetchEncodedProduct } from "../../redux/product/actions";



export default function Boutton({text, style, action, disabled=false}) {

  return (
    <button onClick={action} style={{ textTransform : "initial", width: "auto", height: "45px", paddingLeft:'20px', paddingRight:'20px',
    backgroundColor:"#EAB2FF", borderRadius:"16px", marginBottom:'20px',
    fontSize: "20px", ...style}} disabled={disabled}
    >{text?text:"Reserver"}</button>
  )
}
