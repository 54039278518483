const defaultCookiesText = `Inlive Stream et des tiers utilisent des cookies et des technologies similaires sur ce site web afin de collecter certaines données sur vos
activités en ligne que nous utilisons pour analyser votre utilisation du site web dans le but de personnaliser nos services et nos publicités en ligne.
Lorsque votre consentement est sollicité, vous avez le choix d’accepter, de refuser ou de personnaliser vos options.
Vous pouvez également modifier vos préférences à tout moment en cliquant sur « Préférence de cookies » en bas de chaque page.
Inlive Stream respecte les principes de l’Alliance de la publicité numérique.`;

const cookiesSettings = {
    title: `Personnaliser mes choix`,
    content: `Lors de l’utilisation de notre Site Web des traceurs (ci-après désignés ensemble les « Cookies ») sont déposés sur votre terminal.
    Un Cookie est un petit fichier, souvent crypté, stocké dans votre navigateur ou votre terminal et identifié par un nom. 
    Il est déposé lors de la consultation de notre Site Web. 
    Chaque fois que vous revenez sur le site en question, le Cookie est récupéré sur votre navigateur ou sur votre terminal. 
    Ainsi, chaque fois que vous consultez le site ou l’application, le navigateur est reconnu.
    
    Le dépôt de ces Cookies est susceptible de nous permettre d’accéder à vos données de navigation 
    et/ou à des données à caractère personnel vous concernant.`
};

const requiredCookies = {
    title: `Cookies nécessaires :`,
    content: `Ces cookies sont strictement nécessaires pour fournir notre site Web ou notre service en ligne. 
    Par exemple, nous et nos fournisseurs de services pouvons utiliser ces cookies pour authentifier et 
    identifier les visiteurs lorsqu'ils utilisent nos sites Web et applications afin que nous puissions leur fournir notre service. 
    Ils nous aident également à faire respecter nos conditions d'utilisation, à prévenir la fraude et à maintenir la sécurité de nos services.`
};

const cookiesLifeTime = `Durée de vie : la plupart des cookies sont des cookies de session (qui ne sont actifs que jusqu'à ce que vous fermiez votre navigateur) 
    ou des cookies qui ne sont actifs que pendant une journée. Certains cookies sont actifs plus longtemps, 
    allant de 3 à 12 mois. Les cookies utilisés pour prévenir les fraudes et maintenir la sécurité de nos services sont actifs pour une durée maximale de 24 mois`;

const performanceCookies = {
    title: `Cookies de performance :`,
    content: `Les Cookies de performance et de fonctionnalité sont nécessaires au bon fonctionnement du Site Web et pour vous fournir nos services. 
    Ils sont utilisés tout au long de votre navigation, afin de la faciliter et d’exécuter certaines fonctions.
    
    Un Cookie technique peut par exemple être utilisé pour mémoriser vos réponses renseignées dans un formulaire 
    ou encore vos préférences s’agissant de la langue ou de la présentation de l’Application, lorsque de telles options sont disponibles. `
};

export {
    defaultCookiesText,
    cookiesSettings,
    requiredCookies,
    cookiesLifeTime,
    performanceCookies
};
