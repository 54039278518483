import apiService from '../../helpers/api.service';
import constants from '../../constants';

export const createStripeIntent = queryVariable => {
    if (queryVariable !== undefined) {
        return apiService.post(constants.URL_API + '/purchases/intent', queryVariable);
    }
};

export const treatStripeSuccess = queryVariable => {
    if (queryVariable !== undefined) {
        return apiService.post(constants.URL_API + '/purchases/success', queryVariable);
    }
};  
  



export const createStripeIntentForLive = queryVariable => {
    if (queryVariable !== undefined) {
        return apiService.post(constants.URL_API + '/stream/stripeIntent', queryVariable);
    }
};

export const treatStripeSuccessLive = queryVariable => {
    if (queryVariable !== undefined) {
        return apiService.post(constants.URL_API + '/stream/stripePurchaseSuccess', queryVariable);
    }
};