import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../constants";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { closePopup } from "../../../redux/popup/actions";
import logoImgSmall from "../../../assets/img/playstore.png";
import logoImgTall from "../../../assets/img/logo-white.png";
import LockIcon from "@material-ui/icons/Lock";
import StripeCheckoutForm from "./stripCheckOutForm";

import parse from "html-react-parser";
import decodeURIComponentSafely from "../../../helpers/decodeUri";

const stripePromise = loadStripe(
  process.env.REACT_APP_STAGE === "production"
    ? constants.STRIPE_PROD
    : constants.STRIPE_TEST
);

const Payment = ({ setShowLoader }) => {
  const dispatch = useDispatch();

  const { type: typePayment } = useSelector((state) => state.popupReducer);
  const { encodedProduct: eProduct } = useSelector(
    (state) => state.productReducer
  );
  const [isSuccess, setIsSuccess] = useState(false);

  const product = JSON.parse(decodeURIComponentSafely(escape(atob(eProduct))));

  return (
    <>
      <div className="popup-content-close">
        <button
          className="popup-content-close-button"
          onClick={() => dispatch(closePopup())}
        />
      </div>
      <div className="popup-content-logo">
        <img
          className="popup-content-logo-sm"
          src={logoImgSmall}
          alt="logo Inlive Stream"
        />
        <img
          className="popup-content-logo-xl"
          src={logoImgTall}
          alt="logo Inlive Stream"
        />
      </div>

      {isSuccess && (
        <>
          <div className="popup-content-title">Merci pour votre commande !</div>
          <div className="popup-content-text">
            Une confirmation par e-mail vous a été envoyée.
          </div>
          <div className="popup-content-thumbnail">
            <img src={product.img} alt={product.titre} />
          </div>
        </>
      )}

      <div className="popup-content-product">
        <div className="popup-content-product-line">
          <div className="popup-content-product-left --title">
            {parse(product.titre)}
          </div>
          <div className="popup-content-product-right --price">
           
            {typePayment === "loc"
              ? product?.price_rental
              : product?.price}{" "}
            &euro;
          </div>
        </div>
        <div className="popup-content-product-line">
          <div className="popup-content-product-left --quantity">Qté : 1</div>
        </div>
      </div>
      <div className="popup-content-separator"></div>
      {isSuccess ? (
        <></>
      ) : (
        <>
          <div className="popup-content-title">Payer par carte</div>
          <Elements stripe={stripePromise}>
            <StripeCheckoutForm
              product={product}
              paymentType={typePayment}
              setIsSuccess={setIsSuccess}
              setShowLoader={setShowLoader}
            />
          </Elements>
          <div className="popup-content-payment-secured">
            <LockIcon /> Paiement sécurisé
          </div>
        </>
      )}
    </>
  );
};

export default Payment;
