import React from "react";
import { useHistory } from "react-router-dom";
import imgMedia from "../../assets/img/check.png";
import Boutton from "../Boutton/Boutton";

export default function Index({
  onClose,
  title,
  text,
  colorText,
  mobile,
  txtbtn1,
  txtbtn2,
  live,
}) {
  const history = useHistory();

  if (!mobile) {
    return (
      <div
        style={{
          minHeight: "300px",
          height: "auto",
          minWidth: "250px",
          width: "50vw",
          borderRadius: "15px",
          backgroundColor: "#1D1D1D",
          position: "absolute",
          padding: "45px 35px",
          top: "20%",
          left: "25%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            height: "10px",
            width: "50vw",
            backgroundColor: "#D600FF",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        ></div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <img
            src={imgMedia}
            alt="check"
            style={{
              width: "50px",
              height: "38px",
              marginTop: "8px",
              marginRight: "30px",
            }}
          />
          <div style={{ fontSize: "45px", fontWeight: "bold" }}>
            {title ? title : "Code d’accès validé !"}
          </div>
        </div>
        <p style={{ marginTop: "30px", fontSize: "18px", lineHeight: "30px" }}>
          Votre inscription au {live?.titre} en Live Stream a été enregistrée
          avec succès. Un email automatique de confirmation vient de vous être
          envoyé.
        </p>
        <Boutton
          text="continuer"
          style={{
            borderRadius: "7px",
            backgroundColor: "#522787",
            border: "solid",
            borderWidth: "3px",
            textTransform: "capitalize",
            fontSize: "20px",
            borderColor: "#522787",
            marginRight: "30px",
            width: "200px",
            marginTop: "60px",
          }}
          action={() => history.push("/LiveEvent")}
        />
      </div>
    );
  } else {
    return (
      <div
        style={{
          minHeight: "300px",
          height: "auto",
          minWidth: "250px",
          width: "fit-content",
          borderRadius: "15px",
          backgroundColor: "#1D1D1D",
          position: "absolute",
          padding: "6vw 3vw",
          top: "20%",
          left: 0,
          right: 0,
          overflow: "hidden",
        }}
      >
        
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <img
            src={imgMedia}
            alt="check"
            style={{
              width: "30px",
              height: "22px",
              marginTop: 0,
              marginRight: "20px",
            }}
          />
          <div style={{ fontSize: "22px", fontWeight: "bold" }}>
            {title ? title : "Code d’accès validé !"}
          </div>
        </div>
        <p style={{ marginTop: "30px", fontSize: "18px", lineHeight: "30px" }}>
          {text
            ? text
            : "Votre inscription a été enregistrée avec succès. Un email automatique de confirmation vient de vous être envoyé."}
        </p>
        <Boutton
          text={txtbtn1 ? txtbtn1 : "Renvoyer un mail"}
          style={{
            borderRadius: "7px",
            backgroundColor: "#ebc2c200",
            textAlign: "center",
            border: "solid",
            borderWidth: "1px",
            textTransform: "capitalize",
            fontSize: "16px",
            borderColor: "#ffff",
            width: "90vw",
            marginTop: "60px",
          }}
        />
        <br />
        <Boutton
          text={txtbtn2 ? txtbtn2 : "Continuer"}
          style={{
            borderRadius: "7px",
            backgroundColor: "#522787",
            border: "solid",
            borderWidth: "2px",
            textTransform: "capitalize",
            fontSize: "16px",
            borderColor: "#522787",
            width: "90vw",
            marginTop: "5px",
          }}
        />
      </div>
    );
  }
}
