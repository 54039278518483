import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Tooltip } from "@mui/material";
import hd from '../../assets/icon/high-definition.svg';
import VideoPlayer from "../videoplayer/player";
import ReactPlayer from "react-player"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import moment from 'moment';
import parse from 'html-react-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useState } from 'react';

export default function ModalReplay({ replayPress, onClose, stream }) {

    const [styleImg, setStyleImg] = useState({});

    const [playerMuted, setPlayerMuted] = useState(false);
    const [playerPlaying, setPlayerPlaying] = useState(true);
    const [showBA, setShowBA] = useState(false);
    const customStylesVideos = {
		content: {
			position: 'unset',
			top: '100%',
			left: '100%',
			transform: 'translate(-100%, -100%)',
			width:'100%',
			height: '100%',
			background: "rgba(0,0,0,0.7",
			border: "none",
			padding: "none",
            
		},
	};
    return (
        <div
        style={customStylesVideos}
        >
            <div style={{ position: "absolute", right: 20, top: 40, zIndex: 1500, cursor: "pointer" }}>
                <FontAwesomeIcon icon={solid('x')} onClick={onClose} />
            </div>
            <div className="info-blkImg" style={{width: '100%'}}>
                <img width={'100%'} height={'100%'} effect="blur" loading="lazy"
                    className="" src={"https://vcdn.inlive-stream.com/img/" + stream?.img} alt={stream?.titre} style={{width: '100%'}} />

                <div className="infoButtons">
                    <div className="btnTransac">
                        {(stream?.price_rental) && (
                            <span className="btnLouer" onClick={(e) => { }}>
                                LOUER  {stream?.price_rental} &euro;
                            </span>
                        )}
                        {(stream?.price) && (
                            <span className="btnAcheter" onClick={(e) => { }}>
                                ACHETER {stream?.price} &euro;
                            </span>
                        )}
                    </div>
                    <div className="logoInfos">
                        <div className="d-flex flex-row-reverse">
                            {(showBA && stream?.ba_id) ? (
                                <>
                                    {playerPlaying ? (
                                        <div className="p-gb">
                                            <Tooltip title="Mettre en pause">
                                                <span className="btnIcon" onClick={() => { setPlayerPlaying(false) }}>
                                                    <div className="icnPause"></div>
                                                </span>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div className="p-gb">
                                            <Tooltip title="Continuer la lecture">
                                                <span className="btnIcon" onClick={() => { setPlayerPlaying(true) }}>
                                                    <div className="icnPlay"></div>
                                                </span>
                                            </Tooltip>
                                        </div>
                                    )}
                                    {playerMuted ? (
                                        <div className="p-gb">
                                            <Tooltip title="Activer le son">
                                                <span className="btnIcon" onClick={() => setPlayerMuted(false)}>
                                                    <div className="icnMute"></div>
                                                </span>
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div className="p-gb">
                                            <Tooltip title="Désactiver le son">
                                                <span className="btnIcon" onClick={() => setPlayerMuted(true)}>
                                                    <div className="icnSound"></div>
                                                </span>
                                            </Tooltip>
                                        </div>
                                    )}
                                </>
                            ) : (
                                stream?.ba_id ? (
                                    <div className="p-gb">
                                        <Tooltip title="Relancer la bande annonce">
                                            <span className="btnIcon" onClick={() => { }}>
                                                <div className="icnPlay"></div>
                                            </span>
                                        </Tooltip>
                                    </div>
                                ) : (<></>)
                            )}
                        </div>

                    </div>
                </div>
            </div>

            <div className="row ligInfo">
                <div className="col-md-8 colGauche">
                    <div>
                        <span className="titre">{stream?.titre}</span>
                        {(stream?.isHD !== "0") && <span className="quality"><img src={hd} /></span>}
                    </div>
                    {stream?.desc ? (
                        <div className="desc" dangerouslySetInnerHTML={{ __html: stream?.desc }} />
                    ) : (
                        <div className="desc" dangerouslySetInnerHTML={{ __html: stream?.desc }} />
                    )}
                </div>
                <div className="col-md-4">
                </div>
            </div>
            <div className="brLine">
                <div className="breakLine"></div>
            </div>
        </div>
    )
}
