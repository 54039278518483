/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { listBrowserFront } from "../../redux/browserfront/actions";
import { useHistory } from "react-router-dom";
import { usePageVisibility } from "react-page-visibility";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import constants, { identity } from "../../constants/index";
// Version Mobile
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// Fin version mobile

import useMobileOrientation from "../../hook/useMobileOrientation";
import ModalFilm from "../modalfilm";
import ModalInfo from "../modalinfo";

import {
  getPurchaseProduct,
  getRentalProduct,
} from "../../helpers/product.service";

import { openPopup } from "../../redux/popup/actions";
import { popupNames } from "../../constants";

import logoEvent from "../../assets/img/live-channel.png";
import "./event.css";
import { removeDuplicates } from "../../helpers/removeDuplicates";
import { Audio } from "react-loader-spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ContentSliderComponent from "../VodComponent/SliderComponent";

const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (ref.current) observer.observe(ref.current);

    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

const HomePage = ({ Carousels, Bandeau, browserFrontData }) => {
  const dispatch = useDispatch();

  const { isMobile } = useMobileOrientation("(max-width: 911px)");

  const history = useHistory();

  const containPlayer = useRef(null);
  const refInfoWrapper = useRef(null);
  const refTitreWrapper = useRef(null);

  const meUser = useSelector((state) => state.userReducer.userInfo);
  const refreshPurch = useSelector(
    (state) => state.productReducer.refreshPurchase
  );

  const [showModal, setShowModal] = useState(false);
  const [rulesModal, setRulesModal] = useState();
  var [dataModal, setDataModal] = useState();
  const [dataPurch, setDataPurch] = useState("");

  const [showInfoModal, setShowInfoModal] = useState(false);

  const [purchaseProd, setPurchaseProd] = useState([]);
  const [rentalProd, setRentalProd] = useState([]);
  const [isPurchaseLoaded, setIsPurchaseLoaded] = useState(false);
  const [isRentalLoaded, setIsRentalLoaded] = useState(false);
  let itemPurchase = "";
  let itemRental = "";

  const isVisible = useOnScreen(containPlayer);

  const isPageVisible = usePageVisibility();

  const [thePlayer, setthePlayer] = useState(null);
  const elementRef = useRef(null);

  let srcBA = "";
  let displayBA = false;

  const [header, setHeader] = useState("header");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 400) {
      return setHeader("header");
    } else if (window.scrollY > 403) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  useEffect(() => {
    dispatch(listBrowserFront());
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let result = [];
        let fireResult = [];
        let allResult = [];
        if (meUser.uid) {
          result = await getPurchaseProduct(meUser, meUser.uid);

          if (meUser.firebaseid) {
            fireResult = await getPurchaseProduct(meUser, meUser.firebaseid);
            if (result.data && fireResult.data)
              allResult = [...result?.data?.data, ...fireResult?.data?.data];
          } else {
            if (result.data) allResult = [...result?.data?.data];
          }
        }
        setPurchaseProd(removeDuplicates(allResult, "id"));
        setIsPurchaseLoaded(true);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [meUser, refreshPurch]);

  useEffect(() => {
    (async () => {
      try {
        let result = [];
        let fireResult = [];
        let allResult = [];
        if (meUser.uid) {
          result = await getRentalProduct(meUser, meUser.uid);

          if (meUser.firebaseid) {
            fireResult = await getRentalProduct(meUser, meUser.firebaseid);
            allResult = [...result?.data?.data, ...fireResult?.data?.data];
          } else {
            allResult = [...result?.data?.data];
          }
        }
        setRentalProd(removeDuplicates(allResult, "id"));
        setIsRentalLoaded(true);
      } catch (e) {
        console.log(e);
        setIsRentalLoaded(true);
      }
    })();
  }, [meUser, refreshPurch]);

  useLayoutEffect(() => {
    if (!isMobile) {
      setTimeout(() => {}, 3000);
      if (thePlayer === null) return;

      setTimeout(() => {
        thePlayer.play();
      }, 50);
      document.querySelector(".imageWrapper").classList.add("disable");
      var hTitre =
        document.querySelector(".button-layer").getBoundingClientRect().top -
        document.querySelector(".titreWrapper1").getBoundingClientRect()
          .height *
          0.6 -
        document.querySelector(".titreWrapper1").getBoundingClientRect().top;
      refTitreWrapper.current.style =
        "transform-origin: left bottom;transform: scale(0.6) translate3d(0px, " +
        hTitre +
        "px, 0px);transition-duration: 1300ms;transition-delay: 5000ms;";
      refInfoWrapper.current.style =
        "transform : translate3d(0px, " +
        document.querySelector(".infoWrapper1").getBoundingClientRect().height +
        "px, 0px); transition-duration: 1300ms; transition-delay: 5000ms";

      document
        .querySelector(".texteInfoFade")
        .classList.replace("InfoFade", "InfoFadePlay");
      thePlayer.on("ended", () => {
        document.querySelector(".imageWrapper").classList.remove("disable");

        refTitreWrapper.current.style =
          "transform-origin: left bottom;transform: scale(1) translate3d(0px, 0px, 0px);transition-duration: 1300ms;transition-delay: 0ms;";
        refInfoWrapper.current.style =
          "transform: translate3d(0px, 0px, 0px);transition-duration: 1300ms;transition-delay: 0ms; opacity: 1";
        document
          .querySelector(".texteInfoFade")
          .classList.replace("InfoFadePlay", "InfoFade");
      });
    }
  }, [thePlayer]);

  useEffect(() => {
    if (!isMobile) {
      if (!isPageVisible) setShowModal(false);
      if (!isVisible) setShowModal(false);

      if (thePlayer === null) return;
    }
  }, [isVisible, isPageVisible]);

  let settings = {
    dots: false,
    className: "slider variable-width center",
    centerPadding: "100px",
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var mobileSlideSetting = {
    className: "center",
    dots: false,
    lazyLoad: true,
    // infinite: true,
    autoplay: true,
    centerPadding: "100px",
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          // infinite: true,
          // dots: trufe,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  const handleBox = (event, video) => {
    const foundObject = purchaseProd.filter((obj) => obj.id === video.id);
    const checkRental = rentalProd.filter((obj) => obj.id === video.id);
    if (foundObject.length > 0 || checkRental.length > 0) {
      if (foundObject.length > 0) {
        setDataPurch(btoa(foundObject[0].video_id));
      } else {
        setDataPurch(btoa(checkRental[0].video_id));
      }
    } else {
      setDataPurch("");
    }

    let plusX = 30,
      plusY = 80,
      plusW = 60,
      plusH = 200;

    var dimElParent = document
      .querySelector(".slick-list")
      .getBoundingClientRect();
    var dimElEvent = event.target.getBoundingClientRect();

    if (dimElParent.x === dimElEvent.x) {
      plusX = 0;
    }
    if (dimElEvent.right >= dimElParent.right) {
      plusX = 60;
    }
    const myRules = {
      hidden: {
        opacity: 0,
        zIndex: 10,
        x: dimElEvent.left + window.scrollX,
        y: dimElEvent.top + window.scrollY,
        position: "absolute",
        width: dimElEvent.width,
        height: dimElEvent.height,
        backgroundColor: "rgb(23,22,22)",
        transition: { delay: 0 },
      },
      visible: {
        opacity: 1,
        x: dimElEvent.left + window.scrollX - plusX,
        y: dimElEvent.top + window.scrollY - plusY,
        width: dimElEvent.width + plusW,
        height: dimElEvent.height + plusH,
        transition: { bounce: 0, delay: 0 },
      },
    };
    setRulesModal(myRules);
    setDataModal(event.target.getAttribute("data-gab"));

    setShowModal(true);
  };

  const showPopMobile = (e) => {
    const event = e.target.tagName === "IMG" ? e.target : e.target.children[0];

    const data_purch = event.getAttribute("data-purch");
    const data_rent = event.getAttribute("data-rent");
    const data_gab = event.getAttribute("data-gab");

    var data_achat = "";
    if (data_purch.length > 0 || data_rent.length > 0) {
      data_achat = data_purch.length > 0 ? data_purch : data_rent;
    }
    dispatch(openPopup(popupNames.detailSheet, null, data_gab, data_achat));
  };

  const showCarouselWeb = (film) => {
    if (meUser.uid) {
      try {
        itemPurchase = purchaseProd.filter((r) => {
          if (r.id === film.id) {
            return r;
          } else return null;
        });
        itemRental = rentalProd.filter((r) => {
          if (r.id === film.id) return r;
          else return null;
        });
      } catch (e) {}
    }
    let data_gab = btoa(unescape(encodeURIComponent(JSON.stringify(film))));
    let data_purch =
      itemPurchase.length > 0 ? btoa(itemPurchase[0].video_id) : "";
    let data_rent = itemRental.length > 0 ? btoa(itemRental[0].video_id) : "";

    if (data_purch.length > 0 || data_rent.length > 0) {
      setDataPurch(data_purch.length > 0 ? data_purch : data_rent);
    } else {
      setDataPurch("");
    }

    setDataModal(data_gab);
    setShowInfoModal(true);
  };

  function sortByPosiDescending(data) {
    return data.slice().sort((a, b) => parseInt(a.posi) - parseInt(b.posi));
  }

  function checkCarouselBandeau() {
    let arrData = browserFrontData;
    if (Bandeau.length < 1 && Carousels.length > 0) {
      arrData = arrData.slice(1);
      return arrData;
    }
    return arrData;
  }

  return (
    <>
      <div className="mainView">
        <ModalInfo
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
          dataModal={dataModal}
          dataPurch={dataPurch}
        />
        <ModalFilm
          showModal={showModal}
          setShowModal={setShowModal}
          myRulesModal={rulesModal}
          dataPurch={dataPurch}
          dataModal={dataModal}
          setShowInfoModal={setShowInfoModal}
          srcBA={srcBA}
          displayBA={displayBA}
        />
        {!isMobile && (
          <>
            <div
              className="ctnrGab is-fullGab"
              style={{ zIndex: 0, position: "relative" }}
            >
              <div className="animations-container">
                {isPurchaseLoaded && isRentalLoaded && Bandeau.length > 0 ? (
                  <div
                    ref={containPlayer}
                    className="paneTrailer"
                    onMouseOver={() => {
                      if (showModal) setShowModal(false);
                    }}
                  >
                    <div className="imageWrapper">
                      <img
                        alt=""
                        className="static-img"
                        src={constants.URL_FILE + Bandeau[0]?.img}
                      />
                    </div>

                    <div className="fillContainer">
                      <div className="info metaLayer">
                        {Bandeau[0].type === "LIVE" && (
                          <div
                            className="titreWrapper1"
                            style={{
                              transformOrigin: "left bottom",
                              transform: "scale(1) translate3d(0px, 0px, 0px)",
                              transitionDuration: "1300ms",
                              transitionDelay: "0ms",
                            }}
                          >
                            <div className="titre">
                              <span className="envelopEvent">
                                <span>Live Event</span>
                                <img
                                  alt=""
                                  className="logoEvent"
                                  src={logoEvent}
                                />
                                <span className="backgroundEvent"></span>
                              </span>
                            </div>
                          </div>
                        )}

                        <div
                          className="infoWrapper1"
                          style={{
                            transform: "translate3d(0px, 0px, 0px)",
                            transitionDuration: "1300ms",
                            transitionDelay: "0ms",
                            opacity: "1",
                          }}
                        >
                          <div className="texteInfoFade InfoFade">
                            <div className="synopsisInfoMarge">
                              {Bandeau.length > 0 &&
                                Bandeau[Bandeau.length - 1]?.titre}
                              <br />

                              <span
                                style={{ color: "#FFC500", fontWeight: "bold" }}
                              >
                                {Bandeau.length > 0 &&
                                  Bandeau[Bandeau.length - 1]?.titre_preorder}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="button-layer">
                          <div className="button-wrapper">
                            <div
                              data-gab={btoa(
                                unescape(
                                  encodeURIComponent(JSON.stringify(Bandeau[0]))
                                )
                              )}
                              data-purch={
                                itemPurchase.length > 0
                                  ? btoa(itemPurchase[0].video_id)
                                  : ""
                              }
                              data-rent={
                                itemRental.length > 0
                                  ? btoa(itemRental[0].video_id)
                                  : ""
                              }
                              className="play-button default-static-button default"
                              onClick={(e) => {
                                e.stopPropagation();
                                meUser.uid
                                  ? // btnPaymentClick("achat")
                                    Bandeau[0].type === "LIVE"
                                    ? history.push({
                                        pathname: "/Billetterie/",
                                        state: {
                                          image:
                                            constants.URL_FILE +
                                            Bandeau[0]?.img,
                                          data: Bandeau[0],
                                        },
                                      })
                                    : showCarouselWeb(Bandeau[0])
                                  : identity.Login();
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  width: "auto",
                                  fontSize: "14px",
                                  padding: "3px",
                                }}
                              >
                                {Bandeau[0].type === "VIDEO"
                                  ? "Decouvrir"
                                  : "RESERVER MON LIVE STREAM"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      infiniteLoop={true}
                      autoPlay={true}
                      stopOnHover={false}
                      dynamicHeight={true}
                      interval={5000}
                      transitionTime={750}
                      className={header}
                      ref={elementRef}
                    >
                      {isPurchaseLoaded &&
                        isRentalLoaded &&
                        Carousels &&
                        Carousels.map((film, idx) => {
                          if (meUser.uid) {
                            try {
                              itemPurchase = purchaseProd.filter((r) => {
                                if (r.id === film.id) {
                                  return r;
                                } else return null;
                              });
                              itemRental = rentalProd.filter((r) => {
                                if (r.id === film.id) return r;
                                else return null;
                              });
                            } catch (e) {}
                          }
                          return (
                            <div
                              key={idx}
                              onClick={() => showCarouselWeb(film)}
                            >
                              <img
                                className={header}
                                alt={film.titre}
                                style={{ height: "100vh" }}
                                src={constants.URL_FILE + film.img}
                                data-gab={btoa(
                                  unescape(
                                    encodeURIComponent(JSON.stringify(film))
                                  )
                                )}
                                data-purch={
                                  itemPurchase.length > 0
                                    ? btoa(itemPurchase[0].video_id)
                                    : ""
                                }
                                data-rent={
                                  itemRental.length > 0
                                    ? btoa(itemRental[0].video_id)
                                    : ""
                                }
                              />
                            </div>
                          );
                        })}
                    </Carousel>
                  </div>
                )}

                <div
                  className="slideContainer"
                  style={
                    Bandeau.length > 0
                      ? { padding: "43% 50px 0px", zIndex: "3" }
                      : {
                          padding: "7% 50px -100px",
                          zIndex: "3",
                          position: "relative",
                        }
                  }
                >
                  <div style={{ position: "relative" }}>
                    <Slider {...settings} infinite={Carousels.length > 3}>
                      {isPurchaseLoaded &&
                        isRentalLoaded &&
                        Bandeau &&
                        Bandeau.length > 0 &&
                        Carousels &&
                        Carousels.map((film, idx) => {
                          return (
                            <div
                              key={idx}
                              onMouseEnter={(e) => handleBox(e, film)}
                              style={{ zIndex: 2, position: "relative" }}
                            >
                              <LazyLoadImage
                                effect="blur"
                                src={constants.URL_FILE + film.img_thumbnail}
                                alt={film.titre}
                                data-gab={btoa(
                                  unescape(
                                    encodeURIComponent(JSON.stringify(film))
                                  )
                                )}
                              />
                            </div>
                          );
                        })}
                    </Slider>
                  </div>

                  {Carousels.length > 0 &&
                    browserFrontData &&
                    browserFrontData.length > 0 && (
                      <div
                        className="slideContainer"
                        style={
                          Bandeau.length > 0
                            ? { display: "none" }
                            : {
                                padding: "0px 50px 0px",
                                zIndex: "3",
                                marginTop: "-20vh",
                              }
                        }
                      >
                        <div
                          className="headerContainer"
                          onClick={() => {
                            history.push(
                              "/seeAll/" +
                                browserFrontData[0].key.split(" ").join("")
                            );
                          }}
                        >
                          <div className="HeaderSlide">
                            {browserFrontData[0].key}
                          </div>
                          <div className="toutvoir-row-header">
                            <div className="link-see-all">tout voir</div>
                            <div className="iconChevron"></div>
                          </div>
                        </div>

                        <Slider
                          {...settings}
                          infinite={browserFrontData[0].values.length > 3}
                        >
                          {isPurchaseLoaded &&
                            isRentalLoaded &&
                            browserFrontData &&
                            sortByPosiDescending(
                              browserFrontData[0].values
                            )?.map((film, idx) => {
                              return (
                                <div
                                  key={idx}
                                  onMouseEnter={(e) => handleBox(e, film)}
                                  style={{ zIndex: "10", width: "100%" }}
                                >
                                  <LazyLoadImage
                                    effect="blur"
                                    loading="lazy"
                                    className="imgConta"
                                    height={"100%"}
                                    width={"100%"}
                                    src={
                                      constants.URL_FILE + film.img_thumbnail
                                    }
                                    alt={film.titre}
                                    data-gab={btoa(
                                      unescape(
                                        encodeURIComponent(JSON.stringify(film))
                                      )
                                    )}
                                  />
                                </div>
                              );
                            })}
                        </Slider>
                      </div>
                    )}
                </div>
              </div>
            </div>

            {Bandeau &&
              Bandeau.length < 1 &&
              Carousels &&
              Carousels.length < 1 && <div style={{ marginTop: 70 }} />}

            {!isPurchaseLoaded || !isRentalLoaded ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Audio color="#fbfbfb" height={100} width={100} />
              </div>
            ) : (
              <>
                {browserFrontData &&
                  checkCarouselBandeau(browserFrontData).map((itemData) => (
                    <ContentSliderComponent
                      key={itemData.key}
                      handleBox={handleBox}
                      title={itemData.key}
                      data={itemData.values}
                      isLoaded={isPurchaseLoaded && isRentalLoaded}
                      customStyle={
                        isPurchaseLoaded && isRentalLoaded
                          ? { padding: "0px 50px 30px 50px", zIndex: "30" }
                          : { display: "none" }
                      }
                    />
                  ))}
              </>
            )}
          </>
        )}

        {isMobile && (
          <>
            <div ref={containPlayer}></div>
            <div className="slideContainer">
              {isPurchaseLoaded && isRentalLoaded && (
                <>
                  {Bandeau && Bandeau.length > 0 ? (
                    <div
                      className="animations-container"
                      style={{ height: 500 }}
                      onMouseOver={() => {
                        if (showModal) setShowModal(false);
                      }}
                    >
                      {/* La version animation + video se trouve dans le ficheir block_carousel.js */}
                      <div className="imageWrapper" style={{ height: "100%" }}>
                        <img
                          height={500}
                          className="static-img"
                          alt=""
                          src={constants.URL_FILE + Bandeau[0]?.img}
                        />
                      </div>

                      <div className="fillContainer" style={{ width: "100%" }}>
                        <div
                          className="info metaLayer"
                          style={{
                            position: "absolute",
                            width: "100%",
                            bottom: 0,
                          }}
                        >
                          {Bandeau[0].type === "LIVE" && (
                            <div className="" style={{ width: 100 }}>
                              <LazyLoadImage
                                effect="blur"
                                width={"50px"}
                                height="30px"
                                className=""
                                src={logoEvent}
                              />
                            </div>
                          )}

                          <div
                            className="infoWrapper1"
                            style={{
                              transform: "translate3d(0px, 0px, 0px)",
                              transitionDuration: "1300ms",
                              transitionDelay: "0ms",
                              opacity: "1",
                              width: "100%",
                            }}
                          >
                            <div className="texteInfoFade InfoFade">
                              <div className="synopsisInfoMarge">
                                {Bandeau.length > 0 &&
                                  Bandeau[Bandeau.length - 1]?.titre}
                                <br />
                                <span
                                  style={{
                                    color: "#FFC500",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {Bandeau.length > 0 &&
                                    Bandeau[Bandeau.length - 1]?.titre_preorder}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="button-layer">
                            <div
                              className="button-wrapper"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                display: "flex",
                              }}
                            >
                              <div
                                data-gab={btoa(
                                  unescape(
                                    encodeURIComponent(
                                      JSON.stringify(Bandeau[0])
                                    )
                                  )
                                )}
                                data-purch={
                                  itemPurchase.length > 0
                                    ? btoa(itemPurchase[0].video_id)
                                    : ""
                                }
                                data-rent={
                                  itemRental.length > 0
                                    ? btoa(itemRental[0].video_id)
                                    : ""
                                }
                                className="play-button default-static-button default"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  meUser.uid
                                    ? // btnPaymentClick("achat")
                                      Bandeau[0].type === "LIVE"
                                      ? history.push({
                                          pathname: "/Billetterie/",
                                          state: {
                                            image: `https://cimage.inlive-stream.com/img.php?src=https://vcdn.inlive-stream.com/img/${Bandeau[0]?.img}&w=1280`,
                                            data: Bandeau[0],
                                          },
                                        })
                                      : showPopMobile(e)
                                    : identity.Login();
                                }}
                              >
                                {/* <span> */}
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    width: "auto",
                                    fontSize: "14px",
                                    padding: "3px",
                                  }}
                                >
                                  {Bandeau[0].type === "VIDEO"
                                    ? "Decouvrir"
                                    : "RESERVER MON LIVE STREAM"}
                                </span>
                                {/* </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      infiniteLoop={true}
                      autoPlay={true}
                      stopOnHover={true}
                      dynamicHeight={true}
                      interval={5000}
                      transitionTime={750}
                    >
                      {isPurchaseLoaded &&
                        isRentalLoaded &&
                        Carousels &&
                        Carousels.map((film, idx) => {
                          let height =
                            Math.round((window.innerHeight * 30) / 100) * 2;
                          if (meUser.uid) {
                            try {
                              itemPurchase = purchaseProd.filter((r) => {
                                if (r.id === film.id) {
                                  return r;
                                } else return null;
                              });
                              itemRental = rentalProd.filter((r) => {
                                if (r.id === film.id) return r;
                                else return null;
                              });
                            } catch (e) {}
                          }
                          return (
                            <div key={idx} onClick={(e) => showPopMobile(e)}>
                              <img
                                alt={film.titre}
                                src={`https://cimage.inlive-stream.com/img.php?src=https://vcdn.inlive-stream.com/img/${film.img_thumbnail}&w=${height}&h=${height}&crop-to-fit`}
                                data-gab={btoa(
                                  unescape(
                                    encodeURIComponent(JSON.stringify(film))
                                  )
                                )}
                                data-purch={
                                  itemPurchase.length > 0
                                    ? btoa(itemPurchase[0].video_id)
                                    : ""
                                }
                                data-rent={
                                  itemRental.length > 0
                                    ? btoa(itemRental[0].video_id)
                                    : ""
                                }
                              />
                            </div>
                          );
                        })}
                    </Carousel>
                  )}

                  {Bandeau &&
                    Bandeau.length < 1 &&
                    Carousels &&
                    Carousels.length < 1 && <div style={{ marginTop: 50 }} />}

                  <div
                    className="containerSlidersMobile"
                    style={{ paddingTop: 0 }}
                  >
                    {browserFrontData &&
                      browserFrontData.map((itemData) => (
                        <div
                          key={itemData.key}
                          className="sliderContainerMobile"
                        >
                          <div
                            className="HeaderSlide-mobile"
                            onClick={() =>
                              history.push(
                                "/seeAll/" + itemData.key.split(" ").join("")
                              )
                            }
                          >
                            {itemData.key}{" "}
                            <span className="chevronMobile"></span>
                          </div>

                          <Slider
                            {...mobileSlideSetting}
                            infinite={itemData.values.length > 3}
                          >
                            {isPurchaseLoaded &&
                              isRentalLoaded &&
                              itemData.values &&
                              sortByPosiDescending(itemData.values).map(
                                (film, idx) => {
                                  if (meUser.uid) {
                                    try {
                                      itemPurchase = purchaseProd.filter(
                                        (r) => {
                                          if (r.id === film.id) return r;
                                          else return null;
                                        }
                                      );
                                      itemRental = rentalProd.filter((r) => {
                                        if (r.id === film.id) return r;
                                        else return null;
                                      });
                                    } catch (e) {}
                                  }
                                  return (
                                    <div
                                      key={idx}
                                      onClick={(e) => showPopMobile(e)}
                                    >
                                      <LazyLoadImage
                                        effect="blur"
                                        src={
                                          constants.URL_FILE +
                                          film.img_thumbnail
                                        }
                                        alt={film.titre}
                                        data-gab={btoa(
                                          unescape(
                                            encodeURIComponent(
                                              JSON.stringify(film)
                                            )
                                          )
                                        )}
                                        data-purch={
                                          itemPurchase.length > 0
                                            ? btoa(itemPurchase[0].video_id)
                                            : ""
                                        }
                                        data-rent={
                                          itemRental.length > 0
                                            ? btoa(itemRental[0].video_id)
                                            : ""
                                        }
                                      />
                                    </div>
                                  );
                                }
                              )}
                          </Slider>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ BrowserFrontReducer }) => ({
  loading: BrowserFrontReducer.loading_action,
  error: BrowserFrontReducer.error,
  Carousels: BrowserFrontReducer.Carousels,
  Bandeau: BrowserFrontReducer.Bandeau,
  browserFrontData: BrowserFrontReducer.browserFrontData,
});

export default connect(mapStateToProps)(HomePage);
