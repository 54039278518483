import { all } from 'redux-saga/effects';
import BrowserFrontSaga from '../browserfront/saga';
import StreamSaga from '../stream/saga';
import UserSaga from '../user/saga';

/**

 * @description combine sagas.

 */

export default function* Sagas() {
  yield all([BrowserFrontSaga(), UserSaga(), StreamSaga()]);
}
