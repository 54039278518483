import { types } from "./types";

/**
 * @description list browserFront.
 */
export const listBrowserFront = (payload) => ({
  type: types.LIST_BROWSERFRONT_REQUEST,
  payload,
});


/**
 * @description list browserFront per category.
 */
export const listBrowserFrontForOneCategory = (payload) => ({
  type: types.GET_CATEGORY_REQUEST,
  payload,
});


