import React, { useState, useSelector } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';


import { openPopup, closePopup } from '../../../redux/popup/actions';
import { popupNames } from '../../../constants';

import logoImgSmall from '../../../assets/img/playstore.png';
import logoImgTall from '../../../assets/img/logo-white.png';

const ForgottenPassword = ({ setShowLoader }) => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [emailHelper, setEmailHelper] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [emailSent, setEmailSent] = useState(false);

	const checkEmail = email => {
    // eslint-disable-next-line
    const regExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email.toLowerCase());
  };

	const checkFormFields = () => {
		if (email === '') {
			setErrorMessage('Veuillez entrer votre e-mail');
			return false;
		} else if (!checkEmail(email)) {
			setErrorMessage('Veuillez entrer un e-mail valide');
			return false;
		}
		setErrorMessage('');
		return true;
	};

	const handleChange = event => setEmail(event.target.value.toLowerCase().trim());

	const handleSubmit = async () => {
		
	};

	const reinitPopup = () => {
		setEmail('');
		setEmailSent(false);
	};

	const baseContent = (
		<>
			<div className="popup-content-title">Réinitialisez votre mot de passe</div>
			<div className="popup-content-text">
				Renseignez votre e-mail et nous vous enverrons un lien pour réinitialiser votre mot
				de passe
			</div>
			<div className="popup-content-form">
				<div className="popup-content-form-item">
					<label htmlFor="email">Email* :</label>
					<TextField
						fullWidth
						id="email"
						name="email"
						error={emailHelper ? true : false}
						helperText={emailHelper}
						value={email}
						onChange={handleChange}
						onBlur={handleChange}
					/>
				</div>
				{errorMessage && <div className="popup-content-error">{errorMessage}</div>}
				{successMessage && <div className="popup-content-success">{successMessage}</div>}
				<div className="popup-content-submit" onClick={handleSubmit}>
					Réinitialiser le mot de passe
				</div>
				<div
					className="popup-content-link"
					onClick={() => dispatch(openPopup(popupNames.login))}>
					Retour à la connexion
				</div>
			</div>
		</>
	);

	const sentContent = (
		<>
			<div className="popup-content-title">Consultez vos mails</div>
			<div className="popup-content-text">
				Consultez vos mails et cliquez sur le lien que nous vous avons envoyé pour
				continuer.
			</div>
			<div className="popup-content-blank_space"></div>
			<div className="popup-content-text">
				<span>Vous n'avez rien reçu ? </span>
				<span className="popup-content-link" onClick={reinitPopup}>
					Renvoyer le lien
				</span>
			</div>
		</>
	);

	return (
		<>
			<div className="popup-content-close">
				<button
					className="popup-content-close-button"
					onClick={() => dispatch(closePopup())}
				/>
			</div>
			<div className="popup-content-logo">
				<img
					className="popup-content-logo-sm"
					src={logoImgSmall}
					alt="logo Inlive Stream"
				/>
				<img className="popup-content-logo-xl" src={logoImgTall} alt="logo Inlive Stream" />
			</div>
			{!emailSent ? baseContent : sentContent}
		</>
	);

};

export default ForgottenPassword;