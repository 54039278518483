import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { logout } from "../../../helpers/firebase";
import { Avatar } from "@material-ui/core";
import constants, { identity } from "../../../constants";
import { useToaster } from "rsuite";

import InfoPerso from "./pages/infoPerso";

import useMobileOrientation from "../../../hook/useMobileOrientation";
import { connect, useDispatch } from "react-redux";

import "./_style.scss";
import Message from "../../../helpers/Notifications";
import { getUserProfile } from "../../../redux/user/actions";
import logoEvent from "../../../assets/img/live-channel.png";

const MonCompte = ({ userData, loading }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const toaster = useToaster();

  const { isMobile } = useMobileOrientation("(max-width: 911px)");

  const Datas = localStorage.getItem("data");

  let tblName = [];
  if (constants.IDENTITY_ACCESS) {
    tblName = [userData?.lastName, userData?.firstName];
  } else {
    tblName = JSON.parse(Datas)?.displayName.split(" ");
  }


  const [pInfoPerso, setPInfoPerso] = useState(true);
  const [pChangePwd, setPChangePwd] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(
          getUserProfile({ userId: JSON.parse(Datas)?.accountId })
        );
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const switchPage = (page) => {
    switch (page) {
      case "infoPerso":
        setPInfoPerso(true);
        setPChangePwd(false);
        break;
      case "changePwd":
        if (!constants.IDENTITY_ACCESS) {
          setPInfoPerso(false);
          setPChangePwd(true);
        } else {
          window.open(
            constants.BASE_URL_LOGIN +
              "/auth/change-password?callback=" +
              constants.URL_CALLBACK +
              "&email=" +
              userData.email,
            "_self"
          );
        }

        break;
    }
  };

  const logoutFunc = () => {
    toaster.push(<Message type="success" message="Deconnexion réussie!" />, {
      placement: "topEnd",
    });
    logout();
    localStorage.removeItem("firebaseid");
    localStorage.removeItem("slug");
    history.push("/");
  };

  return (
    <>
      {isMobile && (
        <div className="row moncompte-menu">
          <div className="col-md-12 margin20">
            {/* <div className="line">
					{userData?.createdAt && <span className="infoDate-mobile">Membre depuis le {dateEnreg}</span>}
				</div> */}
            <div className="line mnu">
              <span
                className="mnuMonCompte"
                onClick={(e) => {
                  history.push("/");
                }}
              >
                Accueil
              </span>
            </div>
            <div className="line mnu">
              <span
                className="mnuMonCompte"
                onClick={(e) => {
                  history.push("/MyPurchases");
                }}
              >
                Mes achats
              </span>
            </div>
            <div className="line mnu">
              <span
                className="mnuMonCompte"
                onClick={(e) => {
                  history.push("/LiveEvent");
                }}
              >
                <span className="">
                  <span>Live Event</span>
                  <img className="logoEvent" src={logoEvent} />
                  {/* <span className="backgroundEvent"></span> */}
                </span>
              </span>
            </div>

            <div className="break-mobile"></div>

            <div className="line mnu">
              <span
                className={`mnuMonCompte ${pInfoPerso ? "mnuSelected" : ""}`}
                onClick={(e) => {
                  e.stopPropagation();
                  switchPage("infoPerso");
                }}
              >
                Mes informations
              </span>
            </div>

            {/* <div className="line mnu">
					<span className={`mnuMonCompte ${pChangePwd ? 'mnuSelected' : ''}`}
						// onClick={(e) => {updateAccount()}}
					>
						Mes moyens de paiement
					</span>
				</div> */}

            <div className="line mnu">
              <span
                className="lien"
                onClick={(e) => {
                  logoutFunc();
                }}
              >
                Déconnexion
              </span>
            </div>

            <div className="break-mobile"></div>
          </div>
          <div className="col-md-12">
            {pInfoPerso && <InfoPerso Datas={userData} />}
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="row moncompte-menu">
          <div className="col-md-4 align-left">
            <div className="marginRight20">
              <div className="line mnu">
                <span
                  className={`mnuMonCompte ${pInfoPerso ? "mnuSelected" : ""}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    switchPage("infoPerso");
                  }}
                >
                  Mes informations
                </span>
              </div>

              {/* <div className="line mnu">
						<span className={`mnuMonCompte`}
							// onClick={(e) => {e.stopPropagation(); switchPage('changePwd');}}
						>
							Mes moyens de paiement
						</span>
					</div> */}
              <div className="line mnu">
                <span
                  className={`mnuMonCompte`}
                  onClick={(e) => {
                    history.push("/MyPurchases");
                  }}
                >
                  Mes achats
                </span>
              </div>

              <div className="line mnu">
                <span
                  className="lien"
                  onClick={(e) => {
                    logoutFunc();
                  }}
                >
                  Déconnexion
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            {pInfoPerso && <InfoPerso Datas={userData} />}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ userReducer }) => ({
  userData: userReducer.userData,
  loading: userReducer.loading,
});

export default connect(mapStateToProps)(MonCompte);
