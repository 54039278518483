import React from 'react';
import { Notification, Placeholder } from 'rsuite';

const Message = React.forwardRef(({ type, ...rest }, ref) => {
    return (
        <Notification ref={ref} {...rest} type={type} style={{backgroundColor: '#4BB543', color: '#fff', zIndex: 1000, position: 'relative'}}>
            <span style={{color: '#fff'}}>{rest.message}</span>
        </Notification>
    );
});

export default Message;
