import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { URL_API_IMG, URL_API_IMG_PUB } from '../../constants';
import {Audio as Loader} from 'react-loader-spinner';
import { detectBrowser } from '../../tools';

const TIMEOUT_TRY_IMAGE = 1000;
const CPT_TRY_MAX_IMAGE = 10;

const getFormatSupported = (isSafari, format) =>
    isSafari ? (['png', 'webp', 'jpeg'].includes(format) ? format : 'jpeg') : 'webp';
const getImagePubPath = (mediaId, width, height, extension) =>
    encodeURI(`${URL_API_IMG_PUB}/${mediaId}/${width}x${height}.${extension}`); //API forced to webp

const Image = ({ filename, format, preview, width, height, placeholder, imgStyle, ...props }) => {
    const [cptTryImg, setCptTryImg] = useState(0);
    const [loading, setLoading] = useState(false);
    const isSafari = detectBrowser().includes('Safari');
    const [imgPath, setImgPath] = useState(
        getImagePubPath(filename, width, height, getFormatSupported(isSafari, format))
    );
    const folderS3 = ""; //ManagerService.getShard();

    useEffect(() => {
        setImgPath(getImagePubPath(filename, width, height, getFormatSupported(isSafari, format)));
    }, [filename, width, height, isSafari, format]);
    const onSuccess = () => {
        setLoading(false);
        setCptTryImg(0);
        typeof props.onLoad === 'function' && props.onLoad();
    };

    const onErrorImg = async e => {
        const target = e.target;
        setLoading(true);
        if (cptTryImg < CPT_TRY_MAX_IMAGE) {
            const newUrl = isSafari
                ? await getImageResize(folderS3, filename, format, width, height, format)
                : await getImageResize(folderS3, filename, format, width, height, 'webp');
            setTimeout(() => {
                setCptTryImg(prevState => prevState + 1);
                target.onError = null;
                target.src = newUrl;
            }, TIMEOUT_TRY_IMAGE);
        } else {
            target.onError = null;
            target.src = placeholder;
        }
    };

    const getImageResize = async (folder, mediaId, format, width, height, extension) => {
        // eslint-disable-line
				/*
        const body = {
            mediaId,
            height,
            width,
            originKey: `${folder}${mediaId}.${format}`,
            format: extension
        };
        const resp = await ApiMediaService.post(URL_API_IMG, body);
        const data = resp.data.data;
        return getImagePubPath(data.mediaId, data.width, data.height, data.format);
				*/
    };

    const getClassName = () => {
        let className = 'image';
        if (props.className) className += props.className;
        if (loading) className += ' --loading';
        return className;
    };

    return (
        <>
            {loading && (
                <div className="image-loader">
                    <Loader type="Audio" color="#fbfbfb" height={100} width={100} />
                </div>
            )}
            <img
                {...props}
                className={getClassName()}
                alt={props.alt ? props.alt : undefined}
                src={imgPath}
                onError={onErrorImg}
                onLoad={onSuccess}
            />
        </>
    );
};

Image.defaultProps = {
    placeholder: null,
    className: ''
};
Image.propTypes = {
    format: PropTypes.string,
    height: PropTypes.number,
    filename: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    width: PropTypes.number
};

export default Image;
