import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/";

import { closePopup } from "../../../redux/popup/actions";

//import VideoPlayer from "../../videoplayer/index.new";
import VideoPlayer from "../../videoplayer/player";
import JwPlayer from "../../videoplayer/jwplayers";

import ReactPlayer from "react-player";
import { motion, AnimatePresence } from "framer-motion";
import hd from "../../../assets/icon/high-definition.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Tooltip } from "@mui/material";

import { openPopup } from "../../../redux/popup/actions";
import { fetchEncodedProduct } from "../../../redux/product/actions";
import { popupNames } from "../../../constants";
import decodeURIComponentSafely from "../../../helpers/decodeUri";

const DetailSheet = () => {
	
  const dispatch = useDispatch();
  const history = useHistory();
  const { data_gab: _dataGab, data_purch: _dataPurch } = useSelector(
    (state) => state.popupReducer
  );
  const meUser = useSelector((state) => state.userReducer.userInfo);
  const [styleImg, setStyleImg] = useState({});
  const [showPlayer, setShowPlayer] = useState(false);
  const [urlFilm, setUrlFilm] = useState("");
  var dataGab = null;
  const [showBA, setShowBA] = useState(false);
  const [playerMuted, setPlayerMuted] = useState(true);
  const [playerPlaying, setPlayerPlaying] = useState(true);

  try {
    dataGab = JSON.parse(decodeURIComponentSafely(escape(atob(_dataGab))));
  } catch (e) {}

  const dataPurch = _dataPurch !== null ? atob(_dataPurch) : null;

  const videoEnded = () => {
    setStyleImg();
    setShowBA(false);
  };
  const videoPlay = () => {
    setStyleImg({ visibility: "hidden" });
  };
  useEffect((e) => {
    if (dataGab)
      if (dataGab.bandeannonce) {
        setShowBA(true);
      }
  });

  const btnPaymentClick = (type) => {
    setPlayerPlaying(false);
    dispatch(fetchEncodedProduct(_dataGab));
    dispatch(openPopup(popupNames.payment, type));
  };
  function closeModal() {
    setStyleImg();
    setPlayerPlaying(false);
    dispatch(closePopup());
  }
  const btnPlay = () => {
    if (dataPurch) {
      if (process.env.NODE_ENV === "development") {
        console.log("dataPurch : ", dataPurch);
      }
      setUrlFilm(dataPurch);
      setShowPlayer(true);
      //			setSrcFilm(atob(dataPurch));
      //closeModal();
      //			openModalFilm();
    }
  };
  const goAudioBook = () => {
    if (dataPurch) {
      if (process.env.NODE_ENV === "development") {
        console.log("dataPurch : ", dataPurch);
      }
      history.push(dataPurch);
    }
  };

  const isSafari = () => {
    return (
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1
    );
  };

  return (
    <>
      <div className="popup-content-close">
        <button
          className="popup-content-close-button-mobile"
          onClick={() => dispatch(closePopup())}
        />
      </div>
      {showPlayer &&
        isSafari() && <JwPlayer src={urlFilm} />}
      {showPlayer &&
        !isSafari() && <VideoPlayer src={urlFilm} />}
      {!showPlayer && dataGab !== null && (
        <>
          <div className="info-blkImg">
            <img
              className="info-img-mobile"
              src={
                dataGab.img_thumbnail.includes("cimage")
                  ? dataGab.img_thumbnail
                  : `https://cimage.inlive-stream.com/img.php?src=https://vcdn.inlive-stream.com/img/${dataGab.img_thumbnail}&w=900`
              }
              alt={dataGab.titre}
              style={styleImg}
            />
          </div>
          {showBA && (
            <div className="info-video-mobile">
              <div className="video-wrapper">
                <ReactPlayer
                  className="videoPlayer"
                  width="100%"
                  height="100%"
                  url={dataGab.bandeannonce}
                  playing={playerPlaying}
                  playsinline={true}
                  autoPlay={true}
                  controls={false}
                  muted={playerMuted}
                  loop={false}
                  onPlay={videoPlay}
                  onEnded={videoEnded}
                />
                <div className="logoInfos-mobile">
                  <div className="d-flex flex-row-reverse">
                    {playerMuted ? (
                      <div className="p-gb">
                        <Tooltip title="Activer le son">
                          <span
                            className="btnIcon"
                            onClick={() => setPlayerMuted(false)}
                          >
                            <div className="icnMute-mobile"></div>
                          </span>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="p-gb">
                        <Tooltip title="Désactiver le son">
                          <span
                            className="btnIcon"
                            onClick={() => setPlayerMuted(true)}
                          >
                            <div className="icnSound-mobile"></div>
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="content-popup-mobile">
            <div className="row ligInfo-mobile">
              <div className="col-md-12">
                <div>
                  <span className="titre">{dataGab.titre}</span>
                  {dataGab.isHD !== "0" && (
                    <span className="quality">
                      <img src={hd} />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row row-mobile">
              <div className="col-md-12">
                {dataPurch && (
                  <div
                    className="btnLouer-mobile"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (dataGab.type === "VIDEO") btnPlay();
                      if (dataGab.type === "BOOK") goAudioBook();
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {dataGab.type === "VIDEO" ? (
                      <span className="btnPlayHA">Lecture </span>
                    ) : (
                      <span className="btnPlayHA">Visionner </span>
                    )}
                    <FontAwesomeIcon icon={solid("play")} />
                  </div>
                )}

                {!dataPurch && dataGab.price_rental && (
                  <div
                    className="btnLouer-mobile"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (meUser.uid) {
                        btnPaymentClick("loc");
                      } else {
                        console.log("loc pushed ! ");
                        closeModal();
                        dispatch(openPopup(popupNames.registration));
                      }
                    }}
                  >
                    LOUER
                    {/* ({dataGab.dureeLoc}) */}
                    {dataGab.price_rental} &euro;
                  </div>
                )}
                {!dataPurch && dataGab.price && (
                  <div
                    className="btnAcheter-mobile"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (meUser.uid) {
                        btnPaymentClick("achat");
                      } else {
                        closeModal();
                        dispatch(openPopup(popupNames.registration));
                      }
                    }}
                  >
                    ACHETER {dataGab.price} &euro;
                  </div>
                )}
              </div>
            </div>

            <div className="row row-mobile desc-mobile">
              <div className="col-md-12">
                {dataGab.desc ? (
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={{ __html: dataGab.desc }}
                  />
                ) : (
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={{ __html: dataGab.desc_short }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DetailSheet;
