import React from "react";
import { createTheme } from "@mui/material";
import {ThemeProvider} from "@mui/material";
import { Link } from 'react-router-dom';

import "./_styles.scss";

const materialTheme = createTheme({
	palette: {
			primary: {
					light: '#f133ff',
					main: '#ec00ff',
					dark: '#b500cb'
			}
	}
});


const styles = {
  table: {
    border: '1px',
    borderColor: 'white',
    borderStyle: 'solid',
    padding: '0.6rem'
  },
  li: {
    lineHeight: 1.2,
    marginLeft: '1rem'
  },
  h2: {
    fontSize: '1.4rem',
    textAlign: 'center'
  },
  h4: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  h5: {
    fontWeight: 'bold',
    marginLeft: '3rem'
  },
  h6: {
    fontStyle: 'italic',
    textDecoration: 'underline'
  },
  bold: {
    fontWeight: 'bold'
  },
  em: {
    display: 'block',
    fontStyle: 'italic'
  },
  strong: {
    display: 'block',
    fontWeight: 'bold'
  },
  indented: {
    marginLeft: '3rem'
  },
  flex: {
    display: 'flex',
    gap: '1rem'
  },
  a: {
    color: '#ec00ff',
  }
};


const MentionsLegales = () => {
	return (
		<div className="margeHaute">
			<div className="profile">
				<ThemeProvider theme={materialTheme}>
        <>
            <h3>Mentions légales</h3>
            <br />
            <div>
                <strong>&Eacute;diteur</strong>
            </div>
            <div>
                Le site internet <a href="https://inlive-stream.com">https://inlive-stream.com</a>{' '}
                est &eacute;dit&eacute; par :
            </div>
            <div>La soci&eacute;t&eacute; INLIVE WITH</div>
            <div>
                <strong>SAS INLIVE WITH&nbsp;</strong>au capital social de 11 111 euros
            </div>
            <div>Immatricul&eacute;e au RCS de Paris sous le num&eacute;ro 852 198 555</div>
            <div>Si&egrave;ge social : 5, rue Vernet - 75008 Paris</div>
            <div>N&deg; TVA intracommunautaire FR45852198555</div>
            <div>
                Adresse &eacute;lectronique :{' '}
                <span>
                    <a href="mailto:contact@inlivewith.com">contact@inlivewith.com</a>
                </span>
            </div>
            <div>&nbsp;</div>
            <div>
                <strong>Directeur de la publication </strong>
            </div>
            <div>
                Monsieur<strong> Thierry SAID</strong>, Pr&eacute;sident(e) de la
                soci&eacute;t&eacute;
                <strong> INLIVE WITH</strong>
            </div>
        </>
				</ThemeProvider>
			</div>
		</div>
	)
}
export default MentionsLegales;