import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Tooltip } from "@mui/material";
//import VideoPlayer from '../../components/videoplayer';
import VideoPlayer from "../videoplayer/player";
import JwPlayer from "../videoplayer/jwplayers";

import Modal from "react-modal";
import { openPopup } from "../../redux/popup/actions";
import { fetchEncodedProduct } from "../../redux/product/actions";
import constants, { identity, popupNames } from "../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import parse from "html-react-parser";
import decodeURIComponentSafely from "../../helpers/decodeUri";
import { removeTags } from "../../helpers/helpers";
import ModalCountry from "../ModalCountry";
var lesDatas = null;

const ModalFilm = ({
  showModal,
  setShowModal,
  myRulesModal,
  dataModal,
  setShowInfoModal,
  dataPurch,
}) => {
  //	const [userInfo, loadingAuth, errorAuth] = useAuthState(auth);
  const meUser = useSelector((state) => state.userReducer.userInfo);

  const isAuthenticated = meUser.uid ? true : false;

  const ref = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [srcBA, setSrcBA] = useState("");
  const [isTrailer, setIsTrailer] = useState(true);

  const [selectCountry, setSelectCountry] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const addMetaSuccess = useSelector((state) => state.userReducer.metaCountry);


  if (dataModal) {
    try {
      lesDatas = JSON.parse(decodeURIComponentSafely(escape(atob(dataModal))));
    } catch (error) {
      console.log("Error parsing JSON:", error);
    }
  }

  useEffect(()=> {
    if(addMetaSuccess==='SUCCESS'){
      setSelectCountry(false);
      proceedToPayment(paymentType);
    }
  }, [addMetaSuccess]);

  const proceedToPayment = (type) => {
    dispatch(fetchEncodedProduct(dataModal));
    dispatch(openPopup(popupNames.payment, type));
  };

  const btnPaymentClick = (type) => {
    // const metaCountry = localStorage.getItem("metaCountry");
    setPaymentType(type)
    // if (metaCountry) {
    //   console.log(metaCountry);
      proceedToPayment(type);
    // } else {
    //   setSelectCountry(true);
    // }
  };

  const btnPlayClick = () => {
    if (lesDatas?.video_id) {
      if (dataPurch) {
        setSrcBA(lesDatas?.video_id);
        openModal();
      } else {
        // dispatch(openPopup(popupNames.payment, 'achat'));
        btnPaymentClick("achat");
      }
    }
  };

  const playBA = (src) => {
    if (src) {
      setSrcBA(src);
      setIsTrailer(true);
      openModal();
    }
  };

  const playFilm = () => {
    setSrcBA(atob(dataPurch));
    setIsTrailer(false);
    openModal();
  };

  const goAudioBook = () => {
    history.push(atob(dataPurch));
  };

  const onOverlay = (e) => {
    setShowModal(false);
  };

  const authenticat = () => {
    if (constants.IDENTITY_ACCESS) {
      identity.Login();
    } else {
      dispatch(openPopup(popupNames.login));
    }
  };

  const customStyles = {
    content: {
      position: "unset",
      top: "100%",
      left: "100%",
      transform: "translate(-100%, -100%)",
      width: "100%",
      height: "100%",
      background: "rgba(0,0,0,0.7",
      border: "none",
      padding: "none",
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    document.documentElement.style.overflow = "hidden";
    setIsOpen(true);
  }

  function closeModal() {
    document.documentElement.style.overflow = "initial";
    setIsOpen(false);
  }

  const isSafari = () => {
    return (
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1
    );
  };

  // function parseMessage = ()

  Modal.setAppElement("#root");

  return (
    <>
      <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div
          style={{
            position: "absolute",
            right: 10,
            top: 5,
            zIndex: 1500,
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={solid("x")} onClick={closeModal} />
        </div>

        {isSafari() && <JwPlayer src={srcBA} />}
        {!isSafari() && <VideoPlayer src={srcBA} />}
      </Modal>

      <AnimatePresence initial={false} exitBeforeEnter>
        {showModal && lesDatas && (
          <>
            <div className="backModal" onMouseOver={onOverlay}></div>
            <motion.div
              className="popup-film"
              ref={ref}
              variants={myRulesModal}
              initial="hidden"
              animate="visible"
              exit="hidden"
              key="loader"
              onMouseLeave={(e) => {
                e.preventDefault();
                setShowModal(false);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShowInfoModal(true);
              }}
            >
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                width={"100%"}
                className="popup-img"
                src={"https://vcdn.inlive-stream.com/img/" + lesDatas?.img}
                alt={lesDatas?.titre}
              />

              <div className="blocIcn">
                <div className="d-flex flex-row-reverse">
                  <div className="p-gb">
                    <Tooltip title="Plus d'informations">
                      <span className="btnIcon">
                        <div
                          className="icnInfo"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowInfoModal(true);
                          }}
                        ></div>
                      </span>
                    </Tooltip>
                  </div>
                  {lesDatas?.ba_id && (
                    <div className="p-gb">
                      <Tooltip title="Voir la bande annonce">
                        <span
                          className="btnIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            playBA(lesDatas?.ba_id);
                          }}
                        >
                          <div className="icnPlay"></div>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>

              <div className="blocLogo btnAchat">
                {lesDatas && dataPurch.length === 0 && (
                  <div className="row">
                    <div className="col-sm-6 btnLeft">
                      {lesDatas?.price_rental && (
                        <div
                          className="myBtn btnLoc"
                          onClick={(e) => {
                            e.stopPropagation();
                            isAuthenticated
                              ? btnPaymentClick("loc")
                              : authenticat();
                          }}
                        >
                          <span>
                            <span className="txtBtn">LOUER </span>
                          </span>
                          <span>{lesDatas?.price_rental} &euro;</span>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 btnRight">
                      {lesDatas?.price && (
                        <div
                          className="myBtn btnAch"
                          onClick={(e) => {
                            e.stopPropagation();
                            isAuthenticated
                              ? btnPaymentClick("achat")
                              : authenticat();
                          }}
                        >
                          <span>
                            <span className="txtBtn">ACHETER </span>
                          </span>
                          <span>{lesDatas?.price} &euro;</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {dataPurch.length > 0 && (
                  <div className="row">
                    <div
                      className="myBtn btnLecture"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (lesDatas?.type === "VIDEO") playFilm();
                        if (lesDatas?.type === "BOOK") goAudioBook();
                      }}
                    >
                      <span>
                        {lesDatas?.type === "BOOK" ? (
                          <span className="txtBtn">VISIONNER </span>
                        ) : (
                          <span className="txtBtn">LECTURE </span>
                        )}
                      </span>
                      <span style={{ paddingLeft: "10px" }}>
                        <FontAwesomeIcon icon={solid("play")} />
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="contentModal btnAchat">
                <div className="titreModal">
                  {lesDatas && parse(lesDatas?.titre)}
                </div>
                <div className="descModal">
                  <div className="desc" style={{ color: "white" }}>
                    {" "}
                    {removeTags(parse(lesDatas?.desc_short))}{" "}
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
      {/* {selectCountry && (
        <ModalCountry onClose={() => setSelectCountry(false)} />
      )} */}
    </>
  );
};

export default ModalFilm;
