import React from "react";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import HeaderComponent from "./Header";
import { useHistory } from "react-router-dom";
import constants from "../../constants";

const ContentSliderComponent = ({
  title,
  data,
  isLoaded,
  handleBox,
  customStyle,
}) => {
  const history = useHistory();
  let settings = {
    dots: false,
    centerMode: false,
    className: "slider variable-width center",
    centerPadding: "100px",
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function sortByPosiDescending(data) {
    return data.slice().sort((a, b) => parseInt(a.posi) - parseInt(b.posi));
  }

  return (
    <div className="slideContainer" style={customStyle}>
      <HeaderComponent
        title={title}
        link={() => history.push(`/seeAll/${title.split(" ").join("")}`)}
      />
      <Slider {...settings} infinite={data.length > 3}>
        {isLoaded &&
          data &&
          sortByPosiDescending(data).map((film, idx) => (
            <div
              key={film.id}
              onMouseEnter={(e) => handleBox(e, film)}
              style={{ zIndex: "10", width: "100%" }}
            >
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                height={"100%"}
                width={"100%"}
                className="imgConta"
                src={constants.URL_FILE + film.img_thumbnail}
                alt={film.titre}
                data-gab={btoa(
                  unescape(encodeURIComponent(JSON.stringify(film)))
                )}
              />
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default ContentSliderComponent;
