import { types } from "./types";

const initialState = {
  streams: null,
  error: null,
  loading: false,
  current_live: null,
  loading_live: false,
  my_streams: null,
  my_streams_ids: null,
  status: null,
  magic_link: null,
  magic_data: null,
  catalogue: null,
};

const StreamReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STREAM_SUCCESS:
      return { ...state, streams: action.payload, loading: false };

    case types.GET_STREAM_REQUEST:
      return { ...state, loading: true };

    case types.GET_STREAM_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case types.CURRENT_LIVE_SUCCESS:
      return { ...state, current_live: action.payload, loading_live: false };
    case types.CURRENT_LIVE_REQUEST:
      return { ...state, loading_live: true };
    case types.CURRENT_LIVE_FAILURE:
      return { ...state, error: action.payload, loading_live: false };

    case types.PURCHASE_STREAM_REQUEST:
      return { ...state, loading: true };

    case types.PURCHASE_STREAM_SUCCESS:
      return {
        ...state,
        my_streams: action.payload,
        loading: false,
        my_streams_ids: action.payload.map((a) => a.id),
      };
    case types.PURCHASE_STREAM_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case types.VERIFY_CODE_REQUEST:
      return { ...state, loading: true, status: null };
    case types.VERIFY_CODE_SUCCESS:
      return { ...state, loading: false, status: "SUCCESS" };
    case types.VERIFY_CODE_FAILURE:
      return { ...state, loading: false, status: "FAILED" };

    case types.MAGIC_LINK_REQUEST:
      return { ...state, loading: true, status: null };
    case types.MAGIC_LINK_SUCCESS:
      return { ...state, status: "SUCCESS", magic_link: action.payload };
    case types.MAGIC_LINK_FAILURE:
      return { ...state, loading: false, status: "FAILED", magic_link: action.payload };

    case types.MAGIC_STREAM_REQUEST:
      return { ...state, loading: true, status: null };
    case types.MAGIC_STREAM_SUCCESS:
      return {
        ...state,
        loading: false,
        status: "SUCCESS",
        magic_data: action.payload.magiclink,
        catalogue: action.payload.catalogue,
      };
    case types.MAGIC_STREAM_FAILURE:
      return { ...state, loading: false, status: "FAILED" };
    case types.CLOSE_LINK_REQUEST:
      return { ...state, loading: false };
    case types.CLOSE_LINK_FAILURE:
      return { ...state, loading: false };
    case types.CLOSE_LINK_SUCCESS:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default StreamReducer;
