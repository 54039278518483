import React from 'react'
import PropTypes from 'prop-types'

const defaultDialogStyle = {
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	zIndex: 9999,
	background: 'rgba(255, 255, 255, 1)',
	borderRadius: "5px"
}

const defaultBackdropStyle = {
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	zIndex: 9998,
	background: 'rgba(0, 0, 0, 0.3)'
}

const ModelessDialog = props => {

	if(props.isOpen === false) return null;
	
	const close = (e) => {
		e.preventDefault();

		if(props.onClose) props.onClose();
	}


	const mouseOut = (e) => {
		e.preventDefault();
		if(props.onMouseOut) props.onMouseOut();
	}

	const finalDialogStyle = Object.assign({}, defaultDialogStyle, props.style);
	const finalBackdropStyle = Object.assign({}, defaultBackdropStyle, props.backdropStyle)

	return (
		<div className={props.containerClassName}>
			<div className={props.className} style={finalDialogStyle}
				onMouseOut={mouseOut}
			>
				{props.children}
			</div>
			{!props.noBackdrop &&
				<div className={props.backdropClassName} style={finalBackdropStyle}
					onClick={props.clickBackdropToClose && close} />}
		</div>
	)
}

ModelessDialog.propTypes = {

	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element.isRequired)
	]).isRequired,
	
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onMouseOut: PropTypes.func,

	className: PropTypes.string,
	containerClassName: PropTypes.string,
	style: PropTypes.object,

	noBackdrop: PropTypes.bool,
	clickBackdropToClose: PropTypes.bool,
	backdropClassName: PropTypes.string,
	backdropStyle: PropTypes.object
}

ModelessDialog.defaultProps = {
	isOpen: false,
	style: {},
	noBackdrop: true,
	clickBackdropToClose: true,
	backdropStyle: {}
}

export default ModelessDialog