import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, 
	Toolbar, Button, Typography, CssBaseline,
	useScrollTrigger, Box, Container, 
	styled, IconButton, Badge
} from "@mui/material";

import { AccountCircle, Login } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';


import { openPopup } from "../../../redux/popup/actions";
import constants, { popupNames, identity } from "../../../constants";

import logo from '../../../assets/img/icon-logo-horizontal-white.png';
import iconILS from '../../../assets/img/playstore.png';
import logoWhite from '../../../assets/img/logo-white.png';

import './navbar.scss';


function ElevationScroll(props){
	const { children, window } = props; 
	
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window() : undefined,
	});
	
	return React.cloneElement(children, {
		elevation: trigger ? 6 : 0,
		style: {background: trigger ? "#000" : "linear-gradient(#000, transparent)" },
	});
}

ElevationScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};
  
const colorHover = '#aaa';

const MyButton = styled(Button)({
	color: '#fff',
	textTransform: 'none',
	'&:hover' : {
		color: colorHover
	}
});
const MyIconButton = styled(IconButton)({
	color: '#fff',
	'&:hover': {
		color: colorHover
	}
});

const ElevateAppBarMobile = ({props, children}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const userInfo = useSelector(state => state.userReducer.userInfo);
	//const [userInfo, loadingAuth, errorAuth] = useAuthState(auth);
	const isAuthenticated = userInfo?.uid ? true : false;

	const myNotifs = '';

	return (
		<React.Fragment>
		<CssBaseline />
		<ElevationScroll {...props}>
			<AppBar>
				<Toolbar>
					<Box sx={{flexGrow:1, my: 2}} className="navbar-boxLogo-mobile" onClick={(e)=>{history.push('/')}}>
						<img src={iconILS} alt="Inlive Stream" className="navbar-iconILS-mobile" />
						<img src={logoWhite} alt="Inlive Stream" className="navbar-logoWhite-mobile" />
					</Box>

					<Box sx={{ flexGrow: 0 }} className="mnuBtnIcn">

						{ (isAuthenticated) ? (
							<Link to="/MyAccount">
								<MyIconButton sx={{p: '0 0 0 10px'}}>
									<AccountCircle/>
								</MyIconButton>
							</Link>
						):(
							<MyIconButton sx={{p: '0 0 0 10px'}} onClick={(e) => {
								if(constants.IDENTITY_ACCESS){
									identity.Login();
								}else{
									dispatch(openPopup(popupNames.login))
								}
							}}>
								<AccountCircle/>
							</MyIconButton>
							)}
					</Box>
				</Toolbar>

			</AppBar>
		</ElevationScroll>	

		{ children }
	</React.Fragment>
  
	)
}

export default ElevateAppBarMobile;