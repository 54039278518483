import React from "react";
import ElevateAppBar from "../../NavBar";
import Footer from '../../footer';

const WebLayout = ({children}) => {
	return (
		<div className="webLayout">
			<ElevateAppBar> 
				<div className="conteneur">
					{children}
				</div>
				<Footer />
			</ElevateAppBar>
			
		</div>
	)
}
export default WebLayout;