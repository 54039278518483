export const types = {
    GET_STREAM_REQUEST: 'GET_STREAM_REQUEST',
    GET_STREAM_SUCCESS: 'GET_STREAM_SUCCESS',
    GET_STREAM_FAILURE: 'GET_STREAM_FAILURE',

    CURRENT_LIVE_SUCCESS: 'CURRENT_LIVE_SUCCESS',
    CURRENT_LIVE_REQUEST: 'CURRENT_LIVE_REQUEST',
    CURRENT_LIVE_FAILURE: 'CURRENT_LIVE_FAILURE',

    VERIFY_CODE_REQUEST: 'VERIFY_CODE_REQUEST',
    VERIFY_CODE_SUCCESS: 'VERIFY_CODE_SUCCESS',
    VERIFY_CODE_FAILURE: 'VERIFY_CODE_FAILURE',

    GET_BILLET_REQUEST: 'GET_BILLET_REQUEST',
    GET_BILLET_SUCCESS: 'GET_BILLET_SUCCESS',
    GET_BILLET_FAILURE: 'GET_BILLET_FAILURE',

    PURCHASE_STREAM_REQUEST: 'PURCHASE_STREAM_REQUEST',
    PURCHASE_STREAM_SUCCESS: 'PURCHASE_STREAM_SUCCESS',
    PURCHASE_STREAM_FAILURE: 'PURCHASE_STREAM_FAILURE',

    MAGIC_LINK_REQUEST: 'MAGIC_LINK_REQUEST',
    MAGIC_LINK_SUCCESS: 'MAGIC_LINK_SUCCESS',
    MAGIC_LINK_FAILURE: 'MAGIC_LINK_FAILURE',

    MAGIC_STREAM_REQUEST: 'MAGIC_STREAM_REQUEST',
    MAGIC_STREAM_SUCCESS: 'MAGIC_STREAM_SUCCESS',
    MAGIC_STREAM_FAILURE: 'MAGIC_STREAM_FAILURE',

    CLOSE_LINK_REQUEST: 'CLOSE_LINK_REQUEST',
    CLOSE_LINK_SUCCESS: 'CLOSE_LINK_SUCCESS',
    CLOSE_LINK_FAILURE: 'CLOSE_LINK_FAILURE',
};