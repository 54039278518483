import React from "react";
import useMobileOrientation from '../../hook/useMobileOrientation';
import PopupHandler from "../popup";
import WebLayout from "./web";
import MobileLayout from "./mobile";


const Layout = ({children, fullPage}) => {
	const { isMobile } = useMobileOrientation('(max-width: 911px)');
  const Layout = isMobile ? MobileLayout : WebLayout;
	//const Layout = WebLayout;

	return (
		<>
			<PopupHandler />
			{ fullPage ? children : <Layout>{children}</Layout> }
		</>
	)
} 

export default Layout;