import React from 'react';

const HeaderComponent = ({ title, link }) => {
  return (
    <div className="headerContainer" onClick={link}>
      <div className="HeaderSlide">{title}</div>
      <div className="toutvoir-row-header">
        <div className="link-see-all">tout voir</div>
        <div className="iconChevron"></div>
      </div>
    </div>
  );
};

export default HeaderComponent;
