import { combineReducers } from 'redux';
import BrowserFrontReducer from '../browserfront/reducer';
import userReducer from '../user/reducer';
import productReducer from "../product/reducer"
import popupReducer from "../popup/reducer"
import StreamReducer from '../stream/reducer';

const reducerCombination = combineReducers({
  BrowserFrontReducer,
  userReducer,
  productReducer,
  popupReducer,
  StreamReducer,
});

export default reducerCombination;

