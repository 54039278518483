import { NotificationManager } from "react-notifications";
import { types } from "./types";

const getUser = () => {
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const data = localStorage.getItem("data");
  const firebaseid = localStorage.getItem("firebaseid");
  const slug = localStorage.getItem("slug");
  const ret = {
    uid: uid,
    token: token,
    data: JSON.parse(data),
    firebaseid: JSON.parse(firebaseid),
    slug: JSON.parse(slug),
  };
  return ret;
};

const initialState = {
  userInfo: getUser(),
  userData: null,
  loading: false,
  error: null,
  loading_action: false,
  metaCountry: null,
  allCountry: [],
};

const userReducer = (state = initialState, action) => {
  const newState = Object.assign({}, state);

  switch (action.type) {
    case types.FETCH_USER_INFO:
      newState.userInfo = getUser();
      return newState;

    case types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loading: false,
        userInfo: getUser(),
      };

    case types.GET_PROFILE_REQUEST:
      return { ...state, loading: true };

    case types.GET_PROFILE_FAILURE:
      return { ...state, error: action.payload, loading: false };

    case types.UPDATE_PROFILE_SUCCESS:
      return { ...state, userData: action.payload, loading_action: false };

    case types.UPDATE_PROFILE_REQUEST:
      return { ...state, loading_action: true };

    case types.UPDATE_PROFILE_FAILURE:
      return { ...state, error: action.payload, loading_action: false };

    case types.GET_USER_COUNTRY:
      return { ...state };

    case types.ADD_META_COUNTRY_SUCCESS:
      return { ...state, metaCountry: "SUCCESS" };
    case types.GET_ALL_COUNTRY:
      return { ...state };
    case types.GET_ALL_COUNTRY_SUCCESS:
      return { ...state, allCountry: action.payload };
    default:
      return state;
  }
};

export default userReducer;
