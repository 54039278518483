import constants from "../../../constants"

function api() {
	const getCodeByUser = ( uid ) => {
		return fetch(constants.URL_API + "/users/getCodeLiveEvent",{
			method: "POST",
			headers: {'Content-Type': 'application/json',
			'Authorization': "Bearer " + constants.JWTtoken()},
			body: JSON.stringify({uid: uid})
		}).then((res) => res.json());
	};

	return {
		getCodeByUser,
	};
} 

export default api();