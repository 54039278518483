import React, { useEffect, useState } from "react";
import { Audio } from "react-loader-spinner";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import constants from "../../../constants";
import { closeLink, getMagicLink } from "../../../redux/stream/actions";
import VideoPlayer from "../../videoplayer/reactPlayer";
import "./_style.scss";
import useMobileOrientation from "../../../hook/useMobileOrientation";
import Boutton from "../../Boutton/Boutton";

function LiveMagic({
  loading,
  magic_data,
  current_live,
  catalogue,
  magic_link,
}) {
  const params = useParams();
  const dispatch = useDispatch();
  const { isMobile } = useMobileOrientation("(max-width: 911px)");
  const [orientation, setOrientation] = useState("portrait");

  const user = `user- ${new Date().getTime()}`;

  useEffect(() => {
    if (params.id) {
      dispatch(getMagicLink(params.id));
    }
  }, []);

  const streamEnded = () => {
    if (process.env.NODE_ENV === "development") console.log("Live fini");
  };
  const createNameChannel = (str = "hello") => {
    return str.replace(/\s+/g, "_").replace(/\//g, "");
  };

  const getOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      setOrientation("portrait");
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
      setOrientation("landscape");
    }
  };
  const updateOrientation = (event) => {
    getOrientation();
  };

  useEffect(() => {
    window.addEventListener("resize", updateOrientation);
    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  const onStart = () => {
    dispatch(closeLink({ token: magic_link.token }));
  };

  return (
    <>
      <div>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              alignItems: "center",
            }}
          >
            <Audio color="#fbfbfb" height={100} width={100} />
          </div>
        )}
        {!loading && (
          <>
            {magic_data ? (
              <>
                {current_live?.viewer_endpoint ? (
                  <>
                    {!isMobile ? (
                      <div style={{ display: "flex" }}>
                        <div className="frameVideo">
                          <VideoPlayer
                            src={current_live?.viewer_endpoint}
                            id="baPlayer"
                            autoplay={true}
                            muted={false}
                            controls={true}
                            width="100%"
                            height="100%"
                            isLive={true}
                            onStart={onStart}
                            onEnded={streamEnded}
                          />
                        </div>
                        <div className="containerChat">
                          <iframe
                            title="inlive-chat"
                            className="frameChat"
                            src={encodeURI(
                              `https://chat.inlive-stream.com/?groupname=${createNameChannel(
                                catalogue?.titre
                              )}&username=${user}&callback=${
                                constants.URL_API
                              }/stream/saveMessage`
                            )}
                          ></iframe>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          orientation === "portrait"
                            ? "framesMobilePortrait"
                            : "framesMobileLandscape"
                        }
                        style={{ display: "flex" }}
                      >
                        <div
                          className={
                            orientation === "portrait"
                              ? "frameVideoPortrait"
                              : "frameVideoLandscape"
                          }
                        >
                          <VideoPlayer
                            src={current_live?.viewer_endpoint}
                            id="baPlayer"
                            autoplay={true}
                            muted={false}
                            controls={true}
                            width="100%"
                            height="100%"
                            onStart={onStart}
                            isLive={true}
                            onEnded={streamEnded}
                          />
                        </div>
                        <div
                          className={
                            orientation === "portrait"
                              ? "containerChatPortrait"
                              : "containerChatLandscape"
                          }
                        >
                          <iframe
                            title="mobile-chat"
                            className={
                              orientation === "portrait"
                                ? "frameChatPortrait"
                                : "frameChatLandscape"
                            }
                            src={encodeURI(
                              `https://chat.inlive-stream.com/?groupname=${createNameChannel(
                                catalogue?.titre
                              )}&username=${user}&callback=${
                                constants.URL_API
                              }/stream/saveMessage`
                            )}
                          ></iframe>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100vh",
                        alignItems: "center",
                      }}
                    >
                      <div style={{}} className="accroche-empty">
                        Votre live n'a pas encore debuté
                        <Boutton
                          text={"Acceuil"}
                          action={() => {
                            window.location.href = constants.URL_CALLBACK;
                          }}
                          style={{ marginTop: 20 }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                  alignItems: "center",
                }}
              >
                <div style={{}} className="accroche-empty">
                  {magic_link?.statusCode === 429 ? "Ce lien a atteint le nombre maximum d'accès" : magic_link?.statusCode === 404 ? "Ce lien du live n'existe pas" : "Ce lien est invalide" }
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({ StreamReducer }) => ({
  magic_data: StreamReducer.magic_data,
  loading: StreamReducer.loading,
  current_live: StreamReducer.current_live,
  catalogue: StreamReducer.catalogue,
  magic_link: StreamReducer.magic_link,
});

export default connect(mapStateToProps)(LiveMagic);
