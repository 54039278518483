import { call, put, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import constants from "../../constants";
import { getRequest, getUnauthRequest, postRequest, putRequest } from '../../helpers/api';

function* getStreams() {
  try {
    let data = yield getRequest(`${constants.URL_API}/catalogues/getAll?type=LIVE`);
    if (data.ret) {
      yield put({ type: types.GET_STREAM_SUCCESS, payload: data?.data });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_STREAM_FAILURE, payload: error });
  }
}

function* getPurchasedStreams({payload}) {
  try {
    let data = yield postRequest(`${constants.URL_API}/stream/getStreamPurchases`, JSON.stringify(payload));
    if (data.ret) {
      yield put({ type: types.PURCHASE_STREAM_SUCCESS, payload: data?.data?.purchases });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: types.PURCHASE_STREAM_FAILURE, payload: error });
  }
}

function* getCurrentLive({ payload }) {
  try {
    let data = yield getRequest(`${constants.URL_API}/stream/signCookie?id=${payload}`);
    if (data.ret) {
      yield put({ type: types.CURRENT_LIVE_SUCCESS, payload: data?.data });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: types.CURRENT_LIVE_FAILURE, payload: error });
  }
}

function* verifyCode({ payload }) {
  try {
    let data = yield postRequest(`${constants.URL_API}/streamcode/validateCode`, JSON.stringify(payload));
    
    if (data.ret) {
      yield put({ type: types.VERIFY_CODE_SUCCESS });
    }else{
      yield put({ type: types.VERIFY_CODE_FAILURE });
    }
  } catch (err) {
    yield put({ type: types.VERIFY_CODE_FAILURE });
  }
}

function* getMagicLink({ payload }) {
  try {
    let data = yield getRequest(`${constants.URL_API}/magiclink/auth?link=${payload}`);

    if (data.ret) {
      yield put({ type: types.MAGIC_LINK_SUCCESS, payload: data?.data });
      yield call(getMagicData, {payload: data.data});
    }else{
      yield put({ type: types.MAGIC_LINK_FAILURE, payload: {statusCode: data?.statusCode} });
    }
  } catch (err) {
    yield put({ type: types.MAGIC_LINK_FAILURE });
  }
}

function* getMagicData({ payload }) {
  try {
    let data = yield getRequest(`${constants.URL_API}/magiclink/watch?link=${payload.link}&token=${payload.token}`);
    
    if (data.ret) {
      yield put({ type: types.MAGIC_STREAM_SUCCESS, payload: data?.data });
      yield call(getCurrentLive, {payload: data.data?.magiclink?.stream_id});
    }else{
      yield put({ type: types.MAGIC_STREAM_FAILURE });
    }
  } catch (err) {
    yield put({ type: types.MAGIC_STREAM_FAILURE });
  }
}


function* closeLink({ payload }) {
  try {
    let data = yield putRequest(`${constants.URL_API}/magiclink/close`, JSON.stringify(payload));
    
    if (data.ret) {
      yield put({ type: types.CLOSE_LINK_SUCCESS });
    }else{
      yield put({ type: types.CLOSE_LINK_FAILURE });
    }
  } catch (err) {
    yield put({ type: types.CLOSE_LINK_FAILURE });
  }
}



export default function* StreamSaga() {
  yield takeLatest(types.GET_STREAM_REQUEST, getStreams);
  yield takeLatest(types.CURRENT_LIVE_REQUEST, getCurrentLive);
  yield takeLatest(types.VERIFY_CODE_REQUEST, verifyCode);
  yield takeLatest(types.PURCHASE_STREAM_REQUEST, getPurchasedStreams);
  yield takeLatest(types.MAGIC_LINK_REQUEST, getMagicLink);
  yield takeLatest(types.MAGIC_STREAM_REQUEST, getMagicData);
  yield takeLatest(types.CLOSE_LINK_REQUEST, closeLink);
}