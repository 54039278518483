import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  createStripeIntent,
  treatStripeSuccess,
} from "../../../services/payment/stripe.service";

import { refreshPurchase } from "../../../redux/product/actions";

const style = {
  base: { color: "#FFF" },
  invalid: { color: " #E33939" },
};

const convertPriceToStg = (stg) => {
  return stg.toString().replace(/\./g, ",") + "€";
};

const StripeCheckoutForm = ({
  product,
  paymentType,
  setIsSuccess,
  setShowLoader,
}) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [cardName, setCardName] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const meUser = useSelector((state) => state.userReducer.userInfo);

  const handleSubmit = async (event) => {
    if (process.env.NODE_ENV === "development") console.log("Handle Submit");
    setError(null);

    event.preventDefault();
    const cardNumberElement = elements.getElement(CardNumberElement);
    setProcessing(true);
    setShowLoader(true);

    if (isEmpty(cardName)) {
      setProcessing(false);
      setShowLoader(false);
      setError("Veuillez renseigner le nom associé à la carte de crédit");
      return;
    }
    const data = {
      productId: product.id,
      productDescription: product.titre + " - " + paymentType,
      userId: meUser.uid,
      userEmail: meUser.data.email,
      type: paymentType,
    };

    createStripeIntent(data)
      .then((res) => {
        confirmPayment(data, res.data, cardNumberElement);
        //console.log(res);
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") console.log("Err  : ", err);
        setError("Une erreur est survenue lors du paiement");
        setProcessing(false);
        setShowLoader(false);
      });
  };

  const confirmPayment = (data, resData, cardNumberElement) => {
    stripe
      .confirmCardPayment(resData.clientSecret, {
        payment_method: { card: cardNumberElement },
      })
      .then((res) => {
        if (res.error) {
          setError("Echec du paiement: " + res.error.message);
          setProcessing(false);
          setShowLoader(false);
        } else {
          treatStripeSuccess({
            productId: data.productId,
            userId: data.userId,
            userEmail: data.userEmail,
            type: paymentType,
          })
            .then((successRes) => {
              setError(null);
              setProcessing(false);
              setSuccessMessage("Paiement effectué avec succès");
              setIsSuccess(true);
              dispatch(refreshPurchase());
            })
            .catch((err) => {
              setError("Une erreur est survenue");
              setProcessing(false);
            })
            .finally(() => setShowLoader(false));
        }
      });
  };

  const trackPaymentEvent = (isSuccessFull, paymentId = "") => {};

  const handleChange = async (event) => {
    if (process.env.NODE_ENV === "development") console.log("HandleChange");
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  return (
    <form onSubmit={handleSubmit} className="popup-content-payment-form">
      <div className="popup-content-payment-form-input">
        <label htmlFor="name">Nom du titulaire de la carte</label>
        <input
          type="text"
          name="name"
          id="name"
          className="--no-active"
          onChange={(e) => setCardName(e.target.value.trim())}
        />
      </div>
      <div className="popup-content-payment-form-input">
        <label>Informations de la carte</label>
        <CardNumberElement
          className="input --first --no-active"
          onChange={handleChange}
          options={{ style }}
        />
        <div className="popup-content-payment-form-input --splitted">
          <CardExpiryElement
            className="input splitted-half --no-active"
            options={{ style }}
            onChange={handleChange}
          />
          <CardCvcElement
            className="input splitted-half --no-active"
            options={{ style }}
            onChange={handleChange}
          />
        </div>
      </div>
      {error && <div className="popup-content-error">{error}</div>}
      {successMessage && (
        <div className="popup-content-success">{successMessage}</div>
      )}
      <button
        className={
          "popup-content-submit" +
          (!stripe || processing || disabled || successMessage
            ? " --disabled"
            : "")
        }
        type="submit"
      >
        Payer {/* {product[paymentType]}  */}
        {paymentType === "loc" ? product?.price_rental : product?.price} &euro;
      </button>
    </form>
  );
};

export default StripeCheckoutForm;
