import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Tooltip } from "@mui/material";
import hd from "../../assets/icon/high-definition.svg";
import VideoPlayer from "../videoplayer/player";
import JwPlayer from "../videoplayer/jwplayers";
import ReactPlayer from "react-player";
import parse from "html-react-parser";
import { openPopup } from "../../redux/popup/actions";
import { fetchEncodedProduct } from "../../redux/product/actions";
import constants, { identity, popupNames } from "../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import decodeURIComponentSafely from "../../helpers/decodeUri";
import { removeTags } from "../../helpers/helpers";
import moment from "moment";
import ModalCountry from "../ModalCountry";
import VideoPlayerJwPlayer from "../videoplayer/jwplayer-modal";

var lesDatas = null;

const ModalInfo = ({
  showInfoModal,
  setShowInfoModal,
  myRulesModal,
  dataModal,
  dataPurch,
}) => {
  const history = useHistory();
  const [styleImg, setStyleImg] = useState({});

  const [playerMuted, setPlayerMuted] = useState(false);
  const [playerPlaying, setPlayerPlaying] = useState(true);

  const dispatch = useDispatch();
  const [showBA, setShowBA] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [srcFilm, setSrcFilm] = useState("");

  const meUser = useSelector((state) => state.userReducer.userInfo);
  const addMetaSuccess = useSelector((state) => state.userReducer.metaCountry);

  const isAuthenticated = meUser.uid ? true : false;

  const [selectCountry, setSelectCountry] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  try {
    if (dataModal && dataModal !== undefined) {
      lesDatas = JSON.parse(decodeURIComponentSafely(escape(atob(dataModal))));
    }
  } catch (err) {
    if (process.env.NODE_ENV === "development")
      console.log("Erreur datas : ", err);
  }

  function closeModal() {
    setStyleImg();
    document.documentElement.style.overflow = "initial";
    setShowInfoModal(false);
  }

  const afterCloseModal = () => {
    setPlayerMuted(false);
    setPlayerPlaying(true);
  };

  const authenticat = () => {
    if (constants.IDENTITY_ACCESS) {
      identity.Login();
    } else {
      dispatch(openPopup(popupNames.login));
    }
  };

  useEffect(() => {
    if (addMetaSuccess === "SUCCESS") {
      setSelectCountry(false);
      proceedToPayment(paymentType);
    }
  }, [addMetaSuccess]);

  useEffect(
    (e) => {
      if (showInfoModal) {
        document.documentElement.style.overflow = "hidden";
        if (!lesDatas.video_id) setStyleImg({});
      }
      // CHeck s'il y a la bande annonce, si OK on la lance
      if (lesDatas)
        if (lesDatas.ba_id) {
          setShowBA(true);
        }
    },
    [showInfoModal]
  );

  const videoEnded = () => {
    setStyleImg();
    setShowBA(false);
  };
  const videoPlay = () => {
    setStyleImg({ visibility: "hidden" });
  };

  const customStyles = {
    content: {
      width: "60%",
      marginLeft: "20%",
      background: "rgb(23, 22, 22)",
      border: "none",
      padding: "none",
    },
  };
  const customStylesVideos = {
    content: {
      position: "unset",
      top: "100%",
      left: "100%",
      transform: "translate(-100%, -100%)",
      width: "100%",
      height: "100%",
      background: "rgba(0,0,0,0.7",
      border: "none",
      padding: "none",
    },
  };

  const btnPaymentClick = (type) => {
    // const metaCountry = localStorage.getItem("metaCountry");
    setPaymentType(type);
    // if (metaCountry) {
    //   console.log(metaCountry);
    proceedToPayment(type);
    // } else {
    //   setSelectCountry(true);

    // }
  };

  const proceedToPayment = (type) => {
    setPlayerPlaying(false);
    dispatch(fetchEncodedProduct(dataModal));
    dispatch(openPopup(popupNames.payment, type));
  };

  const closeModalFilm = () => {
    setIsOpen(false);
  };
  const openModalFilm = () => {
    setIsOpen(true);
  };
  const btnPlay = () => {
    if (dataPurch) {
      setSrcFilm(atob(dataPurch));
      closeModal();
      openModalFilm();
    } else {
      btnPaymentClick("achat");
    }
  };

  const goAudioBook = () => {
    if (dataPurch) {
      history.push(atob(dataPurch));
    }
  };

  const isSafari = () => {
    return (
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1
    );
  };

  return (
    <>
      <Modal
        closeTimeoutMS={500}
        isOpen={modalIsOpen}
        onRequestClose={closeModalFilm}
        style={customStylesVideos}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div
          style={{
            position: "absolute",
            right: 10,
            top: 5,
            zIndex: 1500,
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={solid("x")} onClick={closeModalFilm} />
        </div>
        {isSafari() ? (
          <JwPlayer src={srcFilm} />
        ) : (
          <VideoPlayer src={srcFilm} />
        )}
      </Modal>

      {lesDatas !== null && !selectCountry && (
        <Modal
          closeTimeoutMS={500}
          isOpen={showInfoModal}
          onRequestClose={closeModal}
          onAfterClose={afterCloseModal}
          style={customStyles}
          //        contentLabel="Example Modal"
          overlayClassName="Overlay"
        >
          <div
            style={{
              position: "absolute",
              right: 20,
              top: 10,
              zIndex: 20500,
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon icon={solid("x")} onClick={closeModal} />
          </div>
          <div className="info-blkImg" style={{ width: "100%" }}>
            {!showBA && (
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                className="info-img"
                width={"100%"}
                src={"https://vcdn.inlive-stream.com/img/" + lesDatas.img}
                alt={lesDatas.titre}
                style={{ height: 450, width: "100%" }}
              />
            )}
            {showBA && (
              <div>
                {isSafari() && (
                  <VideoPlayerJwPlayer
                    src={lesDatas.ba_id}
                    controls={true}
                    // height="50%"
                    playerMuted={playerMuted}
                    playerPlaying={playerPlaying}
                  />
                )}
                {!isSafari() && (
                  <VideoPlayer
                    src={lesDatas.ba_id}
                    controls={true}
                    height="450px"
                    playerMuted={playerMuted}
                    playerPlaying={playerPlaying}
                  />
                )}
              </div>
            )}
          </div>

          <div className="row ligInfo">
            {lesDatas && dataPurch.length === 0 && (
              <div className="infoBuy">
                {lesDatas?.price_rental && (
                  <button
                    className="btnLouer"
                    onClick={(e) => {
                      e.stopPropagation();
                      isAuthenticated ? btnPaymentClick("loc") : authenticat();
                    }}
                  >
                    LOUER {lesDatas?.price_rental} &euro;
                  </button>
                )}
                {lesDatas?.price && (
                  <button
                    className="btnAcheter"
                    onClick={(e) => {
                      e.stopPropagation();
                      isAuthenticated
                        ? btnPaymentClick("achat")
                        : authenticat();
                    }}
                  >
                    ACHETER {lesDatas?.price} &euro;
                  </button>
                )}
              </div>
            )}
            {lesDatas && dataPurch.length > 0 && (
              <div className="" style={{ marginBottom: 20 }}>
                <div className="btnTransac">
                  {dataPurch?.length > 0 && (
                    <span
                      className="btnLouer"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (lesDatas.type === "VIDEO") btnPlay();
                        if (lesDatas.type === "BOOK") goAudioBook();
                      }}
                    >
                      {lesDatas.type === "BOOK" ? (
                        <span className="btnPlayHA">Visionner </span>
                      ) : (
                        <span className="btnPlayHA">Lecture </span>
                      )}
                      <FontAwesomeIcon icon={solid("play")} />
                    </span>
                  )}
                </div>
              </div>
            )}
            <div className="col-md-8 colGauche">
              <div className="d-flex">
                <div className="">
                  <div className="titre">{parse(lesDatas?.titre)}</div>
                  <div>
                    <span style={{ fontSize: 14 }}>
                      {lesDatas?.createdAt &&
                        moment(lesDatas.createdAt).format("YYYY")}
                    </span>
                  </div>
                </div>

                {lesDatas.isHD !== "0" && (
                  <div className="quality">
                    <img src={hd} alt="" />
                  </div>
                )}
              </div>

              <div className="desc">{removeTags(parse(lesDatas?.desc))}</div>
              <div className="badges">
                {lesDatas?.categorie &&
                  JSON.parse(parse(lesDatas.categorie))?.map((cat, index) => {
                    return (
                      <span key={index} className="badge">
                        {cat}
                      </span>
                    );
                  })}
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </Modal>
      )}
      {/* {selectCountry && (
        <ModalCountry onClose={() => setSelectCountry(false)} />
      )} */}
    </>
  );
};

export default ModalInfo;
