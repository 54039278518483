import "./App.css";
import Router from "./components";
import store from "./redux/setup/store";
import { Provider } from "react-redux";
import "./styles/index.scss";
import packageJson from "../package.json";
import { getBuildDate } from "./helpers/getBuildDate";
import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  
  return (
    <>
      <Provider store={store}>
        <Router />
      </Provider>
    </>
  );
}

export default App;
