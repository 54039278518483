import React from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";

import { IconButton } from "@material-ui/core";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import "./_reactPlayer.scss";

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: props.src ? props.src : null, //'https://vimeo.com/' + props.VidID,
      pip: false,
      soundControl: false,
      playing: props.autoplay, //props.playing,
      controls: props.controls,
      light: false,
      volume: 1.0,
      muted: props.muted,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      key: 0,
      VideoPlayerID: props.VideoPlayerID
        ? props.VideoPlayerID
        : "defaultVideoID",
      playerReady: false,
      isFullscreen: false,
      showModal: false,
    };
    this.progressTimeInterval = 10;
    //showModal: false,
    this.timeTotal = null;
    this.movieId = props.movieId;

    this.isTrailer = props.isTrailer;
  }

  fFullScreenDetect = (el, obj) => {
    var element = document.fullscreenElement;
    const bFullscreen = !!element;
    this.setState({ isFullscreen: bFullscreen });
  };

  onReady = () => {
    this.setState({ playerReady: true, soundControl: this.props.soundControl });
  };

  onDuration = (duration) => {
    this.timeTotal = duration;
  };

  onStart = () => {
    this.props.onStart() || console.log("");
  };

  onPlay = () => {
    this.setState({ playing: true, showModal: false });
  };

  onPause = () => {
    this.setState({ playing: false, showModal: true });
  };

  onProgress = (progress) => {
    this.props.onProgress() || console.log("");
  };

  onEnded = () => {
    console.log('the stream has ended...');
    this.props.onEnded() ||
      this.state.overlay.btnExitOnClick(null, this.player);
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted = false,
      loop,
      playbackRate,
      pip,
      key,
      VideoPlayerID,
      soundControl,
      progressTimeInterval,
    } = this.state;
    return (
      <>
        <div className="player-wrapper">
          {soundControl && (
            <IconButton
              style={{
                position: "absolute",
                right: "5vw",
                top: "3vh",
                zIndex: 100,
                color: "#FFF",
              }}
              aria-label="soundControl"
              onClick={() => this.setState({ muted: !muted })}
            >
              {muted ? <VolumeOffIcon /> : <VolumeDownIcon />}
            </IconButton>
          )}
          <ReactPlayer
            ref={this.ref}
            key={key}
            id={VideoPlayerID}
            className="react-player"
            width="100vw"
            height="100vh"
            url={url}
            pip={pip}
            playsinline={true}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onReady={this.onReady}
            onStart={this.onStart}
            onPlay={this.onPlay}
            onEnablePIP={this.onEnablePIP}
            onDisablePIP={this.onDisablePIP}
            onPause={this.onPause}
            onEnded={this.onEnded}
            onError={(e) => console.log("onError", e)}
            progressInterval={progressTimeInterval}
            onDuration={this.onDuration}
            config={{
              file: {
                forceHLS: true,
                hlsOptions: {
                  xhrSetup: (xhr, url) => {
                    xhr.withCredentials = true;
                  },
                },
              },
            }}
          ></ReactPlayer>
        </div>
        {
          this.state.showModal && this.props.overlay && (
            <div
              className="player-overlay"
              onClick={(e) => {
                this.setState({ playing: true });
              }}
            >
              <div className="player-voerlay-content">
                <button
                  className="player-overlay-content-btn"
                  onClick={(e) =>
                    this.props.overlay.btnExitOnClick(e, this.player)
                  }
                >
                  {this.props.overlay.btnExit}
                </button>
                <h1 className="player-overlay-content-title">
                  {this.props.overlay.title}
                </h1>
                <p className="player-overlay-content-desc">
                  {this.props.overlay.para}
                </p>
              </div>
            </div>
          )
        }
      </>
    );
  }
}

const stateToProps = (state) => ({
  userInfo: state.userReducer.userInfo,
});

export default connect(stateToProps)(VideoPlayer);
