export const types = {
  //load browser front
  LIST_BROWSERFRONT_REQUEST: "LIST_BROWSERFRONT_REQUEST",
  LIST_BROWSERFRONT_SUCCESS: "LIST_BROWSERFRONT_SUCCESS",
  LIST_BROWSERFRONT_FAILURE: "LIST_BROWSERFRONT_FAILURE",

  GET_CATEGORY_REQUEST: "GET_CATEGORY_REQUEST",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",
};
