import "./_reactPlayer.scss";
import React, { useRef, useEffect, useState } from "react";
import shaka from "shaka-player";
import img from "../../assets/icon/logo-stream.svg";
import constants from "../../constants";
import { useSelector } from "react-redux";

import muxjs from "mux.js";

const ShakaPlayerComponent = ({
  src,
  controls = true,
  height = "100vh",
  playerMuted = false,
  playerPlaying = true,
}) => {
  const videoElementRef = useRef(null);
  const urlReadVod = `${constants.URL_API}/vods/readVod`;
  const meUser = useSelector( state => state.userReducer.userInfo);
  const token = constants.JWTtoken();
  const videoName = src;
  let player;
  const body = {
    mediaId: videoName,
    email:meUser?.data?.email,
    uuid:meUser?.uid,
    firebaseUid:meUser?.firebaseid
  };
  const [playError, setPlayError] = useState(null);

  useEffect(() => {
    fetch(urlReadVod, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        shaka.polyfill.installAll();
        if (shaka.Player.isBrowserSupported()) {
          if (isSafari()) {
            getFairplayCertificate(response.data);
          } else {
            loadPlayer("", response.data);
          }
        } else {
          console.error(
            "This browser does not have the minimum set of APIs needed for shaka!"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setPlayError(true);
      });
  }, []);

  useEffect(() => {
    fetch(`${constants.URL_API}/vods/signCookie?mediaId=${videoName}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((resp) => {});
  }, []);

  useEffect(() => {
    if (playerPlaying) {
      playVideo();
    } else {
      pauseVideo();
    }
  }, [playerPlaying]);

  const getFairplayCertificate = (tk) => {
    let certRequest = new XMLHttpRequest();
    certRequest.responseType = "arraybuffer";
    certRequest.open("GET", tk.data.fairplay_cert_url, true); // Replace with your Fairplay certificate URL
    certRequest.onload = function (event) {
      if (event.target.status === 200) {
        loadPlayer(new Uint8Array(event.target.response), tk);
      } else {
        let error = new Error(
          "HTTP status: " +
            event.target.status +
            " when getting Fairplay Certificate."
        );
        onError(error);
      }
    };
    certRequest.send();
  };

  const loadPlayer = (fairplayCertificate, tk) => {
    let video = videoElementRef.current;
    player = new shaka.Player(video);
    player.addEventListener("error", onErrorEvent);

    // configure the DRM license servers
    let playerConfig = isSafari()
      ? getSafariPlayerConfig(fairplayCertificate, tk)
      : getNonSafariPlayerConfig();
    player.configure(playerConfig);

    // Something special is needed for the widevine license request.
    player
      .getNetworkingEngine()
      .registerRequestFilter(function (type, request) {
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          request.headers["X-VUDRM-TOKEN"] = tk.data.token;
        } else {
          request.allowCrossSiteCredentials = true;
        }

        // custom fairplay license request body required
        if (player.drmInfo()?.keySystem === "com.apple.fps.1_0") {
          request.headers["Content-Type"] = "ArrayBuffer";
          request.uris = [tk.data.fairplay_sever_url.replace("skd", "https")]; // Replace with your Fairplay license server URL
        }
      });

    // load the mpeg-dash or HLS stream into the shaka player
    player
      .load(isSafari() ? tk.data.media.hlsPath : tk.data.media.dashPath) // Replace with your HLS and MPEG-DASH stream URLs
      .then(function () {
        video.play();
      })
      .catch(onError);
  };

  const isSafari = () => {
    return (
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1
    );
  };

  const getNonSafariPlayerConfig = () => {
    return {
      drm: {
        servers: {
          "com.widevine.alpha": "https://widevine-license.vudrm.tech/proxy",
          "com.microsoft.playready":
            "https://playready-license.vudrm.tech/rightsmanager.asmx",
        },
        advanced: {
          "com.widevine.alpha": {
            videoRobustness: "SW_SECURE_CRYPTO",
            audioRobustness: "SW_SECURE_CRYPTO",
          },
        },
      },
    };
  };

  const getSafariPlayerConfig = (fairplayCertificate, val) => {
    return {
      drm: {
        servers: {
          "com.apple.fps.1_0": val.data.fairplay_sever_url, // Replace with your Fairplay license server URL
        },
        advanced: {
          "com.apple.fps.1_0": {
            serverCertificate: fairplayCertificate,
          },
        },
        initDataTransform: function (initData, initDataType) {
          if (initDataType === "skd") {
            let fairPlay = shaka.util.StringUtils.fromBytesAutoDetect(initData);
            const contentId = fairPlay.split("/").pop();
            const certificate = player.drmInfo().serverCertificate;
            return shaka.util.FairPlayUtils.initDataTransform(
              initData,
              contentId,
              certificate
            );
          } else {
            return initData;
          }
        },
      },
    };
  };

  function onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    onError(event.detail);
  }

  function onError(error) {
    if (process.env.NODE_ENV === "development")
      console.error("Error code", error.code, "object", error);
  }

  const playVideo = () => {
    videoElementRef.current.play();
  };

  const pauseVideo = () => {
    videoElementRef.current.pause();
  };

  return (
    <div style={{ height: height, width: '100%' }}>
      <>
        {playError ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <div style={{}} className="play-accroche-empty">
              <img alt="" src={img} height={100} width={100} />
              Impossible de jouer la video
            </div>
          </div>
        ) : (
          <video
            playsInline
            controls={controls}
            ref={videoElementRef}
            // width={"100%"}
            // height={"99%"}
            crossOrigin="use-credentials"
            muted={playerMuted}
            style={{objectFit: 'cover', height: '100%', width: '100%'}}
          />
        )}
      </>
    </div>
  );
};

export default ShakaPlayerComponent;
