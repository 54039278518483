import { put, takeLatest } from "redux-saga/effects";
import { types } from "./types";
import constants from "../../constants";
import { getRequest, getUnauthRequest } from "../../helpers/api";

/**
 * @description list browserFront.
 */

const ressourceName = "catalogues";

function* getAll() {
  try {
    let dataCategory = yield getUnauthRequest(
      `${constants.URL_API}/browsefront/getAll?type=categorie`
    );

    let categorizedData = [];
    if (dataCategory.ret) {
      const Categories = dataCategory.data
        .slice()
        .sort((a, b) => parseInt(a.posi) - parseInt(b.posi));
      for (const element of Categories) {
        let category = element;
        let data = yield getUnauthRequest(
          `${constants.URL_API}/${ressourceName}/publicList?categorie=${category.name}&limit=100`
        );

        if (data?.ret) {
          if (data?.data.length > 0) {
            categorizedData.push({
              key: category.name,
              values: data.data,
            });
          }
        } else {
          yield put({ type: types.LIST_BROWSERFRONT_FAILURE, payload: data });
        }
      }
      yield put({
        type: types.LIST_BROWSERFRONT_SUCCESS,
        payload: { categorie: dataCategory.data, results: categorizedData },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: types.LIST_BROWSERFRONT_FAILURE, payload: error });
  }
}

function* getOneCategory({ payload }) {
  try {
    let data = yield getUnauthRequest(
      `${constants.URL_API}/${ressourceName}/publicList?categorie=${payload}&limit=100`
    );
    if (data?.ret) {
      yield put({ type: types.GET_CATEGORY_SUCCESS, payload: data.data });
    } else {
      yield put({ type: types.GET_CATEGORY_FAILURE, payload: data });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_CATEGORY_FAILURE, payload: error });
  }
}

export default function* BrowserFrontSaga() {
  yield takeLatest(types.LIST_BROWSERFRONT_REQUEST, getAll);
  yield takeLatest(types.GET_CATEGORY_REQUEST, getOneCategory);
}
