import React, { useEffect, useState } from 'react'
import ReserveCards from '../../cards/ReserveCards'
import { useHistory } from "react-router-dom";
import useMobileOrientation from '../../../hook/useMobileOrientation';
import ModalValid from '../../modalValid';
import constants from "../../../constants";
// import RICIBs from 'react-individual-character-input-boxes';
import RICIBs from '../../myInput/ReactIndividualCharacterInputBoxes';
import picto_tickets from "../../../assets/img/fond_texte.png";
import picto_warning from '../../../assets/img/fond_avertissement.png';
import imgClose from "../../../assets/img/imgclose.png";
import '../liveEvent/_style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getBillet, verifyCode } from '../../../redux/stream/actions';
import Slider from "react-slick";

export default function Index() {
  const history = useHistory();
  const { isMobile } = useMobileOrientation('(max-width: 670px)');
  const dispatch = useDispatch();
  const stream = history.location.state.data;
  // const streamId = history.location.state.data;
  // console.log("streamId",streamId)
  const token = constants.JWTtoken();
  const [outputString, setOutputString] = useState(false);
  const [datastream, setDataStream] = useState();
  const [packs, setPacks] = useState([]);
  const [codeFalse, setCodeFalse] = useState(false);
  const [UpdatePortal, setUpdatePortal] = useState(false)
  const [showAccesscode, setShowAccesscode] = useState(false);

  const meUser = useSelector((state) => state.userReducer.userInfo);
  const status = useSelector((state) => state.StreamReducer.status);

  // console.log(stream, 'kk')

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: 60,
    arrows: false,
    initialSlide:0
  };

  const handleOutputString = (str) => {
    setCodeFalse(false);
		setClassInput(true);
		setOutputString(str);
  }

  const setClassInput = (status) => {
    for (let n = 1; n <= 10; n++) {
      var element = document.getElementById("el" + n);
      if (status) {
        element.classList.remove("nErrorInputBox");
      } else {
        element.classList.add("nErrorInputBox");
      }
    }
  }

  const hideUpdatePortal = (e) => {
    setUpdatePortal(false)
  }
  const showUpdatePortal = (e) => {
    setUpdatePortal(true)
  }

  const checkCode = async () => {
    if(outputString.length<10){
      setCodeFalse(true)
      return;
    }
		try{
			const mCode = outputString.substring(0,6) + "-" + outputString.substring(6,outputString.length);
			if(process.env.NODE_ENV==="development") console.log("mCode : ", mCode);
			let postData = {
        code: mCode,
        uid: meUser.uid,
        email: meUser.data.email,
        stream_id: stream.id
      }
      dispatch(verifyCode(postData));
		}catch(error){
      setCodeFalse(true)
			if(process.env.NODE_ENV==="development") console.log("error : ", error);
		}
	}

  useEffect(() => {
    fetch(`${constants.URL_API}/catalogues/getOne?id=${stream.id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((resp) => {
      if (!resp.ok) {
        throw new Error('Network response was not ok');
      }
      return resp.json();
    })
    .then((data) => {
      setDataStream(data.data)
      const packsData = data.data.packs;
        if (Array.isArray(packsData)) {
          setPacks(packsData);
        } else if (packsData && typeof packsData === 'object') {
          setPacks([packsData]);
        }
    })
    .catch((error) => {
      console.error("Fetch error:", error);
    });
  }, []);
  

  useEffect(()=> {
    
    if(status==='SUCCESS'){
      showUpdatePortal(true);
    }
    if(status==='FAILED'){
      setCodeFalse(true);
    }
  }, [status])

  const CodeModal = () => {
    return (
      <>
        {showAccesscode && (
          <>
            {(isMobile) ? (
              !UpdatePortal ? (
                <div className="popupContainer">
                  <div className="faussePopup">
                    <div className="imageWrapper-popup-mobile">
                      <img alt='' className="static-img-popup-mobile" src="https://cimage.inlive-stream.com/img.php?src=https://vcdn.inlive-stream.com/img/HEADER_MOBILE_STREAM.png&w=1280" />
                      <div className="masque-popup-mobile"></div>
                    </div>
                  </div>
                  <div className="ctnCongratMobile">
                    <div className="ctnPopupCongrat" style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}>

                      <div className="titreShowCase">Code d'accès Showcase</div>
                      <div className="txtShowCase">Afin de vérifier votre accès pour le Showcase de Jenifer, munissez-vous de votre code d’inscription inscrit sur votre billet</div>
                      <div className="saisieCode">
                      <RICIBs
                        amount={10}
                        handleOutputString={handleOutputString}
                        inputRegExp='^[a-zA-Z0-9_.-]*$'
                        inputProps={[
                          { className: "mInputBox", id: "el1" },
                          { className: "mInputBox", id: "el2" },
                          { className: "mInputBox", id: "el3" },
                          { className: "mInputBox", id: "el4" },
                          { className: "mInputBox", id: "el5" },
                          { className: "mInputBox", id: "el6" },
                          { className: "mInputBox", id: "el7" },
                          { className: "mInputBox", id: "el8" },
                          { className: "mInputBox", id: "el9" },
                          { className: "mInputBox", id: "el10" },
                        ]}
                        key={10}
                        value={outputString}
                      />
                        <div className="ErrorMessage">
                          {codeFalse &&
                            <>Code d'accès invalide</>
                          }
                        </div>
                      </div>

                      <div className="zoneBtn-mobile">
                        <button
                          className="btnLouer-mobile"
                          onClick={checkCode}
                        >
                          Valider
                        </button>
                      </div>

                    </div>
                  </div>
                </div>) : <ModalValid onClose={hideUpdatePortal} mobile="true" live={stream}/>
            ) : (
              !UpdatePortal ?
                <div className="popupContainer">
                  <div id="popupCode" className="revealPopupCode">
                    <div className="popupClose">
                      <img alt='' src={imgClose} onClick={() => { setShowAccesscode(false) }} />
                    </div>
                    <div className="popupHeaderContainer">
                      <img alt='' className="imgTicket" src={picto_tickets} />
                    </div>
                    <div className="zoneSaisie">

                      <RICIBs
                        amount={10}
                        handleOutputString={handleOutputString}
                        inputRegExp='^[a-zA-Z0-9_.-]*$'
                        inputProps={[
                          { className: "mInputBox", id: "el1" },
                          { className: "mInputBox", id: "el2" },
                          { className: "mInputBox", id: "el3" },
                          { className: "mInputBox", id: "el4" },
                          { className: "mInputBox", id: "el5" },
                          { className: "mInputBox", id: "el6" },
                          { className: "mInputBox", id: "el7" },
                          { className: "mInputBox", id: "el8" },
                          { className: "mInputBox", id: "el9" },
                          { className: "mInputBox", id: "el10" },
                        ]}
                        key={10}
                        value={outputString}
                      />
                      <div className="ErrorMessage">
                        {codeFalse &&
                          <>Code d'accès invalide</>
                        }
                      </div>
                    </div>

                    <div className="zoneBtn">
                      <button
                        className="btnLouer"
                        onClick={checkCode}
                      >
                        Valider
                      </button>

                    </div>

                  </div>
                </div> : <ModalValid onClose={hideUpdatePortal} live={stream}/>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <div style={{
      background: `url(${history.location.state.image})`,
      backgroundSize: "cover", minHeight: "100vh", width: "100%", overflow: "hidden"
    }}>
      <div style={{ 
         width: "fit-content",
         maxWidth: 780, minHeight: "85vh", height: 'auto',
         minWidth: 350,
        backgroundColor: "#1A1A1A", marginTop: '10vh',
        backgroundPosition: "center center",
        backgroundSize: "cover",
        opacity: 1,
        transition: "opacity 0.4s cubic-bezier(0.665, 0.235, 0.265, 0.8) 0s",
        padding: "40px 20px"
      }}>
        <div style={{paddingTop: 20}}>
          <h1 style={{ fontSize: '45px' }}>Billetterie</h1>
          <div style={{ height: "6px", width: "100px", backgroundColor: "white", borderRadius: "20px" }}>
          </div>
        </div>
        <div style={{ marginTop: "60px", width: "70%", marginBottom: "30px" }}>
          <div dangerouslySetInnerHTML={{__html: stream.desc}} />
        </div>
        {isMobile ? 
        <Slider {...settings}>
        {packs?.length > 0 &&  packs?.map((pack) => (
        <ReserveCards
        key={pack.id}
        packId={pack.id}
        prix={pack.price}
        type={pack.type}
        categorie={pack.name}
        chat_enabled={pack.chat_enabled}
        chat_description={pack.chat_description}
        prebackstage_enabled={pack.prebackstage_enabled}
        prebackstage_description={pack.prebackstage_description}
        postbackstage_enabled={pack.postbackstage_enabled}
        postbackstage_description={pack.postbackstage_description}
        merchandising_enabled={pack.merchandising_enabled}
        merchandising_description={pack.merchandising_description}
        stream={datastream}
        />
      ))}
      </Slider>
        // </div>
      :
      <div style={{ width:'auto', display: 'flex', justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap'}}>
      {packs?.length > 0 && packs?.map((pack) => (
        <ReserveCards
        key={pack.id}
        packId={pack.id}
        prix={pack.price}
        type={pack.type}
        categorie={pack.name}
        chat_enabled={pack.chat_enabled}
        chat_description={pack.chat_description}
        prebackstage_enabled={pack.prebackstage_enabled}
        prebackstage_description={pack.prebackstage_description}
        postbackstage_enabled={pack.postbackstage_enabled}
        postbackstage_description={pack.postbackstage_description}
        merchandising_enabled={pack.merchandising_enabled}
        merchandising_description={pack.merchandising_description}
        stream={datastream}
        />
      ))}
        </div>
      }
        <span style={{cursor: 'pointer'}} onClick={()=> setShowAccesscode(true)}>J'ai un code d'acces</span>
      </div>
      {CodeModal()}
    </div>
  )
}
