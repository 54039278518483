import React from "react";
import { createTheme } from "@mui/material";
import {ThemeProvider} from "@mui/material";
import { Link } from 'react-router-dom';

import "./_styles.scss";

const materialTheme = createTheme({
	palette: {
			primary: {
					light: '#f133ff',
					main: '#ec00ff',
					dark: '#b500cb'
			}
	}
});


const styles = {
  table: {
    border: '1px',
    borderColor: 'white',
    borderStyle: 'solid',
    padding: '0.6rem'
  },
  li: {
    lineHeight: 1.2,
    marginLeft: '1rem'
  },
  h2: {
    fontSize: '1.4rem',
    textAlign: 'center'
  },
  h4: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  h5: {
    fontWeight: 'bold',
    marginLeft: '3rem'
  },
  h6: {
    fontStyle: 'italic',
    textDecoration: 'underline'
  },
  bold: {
    fontWeight: 'bold'
  },
  em: {
    display: 'block',
    fontStyle: 'italic'
  },
  strong: {
    display: 'block',
    fontWeight: 'bold'
  },
  indented: {
    marginLeft: '3rem'
  },
  flex: {
    display: 'flex',
    gap: '1rem'
  },
  a: {
    color: '#ec00ff',
  }
};

const CGV = () => {
	return (
		<div className="margeHaute">
		<div className="profile">
		<ThemeProvider theme={materialTheme}>
			<>
      <h2 style={ styles.h2 } >
        CONDITIONS GENERALES
      </h2>
      <br />
      <br />
      <h4 style={ styles.h4 } >
        1. Qui sommes-nous ?
      </h4>
      <br />
      <br />
      <p>Nous sommes Inlive With, SAS opérant sous la marque <span style={ styles.bold }>« INLIVE WITH »</span> immatriculée au RCS de Paris
        sous le n° 852 198 555, dont le siège social est situé 5, rue Vernet, 75008 Paris, France (<span style={ styles.bold }> « INLIVE WITH » </span>
        ou <span style={ styles.bold }> « nous »</span> ).
      </p>
      <br />
      <p>Vous pouvez nous contacter aux coordonnées suivantes :</p>
      <ul>
        <li style={ styles.li } >
          - adresse e-mail qui suit : contact@inlivewith.com,
        </li>
        <li style={ styles.li } >
          - numéro de téléphone : 01 76 34 07 96
        </li>
      </ul>
      <br />
      <br />
      <h4 style={ styles.h4 } >
        2. Que proposons-nous ?
      </h4>
      <br />
      <br />
      <p>
        Nous vous proposons une application web accessible à l’adresse <a style={ styles.a } href="https://www.inlivewith.com/">www.inlivewith.com</a> et une
        application mobile Inlive With (la <span style={ styles.bold }> « Plateforme »</span>) permettant à ses utilisateurs (<span style={ styles.bold }> « Vous »</span> ou
        les <span style={ styles.bold }> « Inlivers »</span>) notamment :
      </p>
      <ul>
        <li style={ styles.li } >
          - de s’informer de l’actualité de célébrités ;
        </li>
        <li style={ styles.li } >
          - de créer ou rejoindre des Clubs regroupant d’autres Inlivers afin d’échanger entre Inlivers ;
        </li>
        <li style={ styles.li } >
          - d’avoir accès, visualiser, écouter et partager avec les autres Inlivers des vidéos, musiques,
          photographies et autres contenus diffusés par les Inlivers ou par les Célébrités ;
        </li>
        <li style={ styles.li } >
          - de visualiser des vidéos en direct et des vidéos en streaming à la demande, gratuitement ou
          en payant ;
        </li>
        <li style={ styles.li } >
          - d’acheter en ligne des contenus personnalisés proposés par les Célébrités.</li>
        <br />
        <li style={ styles.li } >
          (Ensemble les <span style={ styles.bold }> « Services »</span>)
        </li>
      </ul>
      <br />
      <br />
      <h4 style={ styles.h4 } >
        3. Que devez-vous savoir sur nos Conditions Générales ?
      </h4>
      <br />
      <br />
      <table>
        <tbody >
          <tr >
            <th style={ styles.table }>
              <span style={ styles.bold }>A quoi servent nos Conditions Générales ?</span>
            </th>
            <td style={ styles.table }>
              Nos conditions générales (les <span style={ styles.bold }> « Conditions Générales »</span>) constituent
              l’unique document régissant notre relation contractuelle et
              définissent :
              <ul>
                <li style={ styles.li } >
                  - les modalités d’utilisation de nos Services,
                </li>
                <li style={ styles.li } >
                  - nos obligations et les vôtres.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th style={ styles.table } >
              <span style={ styles.bold }>Comment accepter nos Conditions Générales ?</span>
            </th>
            <td style={ styles.table }>
              <p>Vous pouvez les trouver par un lien direct en bas de page de la
                Plateforme.
                Vous acceptez les Conditions Générales en cochant une case dans le
                formulaire d’inscription. Si vous n’acceptez pas l’intégralité de nos
                Conditions Générales, vous ne pouvez pas accéder aux Services.
              </p>
              <br />
              <p>
                Elles peuvent être complétées par des conditions particulières, qui, en
                cas de contradiction, prévalent sur les Conditions Générales.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <h4 style={ styles.h4 } >
        4. Quelles autres conditions générales devez-vous accepter pour utiliser nos Services ?
      </h4>
      <br />
      <br />
      <p>Tous les paiements effectués à travers notre Plateforme sont gérés par le prestataire de services de
        paiement indiqué sur la Plateforme (le <span style={ styles.bold }> « Prestataire de services de paiement »</span>).
      </p>
      <br />
      <p>Vous contractez directement avec le Prestataire de services de paiement s’agissant de la mise en
        œuvre de ces paiements, en acceptant ses conditions générales, par le biais d’une case à cocher sur la
        Plateforme.
      </p>
      <br />
      <p>Si le Prestataire de services de paiement refuse ou résilie votre souscription, vous ne pouvez pas/plus
        utiliser nos Services.
      </p>
      <br />
      <p>Inversement, la fin de nos relations contractuelles entraine la résiliation de votre contrat avec le
        Prestataire de services de paiement.
      </p>
      <br />
      <p>En cas de contradiction entre les conditions générales du Prestataire de services de paiement et nos
        Conditions Générales, ces dernières prévalent.
      </p>
      <br />
      <p>Vous nous mandatez expressément pour transmettre au Prestataire de services de paiement toutes
        vos instructions relatives aux paiements réalisés sur la Plateforme.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 } >
        5. Quelles sont les définitions des termes utilisés dans les Conditions Générales ?
      </h4>
      <br />
      <br />
      <dfn>
        <dt style={ styles.bold }>« Célébrités » :</dt>
        <dd>désigne un artiste, acteur, sportif, créateur et toute autre personnalité publique.
        </dd>
        <br />
        <dt style={ styles.bold }>« Charte de la communauté » :</dt>
        <dd>désigne le document accessible sur la Plateforme qui définit les règles que
          doivent respecter les Inlivers dans leur usage des Services.
        </dd>
        <br />
        <dt style={ styles.bold }>« Club » :</dt>
        <dd>désigne des groupes virtuels accessibles depuis la Plateforme et regroupant d’autres Inlivers
          afin d’échanger entre Inlivers à propos d’une même Célébrité déterminée dont le groupe fait l’objet.
        </dd>
        <br />
        <dt style={ styles.bold }>« Commande » :</dt>
        <dd>désigne la commande de Services que vous avez émise à travers la Plateforme. La
          commande de certains contenus personnalisés pourra être librement acceptée ou refusée par la
          Célébrité.
        </dd>
        <br />
        <dt style={ styles.bold }>« Contenu Streaming » :</dt>
        <dd>désigne un contenu visualisable par les Inlivers sur la Plateforme en streaming
          à la demande, qui peut être proposé à titre gratuit ou à titre payant.
        </dd>
        <br />
        <dt style={ styles.bold }>« Contenu Personnalisé » :</dt>
        <dd>désigne tout contenu personnalisé en fonction du Inliver et qui nécessite
          l’intervention d’une Célébrité tel qu’un échange oral ou écrit avec un Inliver (appel visio, message
          privé) proposé à la vente sur la Plateforme ou une photo, vidéo, chanson dédicacée par une Célébrité.
        </dd>
        <br />
        <dt style={ styles.bold }>« Destinataire » :</dt>
        <dd>désigne une tierce personne pour laquelle la Commande est effectuée par l’Inliver.</dd>
        <br />
        <dt> <span style={ styles.bold }> « Inliver » </span> ou <span style={ styles.bold }>« vous » :</span></dt>
        <dd>désigne les utilisateurs inscrits sur la Plateforme.</dd>
        <br />
        <dt style={ styles.bold }>« Participant » :</dt>
        <dd>désigne une tierce personne participant à un contenu personnalisé appel visio
          commandé par un Inliver.
        </dd>
        <br />
      </dfn>
      <br />
      <h4 style={ styles.h4 } >
        6. Quelles sont les conditions pour accéder à nos Services ?</h4>
      <br />
      <br />
      <p><span style={ styles.bold } >(i) Vous êtes une personne physique</span> disposant de la pleine capacité juridique, ou à défaut vous
        êtes âgé d’au moins 15 ans ou vous avez entre 13 et 15 ans et avez obtenu l’accord de votre
        représentant légal, et vous engagez à nous indiquer son adresse email afin que nous puissions
        le contacter. Si vous avez entre 13 et 15 ans en téléchargeant la Plateforme, vous nous
        garantissez avoir préalablement obtenu le consentement de votre représentant légal.
      </p>
      <br />
      <p><span style={ styles.bold } >(ii) Vous avez la qualité de consommateur, </span>entendu comme toutes personnes physiques qui agit
        à des fins qui n'entrent pas dans le cadre de son activité professionnelle.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 } >
        7. Comment souscrire à nos Services ?
      </h4>
      <br />
      <br />
      <p>Vous devez :</p>
      <ul>
        <li style={ styles.li }>
          - remplir le formulaire prévu à cet effet sur la Plateforme,
        </li>
        <li style={ styles.li }>
          - ou être inscrit sur l’un des sites tiers indiqués sur la Plateforme, utiliser vos identifiants de
          connexion du site tiers sélectionné. Vous nous autorisez expressément à accéder aux données
          de votre compte sur le site tiers concerné.
        </li>
      </ul>
      <br />
      <p>
        Vous devez nous fournir l’ensemble des informations marquées comme obligatoires. Vous devrez
        notamment indiquer votre nom d’Inliver (qui peut être votre vrai nom et prénom ou un pseudonyme)
        et choisir un mot de passe (ensemble vos <span style={ styles.bold } >« Identifiants »</span>).
        Si vous choisissez un pseudonyme, celui-ci ne doit pas être offensant, injurieux ou dénigrant,
        ni avoir une quelconque connotation raciste, sexiste, homophobe ou discriminatoire.
      </p>
      <br />
      <p>
        L’inscription entraine automatique l’ouverture d’un compte en votre nom (le
        <span style={ styles.bold } > « Compte »</span>) qui vous permet d’accéder à nos Services
        à l’aide de vos Identifiants.
      </p>
      <br />
      <p>
        Vous garantissez que toutes les informations que vous donnez dans le formulaire d’inscription ou que
        vous fournissez par l’intermédiaire des sites tiers visés ci-dessus sont exactes, à jour et sincères et ne
        sont entachées d’aucun caractère trompeur. Vous reconnaissez que ces informations valent preuve de
        votre identité et vous engagent dès leur validation.
      </p>
      <br />
      <p>Vous vous engagez à mettre à jour ces informations dans votre compte en cas de modifications
        (notamment changement d’adresse postale), afin qu’elles correspondent toujours aux critères
        susvisés.
      </p>
      <br />
      <p>Vos Identifiants sont strictement personnels et confidentiels. Vous êtes seul responsable du maintien
        de leur confidentialité. Pour protéger votre Compte et les informations que vous partagez en utilisant
        les Services, vous ne devez pas communiquer vos Identifiants à des tiers. Vous devez aussi éviter de
        réutiliser vos Identifiants dans des applications tierces. Vous devez immédiatement nous contacter aux
        coordonnées mentionnées à l’article « Qui sommes-nous ? » si vous constatez que votre Compte a été
        utilisé à votre insu. Vous reconnaissez que nous aurons le droit de prendre toutes mesures appropriées
        en pareil cas.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 } >
        8. Comment accéder à nos Services ?
      </h4>
      <br />
      <br />
      <p>Vous pouvez accéder à nos Services en allant directement sur la Plateforme.</p>
      <br />
      <h4 style={ styles.h4 }>9. Quels sont nos services ?</h4>
      <br />
      <br />
      <h5 style={ styles.h5 }>9.1. Nos Services</h5>
      <br />
      <br />
      <p>Avant toute souscription, vous pouvez prendre connaissance des caractéristiques de nos Services sur
        notre Plateforme.
      </p>
      <br />
      <p>Vous reconnaissez :</p>
      <ul>
        <li style={ styles.li }>
          - avoir pris connaissance des caractéristiques et contraintes, notamment techniques, de nos
          Services,
        </li>
        <li style={ styles.li }>
          - que la mise en œuvre des Services nécessite d’être connecté à internet et que la qualité des
          Services dépend de cette connexion, dont nous ne sommes pas responsable.
        </li>
      </ul>
      <br />
      <p>Les Services auxquels vous avez souscrits sont décrits sur la Plateforme.</p>
      <br />
      <p>Vous avez notamment accès aux Services qui suivent :</p>
      <br />
      <br />
      <h5 style={ styles.em }>9.1.1 Inlive With</h5>
      <br />
      <section style={ styles.indented }>
        <p>Vous avez accès depuis la Plateforme aux services Inlive With qui est un réseau des
          communautés des Inlivers fans et qui leur permet d’interagir entre Inlivers appartenant à un
          même Club et avec les Célébrités ayant décidé de rejoindre le Club.
        </p>
        <br />
        <p>Lors de votre inscription, vous avez la possibilité de rejoindre un Club ou d’en créer un. Dans
          l’hypothèse où vous décidez de créer un Club, vous en devenez l’administrateur
          (« l’Administrateur »).
        </p>
        <br />
        <p>L’Administrateur peut inviter d’autres Inlivers à rejoindre le Club.</p>
        <br />
        <p>Les Inlivers membres d’un Club peuvent publier des photos, des vidéos, des informations et
          tout autre contenu en lien avec le Club et susceptibles d’intéresser les autres Inlivers membres
          du Club.
        </p>
        <br />
        <p>
          La Célébrité qui fait l’objet du Club pourra décider discrétionnairement de rejoindre le Club et
          d’interagir avec les Inlivers membres du Club.
        </p>
      </section>
      <br />
      <br />
      <h5 style={ styles.em }>9.1.2 Inlive Stream</h5>
      <br />
      <section style={ styles.indented }>
        <p>
          Vous avez accès depuis la Plateforme au service Inlive Stream qui est un service de streaming
          depuis lequel vous pouvez visionner du Contenu Streaming qui peut notamment être des
          vidéos ou des livres audios, à titre gratuit ou à titre payant.
        </p>
      </section>
      <br />
      <br />
      <h5 style={ styles.em }>9.1.2 Inlive Me</h5>
      <br />
      <section style={ styles.indented }>
        <p>
          Vous avez accès depuis la Plateforme au service Inlive Me qui vous permet de commander des
          Contenus Personnalisés.
        </p>
        <br />
        <p>Avant toute commande en ligne et en application notamment des dispositions de l’article
          L111-1 du Code de la consommation, vous pouvez prendre connaissance, sur la Plateforme,
          des caractéristiques de chaque Contenu Personnalisé que vous désirez commander.
        </p>
        <br />
        <br />
        <h6 style={ styles.h6 }>9.1.2.1 Description des Contenus Personnalisés</h6>
        <br />
        <br />
        <p>Vous pouvez commander les Contenus Personnalisés décrits ci-dessous. Nous nous réservons
          la possibilité de vous proposer la vente de tout autre contenu, sous une forme et selon les
          fonctionnalités et moyens techniques que nous estimerons les plus appropriés pour proposer
          lesdits Contenus Personnalisés.
        </p>
        <br />
        <p>
          Seuls les Contenus Personnalisés que la Célébrité aura préalablement acceptés de vendre sur
          la Plateforme pourront faire l’objet d’une Commande par l’Inliver.
        </p>
        <br />
        <strong style={ styles.strong }>-
          <span style={ styles.indented }>Contenu Photo Dédicacée</span>
        </strong>
        <br />
        <p>
          Vous sélectionnez une photo parmi celles présentes sur la fiche de la Célébrité et rédigez le
          texte souhaité. Ce dernier sera mis en forme informatiquement en reprenant l’écriture
          manuscrite et la signature de la Célébrité, le cas échéant (<span style={ styles.bold } > « Contenu Photo Dédicacée » </span>).
        </p>
        <br />

        <strong style={ styles.strong }>-
          <span style={ styles.indented }>Contenu Vidéo Dédicacée</span>
        </strong>
        <br />
        <p>Le contenu vidéo dédicacée est composé d’une vidéo dans laquelle la Célébrité vous adresse,
          ou adresse au Destinataire, le cas échéant, un message personnalisé (<span style={ styles.bold } > « Contenu Vidéo
            Dédicacée » </span>).
        </p>
        <br />
        <p>
          Vous devez renseigner des instructions générales claires et intelligibles sur la base desquelles
          le Contenu Vidéo Dédicacée sera réalisé (les <span style={ styles.bold } > « Instructions Générales » </span>).
        </p>
        <p>La durée du Contenu Vidéo Dédicacée sera celle indiquée sur la Plateforme.</p>
        <p>Vous êtes informé que le filigrane d’INLIVE WITH sera apposé sur le Contenu Vidéo Dédicacée.</p>
        <br />
        <strong style={ styles.strong }>-
          <span style={ styles.indented }>Contenu Chanson Dédicacée</span>
        </strong>
        <br />
        <p>
          Le contenu chanson dédicacée se compose d’une vidéo dans laquelle la Célébrité vous adresse
          ou adresse au Destinataire, le cas échéant, un message et chante la chanson sélectionnée par
          vos soins parmi les chansons proposées (<span style={ styles.bold } > « Contenu Chanson Dédicacée » </span>).</p>
        <br />
        <p>
          Vous devez renseigner des Instructions Générales claires et intelligibles sur la base desquelles
          le Contenu Chanson Dédicacée sera réalisé.
        </p>
        <br />
        <p>La durée du Contenu Chanson Dédicacée sera celle indiquée sur la Plateforme.</p>
        <br />
        <p>Vous êtes informé que le filigrane d’INLIVE ME sera apposé sur le Contenu Chanson Dédicacée.</p>
        <br />
        <strong style={ styles.strong }>-
          <span style={ styles.indented }>Contenu Appel Visio</span>
        </strong>
        <br />
        <p>
          Le contenu appels visio désigne une visioconférence entre la Célébrité et l’Utilisateur et/ou le
          Participant le cas échéant (<span style={ styles.bold } > « Contenu Appel Visio » </span>).
        </p>
        <br />
        <p>
          A ce titre, vous devez soumettre à la Célébrité un jour et un horaire. La Célébrité pourra
          également vous proposer un jour et/ou horaire de son choix.
        </p>
        <br />
        <p>Si vous validez la Commande à l’horaire et au jour proposé et que vous n’êtes pas présent à la
          date et heure prévue, vous ne serez pas remboursé du montant de votre achat.
        </p>
        <br />
        <p>Vous reconnaissez et acceptez que cet appel devra être effectué dans un lieu privé.</p>
        <br />
        <strong style={ styles.strong }>-
          <span style={ styles.indented }>Messages Privés</span>
        </strong>
        <br />
        <p>
          Les messages privés vous permettent d’adresser un message écrit et privé à la Célébrité
          Participant le cas échéant (<span style={ styles.bold } >« Message Privé ».</span>).
        </p>
        <br />
        <p>
          Ces Messages Privés peuvent désigner un simple message envoyé par vos soins ou déclencher
          une conversation entre vous et la Célébrité. L’envoi de chaque Message Privé est payant.
        </p>
        <br />
        <br />
        <h6 style={ styles.h6 }>9.1.2.2 Acceptation ou refus de la Commande des Contenus Personnalisés par la Célébrité</h6>
        <br />
        <br />
        <p>Vous reconnaissez et acceptez que les Commandes d’un Contenu Vidéo Dédicacée, Contenu
          Chanson Dédicacée et Contenu Appel Visio pourront être librement acceptées ou refusées par
          la Célébrité. Cette dernière, pourra également, à la suite d’une Commande et à sa libre
          discrétion, vous bloquer l’accès à des commandes de Contenus personnalisés par elle. Si la
          Célébrité vous bloque, vous ne serez plus en mesure de lui soumettre des Commandes.
        </p>
        <br />
        <p>Concernant le Contenu Photo Dédicacée, votre Commande sera automatiquement validée.</p>
        <br />
        <p>Concernant les Messages Privés, la Célébrité est libre de vous répondre ou de clôturer la
          discussion.
        </p>
        <br />
        <p>
          Par ailleurs vous reconnaissez et acceptez que les Célébrités ne sont pas contraintes de suivre
          l’intégralité de vos Instructions Générales.
        </p>
      </section>
      <br />
      <p>Nous nous réservons la possibilité de proposer tout autre Service.</p>
      <br />
      <p>Toute demande de modification des Services souscrits devra faire l’objet d’une nouvelle Commande.</p>
      <br />
      <h5 style={ styles.h5 }>9.2. Nos prestations complémentaires</h5>
      <br />
      <ul>
        <li>
          <strong style={ styles.strong }>
            <span style={ styles.indented }><span style={ styles.indented }>• Maintenance :</span></span>
          </strong>
          <br />
          <p>Vous bénéficiez pendant la durée des Services d’une maintenance, notamment corrective et évolutive.
            Dans ce cadre, l’accès à la Plateforme peut être limité ou suspendu pour des raisons de maintenance.
          </p>
          <br />
          <p>Concernant la maintenance corrective, nous faisons nos meilleurs efforts pour vous fournir une
            maintenance corrective afin de corriger tout dysfonctionnement ou bogue relevé sur la Plateforme.
          </p>
          <br />
          <p>Concernant, la maintenance évolutive, vous bénéficiez pendant la durée des Services d’une
            maintenance évolutive, que nous pourrons réaliser automatiquement et sans information préalable.,
            et qui comprend des améliorations des fonctionnalités de la Plateforme et/ou installations techniques
            utilisées dans le cadre de la Plateforme (visant à introduire des extensions mineures ou majeures).
          </p>
          <br />
          <p>L’accès à la Plateforme peut par ailleurs être limité ou suspendu pour des raisons de maintenance
            planifiée.
          </p>
          <br />
        </li>
        <li>
          <strong style={ styles.strong }>
            <span style={ styles.indented }><span style={ styles.indented }>• Hébergement :</span></span>
          </strong>
          <br />
          <p>Nous assurons, dans les termes d’une obligation de moyens, l’hébergement de la Plateforme,
            ainsi que des données produites et/ou saisies par/sur la Plateforme,
            sur ses serveurs ou par l’intermédiaire d’un prestataire d’hébergement professionnel,
            et sur des serveurs situés dans un territoire de l’Union européenne.
          </p>
        </li>
        <li>
          <br />
          <strong style={ styles.strong }>
            <span style={ styles.indented }><span style={ styles.indented }>• Assistance technique :</span></span>
          </strong>
          <br />
          <p>En cas de difficulté rencontrée lors de l’utilisation de nos Services, vous pouvez nous contacter aux
            coordonnées mentionnées à l’article « Qui sommes-nous ? ».
          </p>
        </li>
      </ul>
      <br />
      <br />
      <h4 style={ styles.h4 }>10. Comment pouvez-vous commander des Contenus Personnalisés et des Contenus Streaming ?</h4>
      <br />
      <br />
      <h5 style={ styles.bold }>10.1 Passation de commande</h5>
      <br />
      <br />
      <h6 style={ styles.h6 }>10.1.1 Commande de Contenu Personnalisé</h6>
      <br />
      <section style={ styles.indented }>
        <p>
          Vous pouvez commander un Contenu Personnalisé en allant sur la page dédiée de la Célébrité
          au sein de la Plateforme Inlive Me et en sélectionnant le Contenu Personnalisé de votre choix.
        </p>
        <br />
        <p>Les modalités de Commande de chaque type Contenu Personnalisé ou Contenu Streaming sont
          indiquées sur la Plateforme.
        </p>
        <br />
        <p>Une fois la Commande de produit Personnalisé soumise à la Célébrité, vous recevrez une
          notification sur la Plateforme dans le délai précisé sur celle-ci, vous indiquant que :
        </p>
        <ul style={ styles.indented }>
          <li style={ styles.li }>- votre Commande est accessible sur la Plateforme ; ou</li>
          <li style={ styles.li }>- votre Commande a été refusée par la Célébrité ; ou</li>
          <li style={ styles.li }>
            - la Célébrité a fait le choix de vous bloquer si vous ne respectez par la Charte des
            communautés ; ou
          </li>
          <li style={ styles.li }>- votre Commande est annulée au motif qu’elle n’aura pas été exécutée dans les
            délais indiqués par la Célébrité.
          </li>
        </ul>
      </section>
      <br />
      <br />
      <h6 style={ styles.h6 }>10.1.2 Commande de Contenu Streaming</h6>
      <br />
      <section style={ styles.indented }>
        <p>Vous pouvez commander un Contenu Streaming en allant sur l’onglet Inlive Stream de la
          Plateforme et en sélectionnant le Contenu Streaming de votre choix.
        </p>
        <br />
        <p>Une fois la Commande de Contenu Streaming effectuée, vous avez directement accès depuis
          votre Compte à ce Contenu Streaming pour la durée indiquée sur la Plateforme.
        </p>
      </section>
      <br />
      <br />
      <h5 style={ styles.bold }>10.2 Confirmation de Commande</h5>
      <br />
      <br />
      <p>A l’issue de votre Commande, vous recevrez par email une confirmation de celle-ci qui :</p>
      <ul>
        <li style={ styles.li }>- récapitule les éléments de la Commande et le délai dans lequel
          elle sera accessible et, si la Célébrité l’accepte concernant le Contenu Personnalisé,
        </li>
        <li style={ styles.li }>- comporte les conditions générales en vigueur au jour de la Commande,</li>
        <li style={ styles.li }>- comporte la facture correspondant à la Commande.</li>
        <br />
        <p>Vous devez vous assurer que les coordonnées renseignées dans votre Compte sont correctes et
          qu’elles vous permettent de recevoir l’email de confirmation. A défaut de réception de celui-ci, vous
          devez nous contacter aux coordonnées mentionnées à l’article « Qui sommes-nous ».
        </p>
        <br />
        <p>Nous vous recommandons de conserver les informations contenues dans la confirmation de
          Commande.
        </p>
        <br />
        <p>
          Vous pouvez accéder à l’historique de vos Commandes de Contenus Personnalisés acceptées et vos
          Commandes de Contenus Streaming sur votre Compte.
        </p>
      </ul>
      <br />
      <br />
      <h4 style={ styles.h4 }>11. Pour combien de temps souscrivez-vous à nos Services ?</h4>
      <br />
      <br />
      <p>Vous souscrivez à nos Services pour une durée indéterminée.</p>
      <br />
      <br />
      <h4 style={ styles.h4 }>12. Quelles sont nos conditions financières ?</h4>
      <br />
      <br />
      <h5 style={ styles.bold }>12.1 Quels sont les prix ?</h5>
      <br />
      <br />
      <section style={ styles.indented }>
        <h6 style={ styles.h6 }>12.1.1 Quels sont les prix des Services ?</h6>
        <section style={ styles.indented }>
          <br />
          <p>Nos Services sont fournis à titre gratuit.</p>
          <br />
        </section>
        <h6 style={ styles.h6 }>12.1.2 Quels sont les prix des Contenus Streaming ?</h6>
        <section style={ styles.indented }>
          <br />
          <p>
            Les prix des Contenus Streaming sont affichés sur la Plateforme. Ils sont indiqués en euros,
            toutes taxes comprises (TVA française et autres taxes applicables).
          </p>
          <br />
          <p>Nous sommes libres de proposer des offres promotionnelles ou des réductions de prix.</p>
          <br />
        </section>
        <h6 style={ styles.h6 }>12.1.3 Quels sont les prix des Contenus Personnalisés ?</h6>
        <section style={ styles.indented }>
          <br />
          <p>
            Les prix des Contenus Personnalisés sont indiqués sur la Plateforme. Ils sont indiqués en euros,
            toutes taxes comprises (TVA française et autres taxes applicables).
          </p>
          <br />
        </section>
      </section>
      <br />
      <br />
      <h5 style={ styles.bold }>12.2 Modalités de paiement</h5>
      <br />
      <section style={ styles.indented }>
        <br />
        <p>
          La totalité du prix des Contenus Streaming payants et des Contenus Personnalisés est exigible à la
          commande.
        </p>
        <br />
        <p>Le paiement peut s’effectuer en ligne et par tout autre moyen qui sera proposé sur la Plateforme
          au moment de la Commande.
        </p>
        <br />
        <p>
          Vous nous garantissez que vous disposez des autorisations nécessaires pour utiliser le mode de
          paiement choisi.
        </p>
        <br />
        <p>Nous nous réservons le droit de suspendre ou d'annuler toute Commande en cas de non-paiement
          de toute somme qui serait due par vous, en cas d'incident de paiement, ou en cas de fraude ou
          tentative de fraude relative à l’utilisation de la Plateforme.
        </p>
        <br />
      </section>
      <br />
      <br />
      <h5 style={ styles.bold }>12.3Facturation</h5>
      <br />
      <p style={ styles.indented }>La facture correspondant à votre Commande vous sera envoyée
        par tout moyen que nous jugeons utile..
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>13. Remboursement</h4>
      <br />
      <br />
      <p>Vous serez remboursé si :</p>
      <ul>
        <li style={ styles.li }>- votre Commande de Contenu Personnalisé est refusée par la Célébrité ;</li>
        <li style={ styles.li }>- la Célébrité vous bloque ;</li>
        <li style={ styles.li }>- votre Commande n’est pas honorée dans les délais indiqués sur la Plateforme.</li>
      </ul>
      <br />
      <p>Vous serez remboursé de l’intégralité des sommes payées, au plus tard dans les 14 jours suivant la
        date de notification du remboursement.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>14. Gestion des réclamations pour les Contenus Personnalisés</h4>
      <br />
      <br />
      <p>En cas de réclamation, en raison de Contenus Personnalisés réalisés de manière insatisfaisante, vous
        aurez la possibilité de nous contacter aux coordonnées mentionnées à l’article « Qui sommes-nous ? »
        ou à travers votre Compte.
      </p>
      <br />
      <p>Il est expressément entendu qu’un Contenu Chanson Dédicacée ou Vidéo Dédicacée est considéré être
        réalisé de manière insatisfaisante lorsque la Célébrité n’a suivi aucune des Instructions Générales.
      </p>
      <br />
      <p>Toutefois, vous acceptez et reconnaissez que nous ne jouons qu’un rôle de simple médiateur entre
        vous et la Célébrité et nous vous invitons à faire vos meilleurs efforts pour résoudre amiablement tout
        litige vous opposant à la Célébrité. Nous ne pouvons pas engager notre responsabilité dans le cadre de
        vos relations avec les Célébrités, ni être partie à quelques litiges éventuels que ce soit, la Célébrité
        restant seule responsable de l’exécution des Contenus fournis via la Plateforme.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>15. Bénéficiez-vous d’un droit de rétractation ?</h4>
      <br />
      <br />
      <p>Vous ne bénéficiez pas du droit de rétractation dès lors que :</p>
      <ul>
        <li style={ styles.li }>-
          nos Services concernent la fourniture d'un contenu numérique non fourni sur un support
          matériel et vous avez expressément (i) accepté que les Services commencent dès votre
          acceptation des Conditions Générales (ii) renoncé à votre droit de rétractation ;
        </li>
        <li style={ styles.li }>- les Contenus Personnalisés incluant des dédicaces personnalisées
          selon vos spécifications.
        </li>
      </ul>
      <br />
      <br />
      <h4 style={ styles.h4 }>16. Quels sont nos droits de propriété intellectuelle respectifs
        et qu’est-ce qu’il nous est permis de faire à cet égard ?
      </h4>
      <br />
      <br />
      <h5 style={ styles.bold }>16.1 Droits de propriété intellectuelle sur la Plateforme</h5>
      <br />
      <p style={ styles.indented }>
        La Plateforme est notre propriété, de même que les logiciels, infrastructures, bases de données et
        contenus de toute nature (textes, images, visuels, musiques, logos, marques, etc.) que nous
        exploitons. Ils sont protégés par tous droits de propriété intellectuelle ou droits des producteurs de bases de
        données en vigueur. La licence que nous vous consentons n’entraine aucun transfert de propriété.
      </p>
      <br />
      <p style={ styles.indented }>Vous bénéficiez d’une licence en mode SaaS non exclusive, personnelle et non transmissible
        d’utilisation de la Plateforme pour la durée prévue à l’article « Pour combien de temps souscrivez vous à nos Services ? ».
      </p>
      <br />
      <br />
      <h5 style={ styles.bold }>16.2 Droits de propriété intellectuelle sur les Contenus Personnalisés et des Contenus Streaming</h5>
      <br />
      <p style={ styles.indented }>Les Contenus Personnalisés sont la propriété des Célébrités et les Contenus Streaming sont notre
        propriété. Vous ne bénéficiez que d’un droit d’accéder aux Contenus Personnalisés et Contenus
        Streaming uniquement pour un usage personnel, non promotionnel et non commercial.
      </p>
      <br />
      <br />
      <div style={ styles.indented }>
        <h6 style={ styles.h6 }>16.2.1 Contenus Personnalisés</h6>
        <br />
        <p style={ styles.indented }>Toute utilisation des Contenus Personnalisés doit être effectuée à des fins privées et personnelles
          ou à des fins de partage avec les autres Inlivers de la Plateforme via les outils de celle-ci ou au
          Destinataire.
        </p>
        <br />
        <br />
        <h6 style={ styles.h6 }>16.2.2 Contenus Steaming</h6>
        <br />
        <p style={ styles.indented }>Vous acceptez et reconnaissez que vous n’êtes pas autorisé à reproduire les Contenus Streaming,
          sur quelque support que ce soit, à les transmettre à quiconque, à les distribuer, les afficher, les
          vendre, concéder une licence, les altérer, les modifier, les détourner ou utiliser de quelque
          manière que ce soit lesdits contenus. Le non-respect de votre part de ses restrictions d’utilisation
          constituera une violation des droits de propriété intellectuelle des, auteurs et/ou ayants-droits
          respectifs, et engagera votre responsabilité.
        </p>
      </div>
      <br />
      <br />
      <h5 style={ styles.bold }>16.3 Dans quel cadre pouvons-nous utiliser vos droits de la personnalité ?</h5>
      <br />
      <p style={ styles.indented }>
        Vous nous autorisez à utiliser à titre gratuit l’image rattachée à votre Compte ainsi que votre nom
        et votre voix captée dans les témoignages vidéos que nous avons réalisés afin de promouvoir nos
        services, par tout moyen et sur tout support, pour le monde entier, pour une durée de 5 ans à
        compter de votre acceptation des Conditions Générales.
      </p>
      <br />
      <br />
      <h5 style={ styles.bold }>16.4 Dans quel cadre pouvons-nous utiliser vos publications et témoignages</h5>
      <div style={ styles.indented }>
        <br />
        <p>
          En souscrivant à nos Services, vous reconnaissez que les contenus de toute nature que vous
          publiez ou importez sur la Plateforme, tels que des photographies, vidéos, textes, illustrations,
          graphismes, etc., (les <span style={ styles.bold }> « Publications »</span>) sont diffusés dans un esprit d’échange communautaire.
        </p>
        <br />
        <p>Vous conservez tous vos droits de propriété intellectuelle sur vos Publications, que vous pouvez
          ré(utiliser) et exploiter comme vous le souhaitez en dehors de la Plateforme. Cependant, en
          important / téléchargeant vos Publications, vous acceptez de concéder à INLIVE WITH et aux
          autres Inlivers les droits ci-après :
        </p>
      </div>
      <br />
      <br />
      <div style={ styles.indented }>
        <h6 style={ styles.h6 }>16.4.1 Licence accordée à INLIVE WITH</h6>
        <br />
        <p style={ styles.indented }>
          Vous concédez à INLIVE WITH une licence non-exclusive, gratuite, transférable et cessible,
          valable pour le monde entier, d’utiliser, de reproduire, d’héberger, de créer des copies
          techniques, de diffuser, de communiquer au public, de modifier, d’analyser, d’afficher vos
          Publications, aux seules fins d’exploitation, de promotion et d’amélioration de la Plateforme,
          de ses outils et des Services.
        </p>
        <br />
        <br />
        <h6 style={ styles.h6 }>16.4.2 Durée des licences accordées</h6>
        <br />
        <div style={ styles.indented }>
          <p>Les licences accordées prendront fin dès que vous supprimerez vos Publications (un ou
            plusieurs de vos Publications) ou dès la suppression de votre Compte pour quelque raison que
            ce soit.
          </p>
          <br />
          <p>Toutefois, dans le cas où vos Publications ont été utilisées par des tiers Inlivers conformément
            à la licence prévue par les présentes, celle-ci perdurera jusqu’à la suppression complète de vos
            Publications par ces Inlivers.
          </p>
          <br />
          <p>Les fonctionnalités de paramétrage et de confidentialité de votre Compte vous permettent de
            définir la manière dont vous souhaitez partager vos Publications avec les autres Inlivers, ainsi
            que, le cas échéant, avec nos partenaires.
          </p>
          <br />
          <p>
            Pensez à configurer votre Compte (qui peut être public ou privé selon le cas) et à réviser
            régulièrement vos paramètres de confidentialité, afin de mieux contrôler vos droits sur vos
            Publications et vos données personnelles. Consultez aussi notre
            <Link to={ '/legal#confidentiality' } style={ styles.a }> politique de confidentialité.</Link>
          </p>
          <br />
          <p>
            Nous sommes susceptibles de conserver vos Publications dans nos systèmes après la fin de la
            licence, notamment lorsque cela est nécessaire pour des raisons techniques liées au
            fonctionnement de la Plateforme pour la gestion d’un litige ou pour nous conformer à nos
            obligations légales.
          </p>
        </div>
        <br />
        <br />
        <h6 style={ styles.h6 }>16.4.3 Témoignages</h6>
        <br />
        <div style={ styles.indented }>
          <p>Vous pouvez également réaliser des témoignages concernant votre utilisation des Services.</p>
          <br />
          <p>Par conséquent, vous consentez à ce que nous puissions :</p>
          <ul>
            <li>
              - diffuser les Publications et les témoignages à titre gracieux sur la Plateforme et sur tous
              autres sites internet français ou étrangers, édités par toutes sociétés avec lesquelles nous
              avons des accords, par tout moyen et sur tout support, aux fins de promotion de la Plateforme,
            </li>
            <li>- traduire les Publications et les témoignages en toutes langues,</li>
            <li>- modifier (notamment le cadrage, le format et les couleurs) et/ou adapter les Publications et
              les témoignages (notamment aux contraintes techniques de la Plateforme (altérations ou
              dégradations dans leur qualité).
            </li>
          </ul>
        </div>
      </div>
      <br />
      <br />
      <h5 style={ styles.bold }>16.5 Dans quel cadre pouvez-vous utiliser les contenus publiés par les Célébrités</h5>
      <br />
      <div style={ styles.indented }>
        <p>Lors de votre utilisation de la Plateforme et des Services, vous pouvez avoir accès à des fichiers
          vidéos, des fichiers audio (notamment de la musique), des photographies, des illustrations, des
          graphismes, des textes et d’autres ressources, qui sont diffusés sur la Plateforme par des
          Célébrités (les <span style={ styles.bold }> « Contenus des Célébrités »</span>).
        </p>
        <br />
        <p>Les droits de propriété intellectuelle sur les Contenus des Célébrités sont conservés par les
          Célébrités, les auteurs et/ou leurs ayants-droits respectifs, à l’exception des utilisations permises
          par les présentes.
        </p>
        <br />
        <br />
        <h6 style={ styles.h6 }>16.5.1 Utilisations permises</h6>
        <br />
        <p style={ styles.indented }>
          Vous pouvez regarder, écouter, commenter, partager avec les autres Inlivers de la Plateforme
          les Contenus des Célébrités, au moyen des outils et services de la Plateforme (les Clubs
          auxquels vous participez, la messagerie personnelle etc.). Toute utilisation doit être effectuée
          à des fins privées et personnelles ou à des fins de partage avec les autres Inlivers de la
          Plateforme via les outils de celle-ci. Vous pouvez également partager les Contenus de vos
          Célébrités préférées sur vos propres réseaux sociaux.
        </p>
        <br />
        <br />
        <h6 style={ styles.h6 }>16.5.2 Utilisations non-autorisées</h6>
        <br />
        <div style={ styles.indented }>
          <p>Toute utilisation non expressément permise n’est pas autorisée. Vous n’êtes pas autorisé,
            notamment, à :
          </p>
          <ul>
            <li style={ styles.li }>-
              Reproduire, télécharger, transmettre, distribuer, afficher, vendre, concéder sous licence,
              altérer, modifier ou utiliser de quelque manière que ce soit les Contenus des Célébrités par
              tout moyen autre que les outils et les services de la Plateforme ;
            </li>
            <li style={ styles.li }>-
              Utiliser la Plateforme pour visionner, écouter ou autrement utiliser les Contenus des Célébrités
              dans une finalité autre que votre usage personnel et non-commercial.
            </li>
          </ul>
          <br />
          <p>Le non-respect de votre part de ses restrictions d’utilisation constituera une violation des
            droits de propriété intellectuelle des Célébrités, auteurs et/ou ayants-droits respectifs, et
            engagera votre responsabilité.
          </p>
        </div>
        <br />
        <br />
      </div>
      <h5 style={ styles.bold }>16.6 Dans quel cadre pouvez-vous utiliser les Publications des autres Inlivers ?</h5>
      <br />
      <div style={ styles.indented }>
        <p>Les Publications des autres Inlivers figurant sur la Plateforme et les droits de propriété
          intellectuelle y afférent sont présumés appartenir à cet Inliver. Vous pouvez utiliser les
          Publications via les outils et les services de la Plateforme de la même manière et avec les mêmes
          restrictions applicables aux Contenus des Célébrités, sauf si l’Inliver en question vous a accordé
          une autorisation expresse permettant une utilisation plus étendue.
        </p>
        <br />
        <p>Avant de partager une Publication au sein de la Plateforme, il vous revient de vérifier sa conformité
          avec la Charte de la communauté INLIVE WITH publiée sur la Plateforme. En cas de doute, ou si
          vous considérez qu’une Publication publiée par un Inliver sur la Plateforme est susceptible de
          porter atteinte aux droits d’un tiers, vous devez vous abstenir de le partager.
        </p>
        <br />
        <p>Nous vous invitons à nous signaler toute Publication qui ne respecterait pas les présentes
          Conditions Générales, la Charte de la communauté et les droits de tout tiers via
          l’onglet « Signaler ».
        </p>
      </div>
      <br />
      <br />
      <h4 style={ styles.h4 }>17 Quelles sont vos obligations et de quoi êtes-vous responsable ?</h4>
      <br />
      <h5 style={ styles.bold }>17.1 Concernant l’utilisation des Services</h5>
      <br />
      <ol>
        <li>
          <div style={ styles.flex }>
            <p>17.1.1</p>
            <p>Vous êtes responsable de votre utilisation des Services et de toute information que vous
              partagez dans ce cadre. Vous vous engagez à utiliser les Services personnellement et à ne
              permettre à aucun tiers de les utiliser à votre place ou pour votre compte.
            </p>
          </div>
        </li>
        <br />
        <li>
          <div style={ styles.flex }>
            <p>17.1.2 </p>
            <div>
              <p> Vous vous interdisez de détourner les Services à des fins autres que celles pour
                lesquelles ils ont été conçus, et notamment pour :
              </p>
              <ul >
                <li style={ styles.li }>- exercer une activité illégale ou frauduleuse,</li>
                <li style={ styles.li }>- porter atteinte à l’ordre public et aux bonnes mœurs,</li>
                <li style={ styles.li }>- porter atteinte à des tiers ou à leurs droits, de quelque manière que ce soit,</li>
                <li style={ styles.li }>- violer une disposition contractuelle, législative ou règlementaire,</li>
                <li style={ styles.li }>- exercer toute activité de nature à interférer dans le système informatique d’un tiers
                  notamment aux fins d’en violer l’intégrité ou la sécurité,
                </li>
                <li style={ styles.li }>- effectuer des manœuvres visant à promouvoir vos services et/ou sites ou ceux d’un tiers,</li>
                <li style={ styles.li }>- aider ou inciter un tiers à commettre un ou plusieurs actes ou activités listés ci-dessus.</li>
              </ul>
              <br />
              <p>Vous vous interdisez également de :</p>
              <ul>
                <li style={ styles.li }>- copier, modifier ou détourner tout élément nous appartenant ou tout concept que nous
                  exploitons dans le cadre des Services,
                </li>
                <li style={ styles.li }>- adopter tout comportement de nature à interférer avec ou détourner nos systèmes
                  informatiques ou porter atteinte à nos mesures de sécurité informatique,
                </li>
                <li style={ styles.li }>- porter atteinte à nos droits et intérêts financiers, commerciaux ou moraux,</li>
                <li style={ styles.li }>- commercialiser, transférer ou donner accès de quelque manière que ce soit aux Services, aux
                  informations hébergées sur la Plateforme ou à tout élément nous appartenant.
                </li>
              </ul>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div style={ styles.flex }>
            <p> 17.1.3 </p>
            <div>
              <p> Vous êtes responsable des Publications de toute nature que vous diffusez sur la Plateforme
                dans le cadre des Services et nous garantissez contre toute réclamation provenant de tout
                tiers, dont nous pourrions faire l’objet du fait du non-respect par vous des conditions
                d’importation et de partage de vos Publications au sein de la Plateforme.
              </p>
              <br />
              <p>
                Vous nous garantissez posséder les autorisations nécessaires et les droits de propriété
                intellectuelle sur tout contenu que vous importez/téléchargez vers la Plateforme et que vous
                partagez au moyen des outils et des Services.
              </p>
              <br />
              <p>
                Vous nous garantissez que vos Publications et leur diffusion au sein de la Plateforme ne portent
                atteinte à aucun droit reconnu et protégé par la loi, et notamment à un droit de propriété
                intellectuelle, à l’image, à la vie privée, à la personnalité, à un droit relatif à la protection des
                données personnelles, etc.
              </p>
              <br />
              <p>
                Vous garantissez également que vos Publications sont conformes à la Charte de la
                Communauté.
              </p>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div style={ styles.flex }>
            <p>17.1.4</p>
            <div>
              <p> Vous acceptez que les Publications diffusées sur la Plateforme soient rendues publics par
                défaut et sont susceptibles d’être vus par d’autres utilisateurs de la Plateforme.
              </p>
              <br />
              <p>Vous vous interdisez de diffuser toute Publication (cette liste n’est pas exhaustive) :</p>
              <ul>
                <li style={ styles.li }>- portant atteinte à l’ordre public et aux bonnes mœurs (pornographiques, obscènes, indécents,</li>
                <li style={ styles.li }>- choquants ou inadaptés à un public familial, diffamatoires, injurieux, violents, racistes, xénophobes ou révisionnistes),</li>
                <li style={ styles.li }>- portant atteinte aux droits de tiers (contenus contrefaisants, atteinte aux droits de la
                  personnalité, etc.) et plus généralement violant une disposition contractuelle, législative ou
                  règlementaire,
                </li>
                <li style={ styles.li }>-
                  préjudiciable à des tiers de quelque manière que ce soit,
                  mensongers, trompeurs ou proposant ou promouvant des activités illicites, frauduleuses ou
                  trompeuses,
                </li>
                <li style={ styles.li }>nuisibles aux systèmes informatiques de tiers.</li>
              </ul>
            </div>
          </div>
        </li>
        <br />
        <li>
          <div style={ styles.flex }>
            <p>17.1.5</p>
            <div>
              <p> La Plateforme vous donne la possibilité, via ses outils et les Services, de commenter les
                Contenus des Célébrités, vos Publications et les Publications d’autres Inlivers, de créer et de
                participer à des Clubs, d’échanger des messages privés avec un ou plusieurs Inlivers ou avec
                une ou plusieurs Célébrités.
              </p>
              <br />
              <p>Vous êtes responsable des relations que vous pourrez nouer avec les autres Inlivers ou avec
                les Célébrités. Vous vous engagez à agir avec discernement et respecter les règles usuelles de
                politesse et de courtoisie dans vos échanges avec les autres Inlivers et avec les Célébrités.
              </p>
              <br />
              <p>Vous reconnaissez et vous engagez à respecter le caractère privé des messages échangés au
                sein des Clubs fermés ou par le biais de la messagerie sur la Plateforme, en vous abstenant de
                toute divulgation de ces messages au-delà du cercle privé auquel ils sont destinés.
              </p>
              <br />
              <p>Vous êtes seul responsable du contenu de vos messages et commentaires et vous devez vous
                assurer de leur caractère licite et respectueux et de leur conformité à la Charte de la
                Communauté.
              </p>
              <br />
              <p>Vous nous garantissez contre toute réclamation et/ou action qui pourrait être exercée à notre
                encontre à la suite de la violation de l’une de vos obligations. Vous nous indemniserez du
                préjudice subi et nous rembourserez de toutes les sommes que nous pourrions avoir à
                supporter de ce fait.
              </p>
              <br />
            </div>
          </div>
        </li>
        <li>
          <div style={ styles.flex }>
            <p>17.1.6</p>
            <p> Dans le cadre des Clubs, vous vous engagez à diffuser et échanger des informations destinées
              à suivre l’actualité de la Célébrité dont le Club fait l’objet.
            </p>
          </div>
        </li>
        <br />
        <li>
          <div style={ styles.flex }>
            <p>17.1.7</p>
            <div>
              <p>Vous êtes entièrement responsable des informations que vous publiez sur la Plateforme,
                comprenant notamment vos Publications, commentaires et messages (les <span style={ styles.bold }> « Informations »</span>).
              </p>
              <br />
              <p>
                Dans le cas où une Information publiée sur la Plateforme par un Inliver ne respecte pas la
                réglementation applicable ou n’est pas conforme aux présentes Conditions Générales ou à la
                Charte des Communautés, l’Inliver à l’origine de cette Information reconnaît et accepte qu’il
                est seul responsable des dommages directs et/ou indirects qui peuvent en résulter à l’égard
                des tiers et/ou de INLIVE WITH.
              </p>
              <br />
              <p>
                Les Contenus des Célébrités sont publiés sur la Plateforme à l’initiative des Célébrités, sous
                leur entière responsabilité.
              </p>
              <br />
              <p>Les Inlivers sont entièrement responsables de l’utilisation qu’ils font des Informations
                disponibles sur la Plateforme et notamment de la décision de partager des Informations
                publiées par d’autres Inlivers ou par des Célébrités. Ils reconnaissent et acceptent qu’en
                décidant de partager une Information qui ne respecte pas la réglementation applicable ou
                n’est pas conforme aux présentes Conditions Générales ou à la Charte des Communautés, ils
                risquent d’engager leur responsabilité à l’égard des tiers et/ou de INLIVE WITH de dommages
                directs et/ou indirects pouvant résulter de la diffusion de l’Information non-conforme.
              </p>
              <br />
              <p>
                Tout Inliver qui considère qu’une Information publiée sur la Plateforme n’est pas conforme à
                la réglementation applicable ou aux Conditions Générales ou à la Charte des Communautés a
                la possibilité de nous signaler cette Information en utilisant l’onglet « Signaler » ou en
                adressant un mail à l’adresse <a style={ styles.a } href="privacy@inlivewith.com">privacy@inlivewith.com</a>
              </p>
              <br />
              <p>Nous examinerons l’Information signalée et, si nous constatons qu’elle a un caractère
                manifestement illicite ou si nous considérons qu’elle n’est pas conforme à la réglementation
                applicable ou aux Conditions Générales ou à la Charte des Communautés, nous procéderont à
                la suppression de l’Information. Nous nous réservons aussi le droit de supprimer, de suspendre
                ou de restreindre l’accès au Compte de l’Inliver responsable de l’Information supprimée.
              </p>
            </div>
          </div>
        </li>
      </ol>
      <br />
      <br />
      <h4 style={ styles.h4 }>18 Quelles sont nos obligations et de quoi sommes-nous responsables ?</h4>
      <br />
      <p>Nous nous engageons à fournir les Services avec diligence, étant précisé que nous sommes tenus à une
        obligation de moyens.
      </p>
      <br />
      <br />
      <h5 style={ styles.h5 }>18.1 Concernant la qualité de nos Services</h5>
      <br />
      <p>Nous faisons nos meilleurs efforts pour vous fournir des Services de qualité. A cette fin, nous procédons
        régulièrement à des contrôles afin de vérifier le fonctionnement et l’accessibilité de nos Services et
        pouvons ainsi réaliser une maintenance planifiée dans les conditions précisées à l’article
        « Maintenance ».
      </p>
      <br />
      <p>Nous ne sommes néanmoins pas responsables des difficultés ou impossibilités momentanées d’accès
        à nos Services qui auraient pour origine :
      </p>
      <ul>
        <li style={ styles.li }>- des circonstances extérieures à notre réseau (et notamment la défaillance partielle ou totale de
          vos serveurs),
        </li>
        <li style={ styles.li }>- de la défaillance d’un équipement, d’un câblage, de services ou de réseaux non inclus dans nos
          Services ou qui n’est pas sous notre responsabilité,
        </li>
        <li style={ styles.li }>- de l’interruption des Services du fait des opérateurs télécoms ou fournisseurs d’accès à internet,</li>
        <li style={ styles.li }>- de votre intervention notamment via une mauvaise configuration appliquée sur les Services,</li>
        <li style={ styles.li }>- d’un cas de force majeure.</li>
      </ul>
      <br />
      <p>Nous sommes responsables du fonctionnement de nos serveurs, dont les limites extérieures sont
        constituées par les points de raccordement.
      </p>
      <br />
      <p>Par ailleurs, nous ne garantissons pas que les Services :</p>
      <ul>
        <li style={ styles.li }>- soumis à une recherche constante pour en améliorer notamment la performance et le progrès,
          seront totalement exempts d’erreurs, de vices ou défauts,
        </li>
        <li style={ styles.li }>- étant standard et nullement proposés en fonction de vos propres contraintes personnelles,
          répondront spécifiquement à vos besoins et attentes.
        </li>
      </ul>
      <br />
      <br />
      <h5 style={ styles.h5 }>18.2 Concernant la garantie de niveau de service de la Plateforme</h5>
      <br />
      <p>
        Nous faisons nos meilleurs efforts pour maintenir un accès à la Plateforme 24h/24h et 7j/7j sauf en cas
        de maintenance planifiée dans les conditions définies à l’article « Maintenance » ou de force majeure.
      </p>
      <br />
      <br />
      <h5 style={ styles.h5 }>18.3 Concernant la sauvegarde des données sur la Plateforme</h5>
      <br />
      <p>
        Nous faisons nos meilleurs efforts pour sauvegarder toutes données produites et/ou saisies par/sur la
        Plateforme.
      </p>
      <br />
      <p>Sauf en cas de fautes avérées de notre part, nous ne sommes néanmoins pas responsables de toute
        perte de données au cours des opérations de maintenance.
      </p>
      <br />
      <br />
      <h5 style={ styles.h5 }>18.4 Concernant le stockage et la sécurité des données</h5>
      <br />
      <p>Nous vous fournissons des capacités de stockage suffisantes pour l’exploitation des Services.</p>
      <br />
      <p>Nous faisons nos meilleurs efforts pour assurer la sécurité des données en mettant en œuvre des
        mesures de protection des infrastructures et de la Plateforme, de détection et prévention des actes
        malveillants et de récupération des données.
      </p>
      <br />
      <br />
      <h5 style={ styles.h5 }>18.5 Concernant la publication de Publications</h5>
      <br />
      <p>Nous intervenons en qualité de prestataire d’hébergement pour les Publications que vous mettez en
        ligne. En conséquence, nous ne sommes pas responsables de ces Publications.
      </p>
      <br />
      <p>Si nous recevons une notification concernant une Publication illicite, nous agirons promptement pour
        le retirer ou en rendre son accès impossible, et nous pourrons prendre les mesures décrites à l’article
        « Quelles sont les sanctions en cas de manquement à vos obligations ? ».
      </p>
      <br />
      <br />
      <h5 style={ styles.h5 }>18.6 Concernant la mise en relation entre Inlivers</h5>
      <br />
      <p>
        Nous mettons à votre disposition un outil vous permettant de communiquer avec les autres Inlivers et
        des Célébrités. Nous ne pouvons donc pas engager notre responsabilité dans le cadre de vos relations
        avec les autres Inlivers et/ou Célébrités, ni être partie à quelques litiges éventuels que ce soit.
      </p>
      <br />
      <br />
      <h5 style={ styles.h5 }>18.7 Concernant la publicité que nous communiquons</h5>
      <br />
      <p>Nous pouvons publier et/ou vous envoyer tous messages publicitaires ou promotionnels en vous
        renvoyant notamment vers des plateformes tierces.
      </p>
      <br />
      <p>Nous ne sommes néanmoins pas responsables de :</p>
      <ul>
        <li style={ styles.li }>- la disponibilité technique et des contenus, produits et/ou services de ces plateformes,</li>
        <li style={ styles.li }>- vos relations nouées par l’intermédiaire de ces plateformes.</li>
      </ul>
      <br />
      <br />
      <h5 style={ styles.h5 }>18.8 Concernant le recours à la sous-traitance et le transfert de nos droits et obligations</h5>
      <br />
      <p>Nous pouvons recourir à des sous-traitants dans le cadre de l’exécution des Services, qui sont soumis
        aux mêmes obligations que les nôtres dans le cadre de leur intervention. Nous restons néanmoins
        seuls responsables de la bonne exécution des Services à votre égard.
      </p>
      <br />
      <p>
        Nous pouvons nous substituer à toute personne qui sera subrogée dans tous nos droits et obligations
        au titre de notre relation contractuelle. Nous vous informerons le cas échéant de cette substitution
        par tout moyen écrit.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>19 Dans quelles limites pouvez-vous engager notre responsabilité ?</h4>
      <br />
      <p>
        Notre responsabilité est limitée aux seuls dommages directs avérés que vous subissez du fait de
        l’utilisation de nos Services.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>20 Quels sont les modes de preuve admis entre nous ?</h4>
      <br />
      <p>La preuve peut être établie par tout moyen.</p>
      <br />
      <p>Vous êtes informé que les messages échangés par le biais de notre Plateforme ainsi que les données
        recueillies sur la Plateforme et nos équipements informatiques constituent le principal mode de
        preuve admis, notamment pour démontrer la réalité des Services réalisés et le calcul de leur prix.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>21 Comment sont traitées les données à caractère personnel dans le cadre des Services ?</h4>
      <br />
      <p>Nous pratiquons une politique de protection des données personnelles accessible
        <Link to={ '/legal#confidentiality' } style={ styles.a }> ici</Link>
        . Nous vous invitons à en prendre connaissance.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>22 Quelles sont nos obligations respectives en matière de confidentialité ?</h4>
      <br />
      <p>Sauf accord écrit de l’autre partie, nous nous engageons respectivement à garder confidentiels,
        pendant la durée de notre relation contractuelle et 3 ans au-delà, toutes les informations relatives ou
        détenus par l’autre partie, dont nous aurions eu connaissance à l’occasion de la conclusion et de
        l’exécution de notre relation contractuelle.
      </p>
      <br />
      <p>Cette obligation ne s’étend pas aux informations :</p>
      <ul>
        <li style={ styles.li }>- dont la partie qui les reçoit avait déjà connaissance,</li>
        <li style={ styles.li }>- déjà publics lors de leur communication ou qui le deviendraient sans violation de cette clause,</li>
        <li style={ styles.li }>- qui auraient été reçues d’un tiers de manière licite,</li>
        <li style={ styles.li }>- dont la communication serait exigée par les autorités judiciaires, en application des lois et
          règlements ou en vue d’établir les droits d’une partie dans la cadre de notre relation contractuelle.
        </li>
      </ul>
      <br />
      <p>
        Les informations confidentielles peuvent être transmises à nos employés, collaborateurs, stagiaires,
        mandataires et cocontractants respectifs, à condition qu’ils soient soumis à la même obligation de
        confidentialité.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>23 Force majeure</h4>
      <br />
      <p>
        Nous ne pouvons être responsables des manquements ou retards dans l’exécution de nos obligations
        contractuelles respectives dus à un cas de force majeure survenu pendant la durée de nos relations.
      </p>
      <br />
      <p>La force majeure recouvre :</p>
      <ul>
        <li style={ styles.li }>-
          tout cas répondant aux conditions de l’article 1218 du Code civil et reconnus par la
          jurisprudence,
        </li>
        <li style={ styles.li }>- les grèves, activités terroristes, émeutes, insurrections, guerres, actions gouvernementales,
          épidémies, catastrophes naturelles ou défaut imputable à un prestataire tiers de
          télécommunication.
        </li>
      </ul>
      <br />
      <p>Si l’un d’entre nous est empêché d’exécuter ses obligations pour un cas de force majeure, il doit
        informer l’autre partie par lettre recommandée avec demande d’avis de réception. Les obligations sont
        suspendues dès réception de la lettre, et devront être reprises dans un délai raisonnable dès la
        cessation du cas de force majeure.
      </p>
      <br />
      <p>Nous restons néanmoins tenus de l’exécution de nos obligations respectives qui ne sont pas affectées
        par un cas de force majeure et de toute obligation de paiement.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>24 Comment mettre fin aux Services ?</h4>
      <br />
      <h5 style={ styles.bold }>24.1 Comment pouvez-vous mettre fin aux Services ?</h5>
      <br />
      <p>Vous pouvez à tout moment décider de vous désinscrire de nos Services et de supprimer votre
        Compte, en utilisant l’onglet « Supprimer votre compte » disponible sur la Plateforme. La
        suppression de votre Compte est effective dans un délai maximum de 7 jours à compter de cette
        demande. Elle entraîne la suppression automatique de votre Compte.
      </p>
      <br />
      <p>Les Publications seront conservées pendant un délai d’un mois par nos soins à compter de la
        demande. A l’issue de ce délai, les Publications seront supprimées.
      </p>
      <br />
      <br />
      <h5 style={ styles.bold }>24.2 Comment pouvons-nous mettre fin aux Services ?</h5>
      <br />
      <p>Dans l’hypothèse où nous supprimons votre Compte dans les conditions de l’article « Quelles sont
        les sanctions en cas de manquement à vos obligations » nous vous informerons de la raison pour
        laquelle votre Compte a été supprimé, suspendu ou restreint, sauf si une telle information serait
        susceptible de violer nos obligations légales.
        Nous vous donnerons alors la possibilité d’exporter les Publications téléchargées par vous vers la
        Plateforme dans un délai raisonnable ne pouvant pas dépasser 15 jours à compter de la
        notification que nous vous adresserons par email à ce sujet.
      </p>
      <br />
      <p>La suppression de votre Compte implique que vous n’aurez plus la possibilité d’utiliser la
        Plateforme et de procéder à toute Commande. Les données attachées à votre Compte seront
        supprimées dans un délai de 15 jours suivant la demande de suppression de votre Compte.
        Toutefois, comme cela est précisé dans les présentes Conditions Générales, vos Publications
        publiées avant la suppression demeureront accessibles et pourront être utilisés par les autres
        Inlivers et par nous, dans les limites des autorisations que vous nous avez accordées par les
        présentes Conditions Générales. La suspension de votre Compte a, pendant sa durée, les mêmes
        conséquences.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>25 Quelles sont les sanctions en cas de manquement à vos obligations ?</h4>
      <br />
      <p>
        Les obligations prévues à l’article « Quelles sont vos obligations et de quoi êtes-vous responsable ? »
        constituent des obligations essentielles.
      </p>
      <br />
      <p>En cas de manquement à ces obligations, nous pouvons :</p>
      <ul>
        <li style={ styles.li }>- suspendre ou supprimer votre accès aux Services,</li>
        <li style={ styles.li }>- supprimer toute Publication en lien avec le manquement,</li>
        <li style={ styles.li }>- publier sur la Plateforme tout message d’information que nous jugeons utile,</li>
        <li style={ styles.li }>
          <p>vous envoyer une lettre recommandée avec accusé de réception pour :</p>
          <ul>
            <li style={ styles.li }>~ suspendre ou supprimer votre Compte, la suspension ou la suppression prenant effet au
              jour de réception ou de première présentation de cette lettre,
            </li>
            <li style={ styles.li }>~ ou pour vous demander de remédier au manquement dans un délai maximum de 15 jours
              calendaires. La suppression de votre Compte prendra effet à l’issue de ce délai à défaut de
              régularisation du manquement.
            </li>
          </ul>
        </li >
        <li style={ styles.li }>- avertir toute autorité compétente, coopérer avec elle et lui fournir toutes les informations utiles
          à la recherche et à la répression d’activités illégales ou illicites,
        </li>
        <li style={ styles.li }>- engager toute action judiciaire.</li>
      </ul>
      <br />
      <p>Ces sanctions sont sans préjudice de tous dommages et intérêts que nous pourrions vous réclamer.</p>
      <br />
      <br />
      <h4 style={ styles.h4 }>26 Comment pouvons-nous modifier nos Conditions Générales ?</h4>
      <br />
      <p>Nous pouvons modifier nos Conditions Générales à tout moment. Les Conditions Générales modifiées
        sont applicables dès leur entrée en vigueur. Vous êtes donc invités à consulter régulièrement sur la
        Plateforme la dernière version en vigueur de nos Conditions Générales.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>27 Quelle langue prévaut en cas de différends sur l’interprétation des Conditions Générales ?</h4>
      <br />
      <p>La langue française prévaut en cas de contradiction ou de contestation sur la signification d’un terme
        ou d’une disposition.
      </p>
      <br />
      <br />
      <h4 style={ styles.h4 }>28 Médiation</h4>
      <br />
      <p>En cas de litige nous opposant, vous pouvez recourir gratuitement au médiateur de la consommation
        suivant en vue d’une résolution amiable :
      </p>
      <br />
      <p>Centre de médiation de la consommation de conciliateurs de justice (CM2C)</p>
      <div>
        Adresse postale : 14 rue Saint Jean 75017 Paris
        <br />
        Téléphone : 06 09 20 48 86
        <br />
        <a style={styles.a} href=" https://www.cm2c.net"> https://www.cm2c.net</a>
      </div>
      <br />
      <br />
      <h4 style={ styles.h4 }>29 Loi applicable</h4>
      <br />
      <p>Nos Conditions Générales sont régies par la loi française.</p>
      <br />
   		</>
		</ThemeProvider>
		</div>
		</div>
	)
}
export default CGV;