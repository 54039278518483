import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../../../constants';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { closePopup } from '../../../../redux/popup/actions';
import logoImgSmall from '../../../../assets/img/playstore.png';
import logoImgTall from '../../../../assets/img/logo-white.png';
import LockIcon from '@material-ui/icons/Lock';
import LiveCheckoutForm from './liveCheckoutForm';
import { useHistory } from 'react-router-dom';

const stripePromise = loadStripe((process.env.REACT_APP_STAGE === "production") ? constants.STRIPE_PROD : constants.STRIPE_TEST);

const LivePayment = ({ setShowLoader }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { liveProduct: product } = useSelector(state => state.productReducer);
	const [isSuccess, setIsSuccess] = useState(false);

	const handleBox = () => {
		if(isSuccess){
			history.push('/LiveEvent')
		}else{
			dispatch(closePopup())
		}
	  }

	return (
		<>
			<div className="popup-content-close">
				<button
					className="popup-content-close-button"
					onClick={handleBox}
				/>
			</div>
			<div className="popup-content-logo">
				<img
					className="popup-content-logo-sm"
					src={logoImgSmall}
					alt="logo Inlive Stream"
				/>
				<img className="popup-content-logo-xl" src={logoImgTall} alt="logo Inlive Stream" />
			</div>

			{isSuccess && (
				<>
					<div className="popup-content-title">Merci pour votre achat !</div>
					<div className="popup-content-text">
						Une confirmation par e-mail vous a été envoyée.
					</div>
					<div className="popup-content-thumbnail">
						<img src={product.img} alt={product.titre} />
					</div>
				</>
			)}

			<div className="popup-content-product">
				<div className="popup-content-product-line">
					<div className="popup-content-product-left --title">
						{product.titre}
					</div>
					<div className="popup-content-product-right --price">
						{product?.price} &euro;
					</div>
				</div>
				<div className="popup-content-product-line">
					<div className="popup-content-product-left --quantity">{product.categorie}</div>
				</div>
			</div>
			<div className="popup-content-separator"></div>
			{isSuccess ? (
				<></>
			) : (
				<>
					<div className="popup-content-title">Payer par carte</div>
					<Elements stripe={stripePromise}>
						<LiveCheckoutForm
							product={product}
							setIsSuccess={setIsSuccess}
							setShowLoader={setShowLoader}
						/>
					</Elements>
					<div className="popup-content-payment-secured">
						<LockIcon /> Paiement sécurisé
					</div>
				</>
			)}


		</>
	)
}

export default LivePayment; 