import React, { Component } from "react";
import HTMLFlipBook from "react-pageflip";
import  ReplayIcon  from "@material-ui/icons/Replay";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from '@material-ui/icons/Pause';

import { ReactComponent as IconNext } from '../../../../assets/icon/next.svg';
import { ReactComponent as IconPrevious } from '../../../../assets/icon/previous.svg';
import { ReactComponent as IconVolumeOn } from '../../../../assets/icon/volume-on.svg';
import { ReactComponent as IconVolumeOff } from '../../../../assets/icon/volume-muted.svg';
import { ReactComponent as IconVisible } from '../../../../assets/icon/visible.svg';
import { ReactComponent as IconNotVisible } from '../../../../assets/icon/not-visible.svg';
import { ReactComponent as IconZoomIn } from '../../../../assets/icon/zoom-in.svg';
import { ReactComponent as IconZoomOut } from '../../../../assets/icon/zoom-out.svg';
import { withRouter } from 'react-router-dom';
import Image from '../../../../shared/image';

class Book extends Component {

	static OUTER_SHADOW_L = 'left';
	static OUTER_SHADOW_R = 'right';
	static ZOOM_STEP = [1, 1.2, 1.4, 1.6, 1.8, 2];

	static BOOK_FLIP_TIME = 700;
	static PAGE_RATIO = 1.114585;

	constructor(props) {
		super(props);
		this.state = {
			audioLoading: false,
			audioPlaying: false,
			audioWaitingList: false,
			barMinimized: false,
			bookSize: { height: null, width: null },
			bookSizeCalculated: false,
			currentPageIndex: 0,
			device: null,
			error: false,
			isLoaded: false,
			isDoublePage: false,
			pageHasAudio: false,
			pagesLoaded: false,
			orientation: null,
			playStarted: false,
			screenOrientationDefined: false,
			timerWaitingList: false,
			volumeActive: true,
			zoomIndex: 0
		};

		this.reader = React.createRef();
		this.scrollable = React.createRef();
		this.book = React.createRef();
		this.bookContainer = React.createRef();
		this.progressbar = React.createRef();
		this.progressMarker = React.createRef();

		this.audio = new Audio();
		this.initAudioEventListeners();

		this.pagesLoaded = [];
		this.timeouts = [];
		this.bookData = this.props.book;
		this.pages = this.props.pages;

		this.keyDownListener = this.keyDownListener.bind(this);
		this.onClickPrevious = this.onClickPrevious.bind(this);
		this.onClickNext = this.onClickNext.bind(this);
		this.mouseDownOnZoomed = this.mouseDownOnZoomed.bind(this);
		this.detectOrientation = this.detectOrientation.bind(this);
		this.touchStartHandlerOnMoved = this.touchStartHandlerOnMoved.bind(this);
	}

	cancelTimeOuts() {
		this.timeouts.forEach(timeout => clearTimeout(timeout));
	}

	/**
	 * Define state currentPageIndex if not set, or go to page in state on book init
	 * @param {*} event
	 */
	initBook(event) {
		if (this.book) {
			const book = this.book.pageFlip();
			if (book) {
				this.delay(0.5).then(() => {
					book.turnToPage(
						this.state.currentPageIndex > 0 ? this.state.currentPageIndex - 1 : 0
					);
				});
			}
			this.setState({
				isDoublePage:
					this.state.currentPageIndex > 0 ||
					this.state.currentPageIndex < this.pages.length - 1,
				leftPage: this.pages[this.state.currentPageIndex],
				rightPage: this.state.isDoublePage
					? this.pages[this.state.currentPageIndex + 1]
					: null,
				pageHasAudio:
					this.pages[this.state.currentPageIndex]?.hasAudio ||
					this.pages[this.state.currentPageIndex + 1]?.hasAudio
			});
			if (this.state.pageHasAudio && this.pages[this.state.currentPageIndex].mediaAudio) {
				this.setAudioSrc(
					this.pages[this.state.currentPageIndex].mediaAudio.filename +
						'.' +
						this.pages[this.state.currentPageIndex].mediaAudio.extension
				);
			}
		}
	}



	initAudioEventListeners() {
		this.audio.addEventListener('canplaythrough', e => {
				if (this.state.playStarted && !this.state.audioPlaying) {
						this.audio.play();
				}
		});

		this.audio.addEventListener('ended', e => {
				if (this.state.audioWaitingList) {
						this.setAudioSrc(this.state.audioWaitingList);
						this.audio.load();
						this.audio.play();
						this.setState({ audioWaitingList: false });
				} else if (this.state.timerWaitingList) {
						this.delay(this.state.timerWaitingList).then(() => {
								this.onClickNext(false);
						});
						this.setState({ audioWaitingList: false, pageHasAudio: false });
				} else {
						this.audio.pause();
						this.setState({ audioWaitingList: false, pageHasAudio: false });
						this.onClickNext(false);
				}
		});

		this.audio.addEventListener('waiting', e => {
				this.setState({ audioLoading: true });
		});

		this.audio.addEventListener('playing', e => {
				this.setState({ audioLoading: false, audioPlaying: true });
		});

		this.audio.addEventListener('pause', e => {
				this.setState({ audioPlaying: false });
		});

		this.audio.addEventListener('error', e => {
				this.setState({ audioPlaying: false });
		});
}

async togglePlayPause() {
		//turn to first page if click play on last page
		if (
				!this.state.playStarted &&
				this.state.currentPageIndex + 1 >= this.book.pageFlip().getPageCount()
		) {
				this.book.pageFlip().flip(0);
				return;
		}

		if (this.state.playStarted && this.state.audioPlaying) {
				this.audio.pause();
				this.cancelTimeOuts();
		} else if (this.state.pageHasAudio && !this.state.audioPlaying) {
				this.audio.play();
		} else {
				this.delay(this.state.leftPage[this.state.currentPageIndex]).then(() => {
						if (this.state.playStarted) {
								this.onClickNext(false);
						}
				});
		}

		this.setState({ playStarted: !this.state.playStarted });
}

detectOrientation() {
		// const bookSize = this.calcBookSize();
		let device = 'computer';
		let orientation = 'landscape';

		try {
				device = !!navigator.maxTouchPoints ? 'mobile' : 'computer';
				orientation = !navigator.maxTouchPoints
						? 'desktop'
						: !window.screen.orientation.angle
						? 'portrait'
						: 'landscape';
		} catch (error) {
				let mql = window.matchMedia('(orientation: portrait)');
				orientation = mql.matches ? 'portrait' : 'landscape';
		}
		let newState = {
				device: device,
				orientation: orientation
		};
		if (this.state.orientation !== orientation)
				newState = { ...newState, bookSize: this.calcBookSize(), bookSizeCalculated: true };

		this.setState(newState);
}

/**
 * Flip next / previous on left / right arrows keydown
 * @param {KeyboardEvent} evnt
 */
keyDownListener(evnt) {
		switch (evnt.keyCode) {
				case 39: //RIGHT ARROW
						this.onClickNext();
						break;
				case 37: //RIGHT ARROW
						this.onClickPrevious();
						break;
				default:
						return;
		}
}

/**
 * Change current page number state,
 * Update progress bar
 * @param {Event} event
 */
onPageFlip(event) {
		this.setCurrentIndex(event.data);
		this.updateProgressBar();

		//Init double page data
		this.setState({
				leftPage: this.pages[event.data],
				rightPage: this.pages[event.data + 1]
		});

		if (event.data + 1 >= this.book.pageFlip().getPageCount()) {
				if (this.state.audioPlaying) {
						this.audio.pause();
				}
				this.setState({ playStarted: false });
		}

		this.initPageAudio({
				leftPage: this.pages[event.data],
				rightPage: this.pages[event.data + 1]
		});
}

onBookChangeState(event) {
		// Pause audio on page flipping or user foldin page
		if (['flipping', 'user_fold'].includes(event.data) && this.state.audioPlaying) {
				this.audio.pause();
		}
		// Resume playing after user fold and stay on same page
		else if (event.data === 'read' && this.state.playStarted && !this.state.audioPlaying) {
				this.audio.play();
		}
}

delay(n = 2) {
		this.cancelTimeOuts();
		return new Promise(done => {
				this.timeouts.push(
						setTimeout(() => {
								done();
						}, n * 1000)
				);
		});
}

toggleVolume() {
		this.audio.muted = this.state.volumeActive;
		this.setState({ volumeActive: !this.state.volumeActive });
}

toggleShowBar() {
		this.setState({ barMinimized: !this.state.barMinimized });
}

setAudioSrc(url, play = false) {
		try{
				
				this.audio.pause();
				this.audio.src = url ? "https://vcdn.inlive-stream.com/ilw/000_media/" + url : null; //s3Service(url) : null;
				this.audio.load();
				if (play) {
						if (this.state.playStarted) this.audio.play();
				}
				this.setState({ pageHasAudio: !!url });
		}catch(e){
				console.log("erreur e: ", e);
		}
}

setAudioWaitingList(url = false) {
		this.setState({ audioWaitingList: url });
}

setTimerWaitingList(time = false) {
		this.setState({ timerWaitingList: time });
}

//FIXME: init audio for one or two pages
initPageAudio(pages) {
		if (this.state.audioPlaying) {
				this.audio.pause();
		}

		//Left page audio
		if (pages.leftPage && pages.leftPage.mediaAudio && pages.leftPage.mediaAudio.filename) {
				this.setAudioSrc(
						pages.leftPage.mediaAudio.filename + '.' + pages.leftPage.mediaAudio.extension,
						true
				);

				//right page audio
				if (
						pages.rightPage &&
						pages.rightPage.mediaAudio &&
						pages.rightPage.mediaAudio.filename
				) {
						this.setAudioWaitingList(
								pages.rightPage.mediaAudio.filename + '.' + pages.rightPage.mediaAudio.extension
						);
				}
				//right page no audio
				else if (
						pages.rightPage &&
						pages.rightPage.noAudioTimer &&
						Number.isInteger(parseInt(pages.rightPage.noAudioTimer))
				) {
						this.setTimerWaitingList(parseInt(pages.rightPage.noAudioTimer));
				} else {
						this.setTimerWaitingList(3);
				}
		}
		//Left page no audio
		else {
				this.setAudioSrc(null);

				this.delay(
						this.state.leftPage.noAudioTimer &&
								Number.isInteger(parseInt(this.state.leftPage.noAudioTimer))
								? parseInt(this.state.leftPage.noAudioTimer)
								: 3
				).then(() => {
						if (
								pages.rightPage &&
								pages.rightPage.mediaAudio &&
								pages.rightPage.mediaAudio.filename
						) {
								this.setAudioSrc(
										pages.rightPage.mediaAudio.filename +
												'.' +
												pages.rightPage.mediaAudio.extension,
										true
								);
								this.setAudioWaitingList();
						} else if (
								pages.rightPage &&
								pages.rightPage.noAudioTimer &&
								Number.isInteger(parseInt(pages.rightPage.noAudioTimer))
						) {
								this.delay(parseInt(pages.rightPage.noAudioTimer)).then(() =>
										this.onClickNext(false)
								);
						} else {
								this.delay(3).then(() => this.onClickNext(false));
						}
				});
		}

		if (
				this.state.rightPage &&
				this.state.rightPage.mediaAudio &&
				this.state.rightPage.mediaAudio.filename
		) {
				this.setState({
						audioWaitingList:
								this.state.rightPage.mediaAudio.filename +
								'.' +
								this.state.rightPage.mediaAudio.extension
				});
		} else if (this.state.rightPage && Number.isInteger(this.state.rightPage.noAudioTimer)) {
				this.setState({ timerWaitingList: this.state.rightPage.noAudioTimer });
		}
}

setCurrentIndex(i) {
		this.setState({ currentPageIndex: i > 0 ? i + 1 : i });
}

updateProgressBar() {
		if (!this.progressbar) return;
		let percent = (100 * this.state.currentPageIndex) / this.pages.length;
		this.progressbar.style.background =
				'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) ' +
				percent +
				'%, rgba(148,147,146,1) ' +
				percent +
				'%, rgba(148,147,146,1) 100%)';
		this.progressMarker.style.left = percent * 0.995 + '%';
}

calcBookSize() {
		this.setState({ bookSizeCalculated: false });
		const win = { height: window.innerHeight, width: window.innerWidth };
		let book = { height: null, width: null };

		book.height = Math.floor(win.height * 0.86);
		book.width = Math.floor(book.height * this.bookData.ratio);
		if (book.width * 2 > win.width) {
				book.width = Math.floor((win.width * 0.95) / 2);
				book.height = Math.floor(book.width / this.bookData.ratio);
		}
		return book;
}

onClickPrevious() {
		const book = this.book.pageFlip();
		if (book.getCurrentPageIndex() <= 0) {
				book.flip(book.getPageCount() - 1);
		} else {
				book.flipPrev();
		}
		this.cancelTimeOuts();
}

onClickNext(infinite = true) {
		const book = this.book.pageFlip();
		if (infinite && book.getCurrentPageIndex() + 1 >= book.getPageCount()) {
				book.flip(0);
		} else {
				book.flipNext();
		}
		this.cancelTimeOuts();
}

onClickFirstPage() {
		this.setState({ audioPlaying: false, audioWaitingList: false, playStarted: false });
		this.audio.pause();
		this.cancelTimeOuts();

		const book = this.book.pageFlip();
		this.delay(0.5).then(() => {
				book.flip(0);
		});
}

mouseDownOnZoomed(e) {
		const bookPos = {
				left: this.scrollable.scrollLeft,
				top: this.scrollable.scrollTop
		};
		const mousePos = {
				x: e.clientX,
				y: e.clientY
		};

		const mouseUpHandler = e => {
				window.removeEventListener('mousemove', mouseMoveHandler);
				window.removeEventListener('mouseup', mouseUpHandler);
		};

		const mouseMoveHandler = e => {
				const dx = e.clientX - mousePos.x;
				const dy = e.clientY - mousePos.y;
				this.scrollable.scrollLeft = bookPos.left - dx;
				this.scrollable.scrollTop = bookPos.top - dy;
		};

		window.addEventListener('mousemove', mouseMoveHandler);
		window.addEventListener('mouseup', mouseUpHandler);
}

touchStartHandlerOnMoved(e) {
		if (e.touches.length <= 0 || !this.scrollable) return;

		var touch = e.touches[0];

		const bookPos = {
				left: this.scrollable.scrollLeft,
				top: this.scrollable.scrollTop
		};
		const touchPos = {
				x: touch.pageX,
				y: touch.pageY
		};

		const touchMoveHandler = e => {
				const dx = e.changedTouches[0].clientX - touchPos.x;
				const dy = e.changedTouches[0].clientY - touchPos.y;
				this.scrollable.scrollLeft = bookPos.left - dx;
				this.scrollable.scrollTop = bookPos.top - dy;
		};

		const touchEndHandler = e => {
				window.removeEventListener('touchmove', touchMoveHandler);
				window.removeEventListener('touchend', touchEndHandler);
				window.removeEventListener('touchcancel', touchEndHandler);
				window.removeEventListener('touchleave', touchEndHandler);
		};

		this.book.pageFlip().block.addEventListener('touchmove', touchMoveHandler);
		this.book.pageFlip().block.addEventListener('touchend', touchEndHandler);
		this.book.pageFlip().block.addEventListener('touchcancel', touchEndHandler);
		this.book.pageFlip().block.addEventListener('touchleave', touchEndHandler);

		e.preventDefault();
		e.stopPropagation();
}

zoomIn() {
		if (this.state.zoomIndex === 0) {
				this.bookContainer.addEventListener('mousedown', this.mouseDownOnZoomed);
				this.book
						.pageFlip()
						.block.addEventListener('touchstart', this.touchStartHandlerOnMoved);
		}

		let newIndex =
				this.state.zoomIndex + 1 >= Book.ZOOM_STEP.length - 1
						? Book.ZOOM_STEP.length - 1
						: this.state.zoomIndex + 1;

		this.book.pageFlip().setting.useMouseEvents = false;
		this.setZoom(Book.ZOOM_STEP[newIndex], this.bookContainer);
		this.setState({ zoomIndex: newIndex });
}

zoomOut() {
		let newIndex = 0;

		if (this.state.zoomIndex <= 1) {
				this.book.pageFlip().setting.useMouseEvents = true;
				this.bookContainer.removeEventListener('mousedown', this.mouseDownOnZoomed);
				this.book
						.pageFlip()
						.block.removeEventListener('touchstart', this.touchStartHandlerOnMoved);
		} else {
				newIndex = this.state.zoomIndex - 1;
		}

		this.setState({ zoomIndex: newIndex });
		this.setZoom(Book.ZOOM_STEP[newIndex], this.bookContainer);
}

/**
 * TODO: adjust right & bottom margins
 * @param {*} zoom
 * @param {*} el
 */
setZoom(zoom, el) {
		let transformOrigin = [(zoom - 1) * 10, (zoom - 1) * 10];

		if (transformOrigin[0] <= 0) {
				el.style = '';
				return;
		}

		var p = ['webkit', 'moz', 'ms', 'o'],
				s = 'scale(' + zoom + ')',
				oString = transformOrigin[0] + '% ' + transformOrigin[1] + '%';

		for (var i = 0; i < p.length; i++) {
				el.style[p[i] + 'Transform'] = s;
				el.style[p[i] + 'TransformOrigin'] = oString;
		}

		el.style['transform'] = s;
		el.style['transformOrigin'] = oString;
		el.style.left = transformOrigin[0] + '%';
		el.style.top = transformOrigin[1] + '%';
}

handleBookImageLoaded(index) {
		this.pagesLoaded.push(index);
		if (this.pagesLoaded.length >= this.pages.length) {
				this.setState({ pagesLoaded: true });
		}
}

handleBookImageError(index) {
		this.setState({ error: 'Erreur de chargement du livre. Veuillez réessayer.' });
		this.timeouts.push(
				setTimeout(() => {
						if (typeof this.props.exitReader === 'function') {
								this.props.exitReader();
						}
				}, 3)
		);
}

getBarClasses() {
		let classes = 'reader__bar';

		if (this.props.isWebView) classes += ' --mobile';
		if (this.state.barMinimized) classes += ' --minimized';

		return classes;
}

componentDidMount() {
		this.detectOrientation();
		window.addEventListener('keydown', this.keyDownListener);
		window.addEventListener('resize', this.detectOrientation);
		this.setState({ isLoaded: true, bookSize: this.calcBookSize(), bookSizeCalculated: true });
}

componentWillUnmount() {
		window.removeEventListener('keydown', this.keyDownListener);
		window.removeEventListener('resize', this.detectOrientation);
		this.cancelTimeOuts();

		this.audio.pause();
		this.audio.removeAttribute('src');
		this.audio.load();
}

render() {
		return (
				<>
						<div className="reader__scrollable" ref={ref => (this.scrollable = ref)}>
								<div className="reader__book" ref={ref => (this.bookContainer = ref)}>
										{!this.props.isWebView && (
												<>
														<span
																className="reader__book__icon-container --previous"
																onClick={e => this.onClickPrevious()}>
																<IconPrevious className="reader__book__icon" />
														</span>
														<span
																className="reader__book__icon-container --next"
																onClick={e => this.onClickNext()}>
																<IconNext className="reader__book__icon" />
														</span>
												</>
										)}
										<HTMLFlipBook
												ref={ref => (this.book = ref)}
												height={this.state.bookSize.height}
												width={this.state.bookSize.width}
												autoSize={true}
												flippingTime={Book.BOOK_FLIP_TIME}
												showCover={true}
												onFlip={e => this.onPageFlip(e)}
												onInit={e => this.initBook(e)}
												onChangeState={e => this.onBookChangeState(e)}
												startZIndex={10}
												usePortrait={false}
												disableFlipByClick={true}
												swipeDistance={30}>
												{this.pages.map((page, i) => (
														<div
																number={i}
																className={i + 1 >= this.pages.length ? '--last' : ''}
																key={i}>
																<Image
																		className="book-page-image"
																		filename={page.mediaImage.filename}
																		format={page.mediaImage.extension}
																		width={797}
																		height={715}
																		onLoad={e => this.handleBookImageLoaded(i)}
																/>
														</div>
												))}
										</HTMLFlipBook>
								</div>
						</div>
						<div className={this.getBarClasses()}>
								{/* {this.props.isWebView && (
										<span className="reader__bar__group --left">
												{this.state.barMinimized && (
														<IconVisible
																className="reader__bar__icon --visible"
																onClick={() => this.toggleShowBar()}
														/>
												)}
												{!this.state.barMinimized && (
														<IconNotVisible
																className="reader__bar__icon --not-visible"
																onClick={() => this.toggleShowBar()}
														/>
												)}
										</span>
								)} */}
								<span className="reader__bar__group">
										<ReplayIcon
												className="reader__bar__icon --restart --circled"
												onClick={() => this.onClickFirstPage()}
										/>
								</span>
								<span className="reader__bar__group">
										{/* {this.props.isWebView && (
												<IconPrevious
														className="reader__bar__icon --previous --circled"
														onClick={e => this.onClickPrevious()}
												/>
										)} */}
										{
												(!this.state.playStarted && (
														<PlayArrowIcon
																className="reader__bar__icon --play --circled"
																onClick={() => this.togglePlayPause()}
														/>
												)) ||
														(this.state.playStarted /* !this.state.audioLoading && */ && (
																<PauseIcon
																		className="reader__bar__icon --pause --circled"
																		onClick={() => this.togglePlayPause()}
																/>
														)) /* ||
								( 
								this.state.playStarted && this.state.audioLoading && 
								<IconLoading className="reader__bar__icon --loading --circled" onClick={ () => this.togglePlayPause() }/>
								) */
										}
										{/* <IconLoading className="reader__bar__icon --loading --circled"/> */}
										{this.props.isWebView && (
												<IconNext
														className="reader__bar__icon --next --circled"
														onClick={e => this.onClickNext()}
												/>
										)}
								</span>
								<span className="reader__bar__group --grow">
										<span className="reader__bar__count">
												{this.state.currentPageIndex + '/' + this.pages.length}
										</span>

										<span className="reader__bar__progress">
												<span
														className="reader__bar__progress--background"
														ref={ref => (this.progressbar = ref)}></span>
												<span
														className={
																'reader__bar__progress--marker' +
																(this.props.isWebView ? ' --mobile' : '')
														}
														ref={ref => (this.progressMarker = ref)}></span>
										</span>
								</span>
								<span className="reader__bar__group">
										<IconZoomOut
												className={
														'reader__bar__icon --zoom-out' +
														(this.state.zoomIndex <= 0 ? ' --disabled' : '')
												}
												onClick={e => this.zoomOut()}
										/>
										<IconZoomIn
												className={
														'reader__bar__icon --zoom-in' +
														(this.state.zoomIndex >= Book.ZOOM_STEP.length - 1
																? ' --disabled'
																: '')
												}
												onClick={e => this.zoomIn()}
										/>
								</span>
								<span className="reader__bar__group">
										{this.state.volumeActive ? (
												<IconVolumeOn
														className="reader__bar__icon --volume-on"
														onClick={() => this.toggleVolume()}
												/>
										) : (
												<IconVolumeOff
														className="reader__bar__icon --volume-on"
														onClick={() => this.toggleVolume()}
												/>
										)}
								</span>
						</div>
				</>
		);
}


}

export default withRouter(Book);