import React from 'react'
import {createPortal} from 'react-dom'
import imgMedia from "../../assets/img/instagram-live.png"
import Boutton from '../Boutton/Boutton'

export default function Index({onClose, onJoin, live}) {
  return <div style={{ minHeight: "300px", height: "auto", minWidth:"250px",
     width:"50vw", borderRadius:"15px", backgroundColor:"#1D1D1D",
     position:"absolute", padding:"45px 35px",
     top: "20%", left: "25%", overflow:"hidden"
     }}>
        <div style={{ position:"absolute", top:"-50%", right:"-25%",
         width: "500px", height: "500px", borderRadius:"500px", backgroundColor:"#000000" }}>
        </div>
        <img src={imgMedia} alt="" style={{ position:"absolute", top:0, right:0, width:"250px", height:"auto" }} />

        <div style={{ position: "relative" }}>
            <h2 style={{fontSize:"45px", width:"240px"}}>{live.titre}</h2>
            <div style={{ height: "6px", width:"100px", backgroundColor:"white", borderRadius: "20px",
            marginTop: "40px"
        }}>
        </div>
        <div style={{marginTop:"50px", width:"330px"}}>
        Le {live?.titre} a débuté. 
        Souhaitez-vous accéder au Live ?
        </div>
        <div style={{ marginTop:"50px" }}>
            <Boutton text="Pas maintenant" action={onClose} style={{ borderRadius:"7px", backgroundColor:"#ebc2c200",
            border:"solid", borderWidth:"3px",textTransform:"capitalize", fontSize:"20px" ,borderColor:"#522787",
            marginRight: "30px", width:"200px"}}/>
            <Boutton text="Rejoindre" style={{ borderRadius:"7px", backgroundColor:"#522787",
            border:"solid", borderWidth:"3px",textTransform:"capitalize", fontSize:"20px" ,borderColor:"#522787",
            marginRight: "30px", width:"200px"}} action={onJoin}/>
        </div>

        </div>
    </div>
  
}
