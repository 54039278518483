import axios from 'axios';
import constants from '../constants';

class ApiService {

	token = null;


	constructor() {
		this.instance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				'Authorization': "Bearer " + constants.JWTtoken()
			},
			baseURL: constants.URL_API
		});
		this.interceptor();
	}

	interceptor = async () => {
		/*
		this.instance.interceptors.response.use(
			response => response,
			error => {
				//console.log("Error : ", error);
				return error.response.data; //Promise.reject(
					//(error.response && error.response.data ) || "Something went wrong"
				//);
			}
		);
		*/
	};

	static getInstance() {
		if(!this.instance) {
			this.instance = new ApiService();
		}
		return this.instance;
	}
	

	paramToString(data){
    let str = '';
    if (typeof data === 'undefined' || data === null) return str;
    Object.keys(data).forEach((item, index) => {
        if (index > 0) {
            str = `${str}&${item}=${data[item]}`;
        } else {
            str = `${str}?${item}=${data[item]}`;
        }
    });
    return str;
	};

	setApiToken = (token) => {
		this.token = token;
		this.instance.defaults.headers.common['Authorization'] = "Bearer " + token;
	}

	get = (url, params) =>
		this.instance.get(`${url}${this.paramToString(params)}`);

	post = (url, data) =>
		this.instance.post(url,data);
	
	put = (url, data) =>
		this.instance.put(url,data, { headers: {'Content-Type': 'application/ld+json'}});

	patch = (url,data) =>
		this.instange.patch(url,data,{headers: {'Content-Type': 'application/merge-patch+json'}});
	
	delete = url => this.instance.delete(url);

}

export default ApiService.getInstance();