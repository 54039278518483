import React from 'react';
import Boutton from '../Boutton/Boutton';
import { useDispatch } from 'react-redux';
import { openPopup } from "../../redux/popup/actions";
import { popupNames } from "../../constants";
import { fetchLive } from "../../redux/product/actions";
import { useHistory } from 'react-router-dom';

export default function ReserveCards({ categorie, prix, packId, stream, type, chat_enabled, chat_description, prebackstage_enabled, prebackstage_description, postbackstage_enabled, postbackstage_description, merchandising_enabled, merchandising_description }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBox = (event) => {
    history.push({
      pathname: '/Payments',
      state: { data: stream, pack: { categorie: categorie, prix: prix } }
    });
  }
  const btnPaymentClick = () => {
    let formatData = {
      price: prix,
      categorie: categorie,
      titre: stream.titre,
      video_id: stream.video_id,
      id: stream.id,
      pack_id:packId

    };
    dispatch(fetchLive(formatData));
    dispatch(openPopup(popupNames.livePayment));
  }

  return (
    <div style={{
      background: "rgb(161,65,180)", marginBottom: 20, marginRight: 10,
      background: "linear-gradient(160deg, rgba(161,65,180,1) 0%, rgba(82,39,135,1) 32%, rgba(82,39,135,1) 100%)",
      minWidth: "200px", height: 'auto', padding: "20px 18px", borderRadius: "32px"
    }}>
      <div style={{ fontSize: "28px", fontWeight: "bold", textAlign: 'center' }}>{categorie}</div>
      <div style={{ fontSize: "16px", fontWeight: "lighter", textAlign: 'center' }}>{prix}<sup>€</sup>/place</div>
      <ul style={{ marginTop: '15px', marginBottom: "20px", listStyle: 'outside' }}>
        <li style={{ marginTop: 10 }}>Live stream</li>
        {chat_enabled === "1" && <li style={{ marginTop: 10 }}>{chat_description}</li>}
        {prebackstage_enabled == "1" && <li style={{ marginTop: 10 }}>{prebackstage_description}</li>}
        {postbackstage_enabled == "1" && <li style={{ marginTop: 10 }}>{postbackstage_description}</li>}
        {merchandising_enabled == "1" && <li style={{ marginTop: 10 }}>{merchandising_description}</li>}
      </ul>
      {type != "free" &&
      <Boutton text={`Reserver ${prix}€`} style={{ width: '100%', borderRadius: 5, fontSize: 18 }} bandeau={stream} action={btnPaymentClick} />
      }
    </div>
  );
}
