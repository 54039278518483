import React, { useEffect, useRef, useState } from "react";
import constants from "../../constants";
import { useSelector } from "react-redux";

function VideoPlayer({
  src,
  controls = true,
  height = "100vh",
  playerMuted = false,
  playerPlaying = true,
}) {
  // Set the mpeg-dash stream URL.
  const playerRef = useRef(null);
  const urlReadVod = `${constants.URL_API}/vods/readVod`;
  const token = constants.JWTtoken()
  const videoName = src;
  const meUser = useSelector( state => state.userReducer.userInfo);

  const body = {
    mediaId: videoName,
    email:meUser?.data?.email,
    uuid:meUser?.uid,
    firebaseUid:meUser?.firebaseid
  };

  useEffect(() => {
    fetch(`${constants.URL_API}/vods/signCookie?mediaId=${videoName}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((resp) => {
      fetch(urlReadVod, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          const dashStreamURL = response.data.data.media.dashPath;
          // Set the hls stream URL.
          const hlsStreamURL = response.data.data.media.hlsPath;

          // Set the URL to retrieve the fairplay certificate from.
          const fairplayCertURL = response.data.data.fairplay_cert_url;
          const fairplayServerUrl = response.data.data.fairplay_sever_url;

          // Please login to https://admin.vudrm.tech to generate a VUDRM token.
          const vudrmToken = response.data.data.token;
          window.jwplayer(playerRef.current).setup({
            // width: "100vw",
            height: height,
            aspectratio: '16/9',
            autostart: true,
            mute: playerMuted,
            controls: controls,
            repeat: true,
            stretching: "exactfit",
            primary: "html5",
            playlist: [
              {
                sources: [
                  {
                    file: dashStreamURL,
                    drm: {
                      widevine: {
                        url: "https://widevine-license.vudrm.tech/proxy",
                        headers: [
                          {
                            name: "X-VUDRM-TOKEN",
                            value: vudrmToken,
                          },
                        ],
                      },
                      playready: {
                        url: "https://playready-license.vudrm.tech/rightsmanager.asmx",
                        headers: [
                          {
                            name: "X-VUDRM-TOKEN",
                            value: vudrmToken,
                          },
                        ],
                      },
                    },
                  },
                  {
                    file: hlsStreamURL,
                    drm: {
                      fairplay: {
                        certificateUrl: fairplayCertURL,
                        processSpcUrl: function (initData) {
                          return fairplayServerUrl;
                        },
                        licenseRequestHeaders: [
                          {
                            name: "Content-type",
                            value: "arraybuffer",
                          },
                          {
                            name: "X-VUDRM-TOKEN",
                            value: vudrmToken,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, []);

 
 

  return (
    <div style={{ width: "100%", height: height }}>
      <div ref={playerRef} />
    </div>
  );
}

export default VideoPlayer;
