import { types } from "./types";

/**
 * @param {boolean} loading check if action is load.
 * @param {object} browserFront result request success .
 * @param {object} error errors that may happen in.
 */
const INITIAL_STATE = {
  Concerts: [],
  Carousels: [],
  Documentaires: [],
  Emissions: [],
  Bandeau: [],
  loading: false,
  error: null,
  browserFrontData: null,
  categoryList: null,
};

const BrowserFrontReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LIST_BROWSERFRONT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case types.LIST_BROWSERFRONT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        browserFrontData: action.payload.results.filter(
          (item) => item.key !== "Bandeau" && item.key !== "Carrousel"
        ),
        Bandeau:
          action.payload.results.find((item) => item.key === "Bandeau")
            ?.values || [],
        Carousels:
          action.payload.results.find((item) => item.key === "Carrousel")
            ?.values || [],
      };
    case types.LIST_BROWSERFRONT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case types.GET_CATEGORY_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case types.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        categoryList: action.payload,
      };
    case types.GET_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default BrowserFrontReducer;
